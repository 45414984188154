import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesAzerbaijanA4 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Azerbaijan</h1>
        <h2 className="race-subtext">Tier A - Season 4</h2>
        <h3 className="race-subtext">Round 3 of 17</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Adam Modan" driverFlag="/gb-eng.png" driverInterval="WINNER" driverFastLap="1:41.790" driverGrid="8" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="+1.069" driverFastLap="1:41.991" driverGrid="15" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+2.385" driverFastLap="1:42.163" driverGrid="12" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Mizi" driverFlag="/cz.png" driverInterval="+4.574" driverFastLap="1:42.395" driverGrid="9" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Thomas Vink" driverFlag="/nl.png" driverInterval="+5.394" driverFastLap="1:41.563" driverGrid="2" driverPosition="5" driverPoints="11" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="Mclaren" driverName="Anton Chebotarov" driverFlag="/ua.png" driverInterval="+6.183" driverFastLap="1:42.789" driverGrid="5" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="Mclaren" driverName="Dallan McTeer" driverFlag="/us.png" driverInterval="+8.227" driverFastLap="1:42.884" driverGrid="6" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Romeo Gagliotti" driverFlag="/nl.png" driverInterval="+8.385" driverFastLap="1:43.157" driverGrid="1" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Maxfan" driverFlag="/pl.png" driverInterval="+11.417" driverFastLap="1:42.044" driverGrid="1" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Jack" driverFlag="/gb-sct.png" driverInterval="+11.732" driverFastLap="1:43.295" driverGrid="-" driverPosition="10" driverPoints="1" />
        <F1RaceResult teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="Dopichu" driverFlag="/fi.png" driverInterval="+42.344" driverFastLap="1:43.616" driverGrid="1" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Stra" driverFlag="/rs.png" driverInterval="DNF" driverFastLap="1:45.525" driverGrid="6" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="DNF" driverFastLap="1:44.247" driverGrid="9" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Justin Janson" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="1:45.707" driverGrid="3" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Levi" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:45.025" driverGrid="9" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="MightyKing" driverFlag="/gb-sct.png" driverInterval="DNF" driverFastLap="1:46.066" driverGrid="-" driverPosition="-" driverPoints="0"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Octavian Ursu</h3>
          </div>
        </div>
    </div>
  )
}
