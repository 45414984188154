/* eslint-disable quotes */
import React from 'react'
import '../../../stylesheets/WRCResultsPage.css'
import F1RaceResult from '../WRCEventResult'
const csv = require('jquery-csv')

export default function WRCEventsKenya1 () {
  const data = csv.toArray(`Rank,DisplayName,Vehicle,Time,DifferenceToFirst,Platform,Points,
  1,Focuser3000,Fiat Grande Punto Abarth S2000,19:26.198,00:00.000,STEAM,25,
  2,arionboii,Opel Corsa S2000,19:34.834,00:08.636,STEAM,18,
  3,TPABACTOK,Peugeot 207 S2000,19:48.208,00:22.010,STEAM,15,
  4,TarmacPirate,Fiat Grande Punto Abarth S2000,19:56.231,00:30.033,STEAM,12,
  5,EsportPizza,Peugeot 207 S2000,20:09.347,00:43.149,STEAM,10,
  6,ZLK52,Opel Corsa S2000,20:12.524,00:46.326,STEAM,8,
  7,vebski,Peugeot 207 S2000,20:30.457,01:04.259,STEAM,6,
  8,MrTheKents,Peugeot 207 S2000,20:32.948,01:06.750,STEAM,4,
  9,Shifu051,Peugeot 207 S2000,20:39.448,01:13.250,EA,2,
  10,PBJellingson,Peugeot 207 S2000,21:00.472,01:34.274,XBOX,1,
  11,Lap1024,Peugeot 207 S2000,21:03.655,01:37.457,STEAM,0,
  12,scienta,Opel Corsa S2000,21:12.070,01:45.872,STEAM,0,
  13,Vickepedian,Peugeot 207 S2000,21:43.752,02:17.554,STEAM,0,
  14,RastaaSquare420,Peugeot 207 S2000,21:53.737,02:27.539,STEAM,0,
  15,GeorgeFair91,Peugeot 207 S2000,22:11.567,02:45.369,PSN,0,
  16,xxKUKxx,Peugeot 207 S2000,22:26.592,03:00.394,XBOX,0,`)
  data.splice(0, 7)

  /*
-------------------------------------------------------------
  FILL IN THE PROPERTIES FOR EACH DRIVER IN THE RESULT

  REMEMBER TO CHANGE:
    - THE EVENT NAME AT THE TOP OF THE FILE
    - THE EVENT NAME UNDER THE DRIVER LIST
    - THE EVENT TYPE UNDER THE DRIVER LIST
    - THE EVENT NUMBER UNDER THE DRIVER LIST
-------------------------------------------------------------
  */

  const results = []
  let driver = 0
  let dElement = 1
  data.forEach(element => {
    if (element !== '') {
      if (dElement === 1) results.push({ name: '', time: '', diff: '', car: '', points: '' })
      else if (dElement === 2) results[driver].name = element
      else if (dElement === 3) results[driver].car = element
      else if (dElement === 4) results[driver].time = element
      else if (dElement === 5) results[driver].diff = element
      else if (dElement === 7) results[driver].points = element

      if (dElement === 7) {
        dElement = 1
        driver++
      } else dElement++
    }
  })
  const sortedResults = results.sort((a, b) => {
    return a.time - b.time // Sort by time ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Kenya</h1>
    <h3 className="race-subtext">S2000</h3>
    <br />
    <h2 className="race-subtext">WRC - Season 1</h2>
    <h3 className="race-subtext">Event 2 of 12</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th></th>
        <th><h6>Car</h6></th>
        <th><h6>Time</h6></th>
        <th><h6>Difference</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            const time = sortedResults[driverid].time
            const position = driverid + 1
            const car = sortedResults[driverid].car

            const lapColor = '#FFFFFF'
            const points = sortedResults[driverid].points

            let diff = `+${driver.diff}`
            if (diff === '+00:00.000') diff = '='

            return (
              <F1RaceResult key={driverid} teamBackground={'rgba(252, 76, 0, 0.2)'} constructorName={car} driverName={driver.name} driverFlag={'/driver-icon.png'} driverPosition={position} driverInterval={time} driverPoints={points} driverFastLap={diff} lapColor={lapColor} />
            )
          })}
        </table>
        <br />
        <br />
      </div>
  )
}
