import React from 'react'
import F1DriverStanding from '../../F1DriverStanding'
import '../../../../stylesheets/F1Standings.css'

export default function F1Drivers1005 () {
  const drivers = []
  drivers.push({ name: 'Emil Lundell', points: '35', order: '0', teamBackground: 'rgba(54, 113, 198, 0.2)', teamName: 'Reserve', flag: '/se.png' })
  drivers.push({ name: 'Melon', points: '35', order: '0', teamBackground: 'rgba(54, 113, 198, 0.2)', teamName: 'Reserve', flag: '/ro.png' })
  drivers.push({ name: 'Stra', points: '34', order: '0', teamBackground: 'rgba(108, 211, 191, 0.2)', teamName: 'Reserve', flag: '/rs.png' })
  drivers.push({ name: 'Curtis Kaos', points: '31', order: '0', teamBackground: 'rgba(108, 211, 191, 0.2)', teamName: 'Reserve', flag: '/us.png' })
  drivers.push({ name: 'Bald', points: '33', order: '0', teamBackground: 'rgba(249, 21, 54, 0.2)', teamName: 'Reserve', flag: '/ie.png' })
  drivers.push({ name: 'Micha', points: '0', order: '0', teamBackground: 'rgba(249, 21, 54, 0.2)', teamName: 'Reserve', flag: '/nl.png' })
  drivers.push({ name: 'Sebeinkro', points: '30', order: '0', teamBackground: 'rgba(245, 128, 32, 0.2)', teamName: 'Reserve', flag: '/no.png' })
  drivers.push({ name: 'Thomas Vink', points: '29', order: '0', teamBackground: 'rgba(245, 128, 32, 0.2)', teamName: 'Reserve', flag: '/nl.png' })
  drivers.push({ name: 'Luca Stevens', points: '29', order: '0', teamBackground: 'rgba(53, 140, 117, 0.2)', teamName: 'Reserve', flag: '/gb-eng.png' })
  drivers.push({ name: 'Janmilton', points: '24', order: '0', teamBackground: 'rgba(53, 140, 117, 0.2)', teamName: 'Reserve', flag: '/ga.png' })
  drivers.push({ name: 'Sqolop', points: '24', order: '0', teamBackground: 'rgba(34, 147, 209, 0.2)', teamName: 'Reserve', flag: '/pl.png' })
  drivers.push({ name: 'Denis K', points: '21', order: '0', teamBackground: 'rgba(34, 147, 209, 0.2)', teamName: 'Reserve', flag: '/bg.png' })
  drivers.push({ name: 'Jivodar', points: '15', order: '0', teamBackground: 'rgba(55, 190, 221, 0.2)', teamName: 'Reserve', flag: '/bg.png' })
  drivers.push({ name: 'Mr Blandning', points: '15', order: '0', teamBackground: 'rgba(55, 190, 221, 0.2)', teamName: 'Reserve', flag: '/se.png' })
  drivers.push({ name: 'TsaR', points: '14', order: '0', teamBackground: 'rgba(94, 143, 170, 0.2)', teamName: 'Reserve', flag: '/hu.png' })
  drivers.push({ name: 'Spyzer', points: '12', order: '0', teamBackground: 'rgba(94, 143, 170, 0.2)', teamName: 'Reserve', flag: '/nl.png' })

  const sortedDrivers = drivers.sort((a, b) => {
    if (b.points !== a.points) {
      return b.points - a.points // Sort by points descending
    } else {
      // If the points are equal, sort by order value (highest first)
      return b.order - a.order
    }
  })
  return (
    <div className="drivers-list-4">
        {sortedDrivers.map(driver => {
          const driverid = sortedDrivers.findIndex(d => d.name === driver.name)
          if (driverid === 3) {
            return (
            <>
            <F1DriverStanding key={driver.points} teamColor={driver.id} teamBackground={'rgba(206, 224, 23, 0.2)'} teamImage={driver.teamImage} constructorName={''} driverName={driver.name} driverFlag={driver.flag} driverPosition={driverid + 1} driverPoints={sortedDrivers[driverid].points} />
            </>
            )
          } else if (driverid === 15) {
            return (
            <>
            <F1DriverStanding key={driver.points} teamColor={driver.id} teamBackground={'rgba(206, 224, 23, 0.2)'} teamImage={driver.teamImage} constructorName={''} driverName={driver.name} driverFlag={driver.flag} driverPosition={driverid + 1} driverPoints={sortedDrivers[driverid].points} />
            </>
            )
          } else {
            return (
            <F1DriverStanding key={driver.points} teamColor={driver.id} teamBackground={'rgba(206, 224, 23, 0.2)'} teamImage={driver.teamImage} constructorName={''} driverName={driver.name} driverFlag={driver.flag} driverPosition={driverid + 1} driverPoints={sortedDrivers[driverid].points} />
            )
          }
        })}
    </div>
  )
  /* return (
    <div className="race-tier-container">
      {drivers.map(race => {
        return (<F1DriverStanding teamColor={drivers.id} teamBackground={drivers.teamBackground} teamImage={drivers.teamImage} constructorName={drivers.teamImage} driverName={drivers.name} driverFlag={drivers.flag} />)
      })}
    </div>
  ) */
  /* return (
    <div className="drivers-list-4">
        <hr className="standing-separator-gold"></hr>
        <F1DriverStanding teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Thomas Vink" driverFlag="/nl.png" driverPosition="1" driverPoints="0" />
        <F1DriverStanding teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Maxfan" driverFlag="/pl.png" driverPosition="2" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Antonio Brancati" driverFlag="/it.png" driverPosition="3" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Emil Lundell" driverFlag="/se.png" driverPosition="4" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Sp3xtre" driverFlag="/se.png" driverPosition="5" driverPoints="0" />
        <hr className="standing-separator-gold"></hr>
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Lukas Hendrych" driverFlag="/cz.png" driverPosition="6" driverPoints="0" />
        <F1DriverStanding teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Daniel Bodnar" driverFlag="/sk.png" driverPosition="7" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Justin Janson" driverFlag="/nl.png" driverPosition="8" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="McLaren" driverName="Dallan McTeer" driverFlag="/us.png" driverPosition="9" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="McLaren" driverName="Nik kei" driverFlag="/ua.png" driverPosition="10" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="EndAndrews" driverFlag="/cz.png" driverPosition="11" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Adam Modan" driverFlag="/en.png" driverPosition="12" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="Dopi" driverFlag="/fi.png" driverPosition="13" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="Gabor" driverFlag="/hu.png" driverPosition="14" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Paaveli" driverFlag="/fi.png" driverPosition="15" driverPoints="0" />
        <div>
        <h5>Demotion to tier b</h5>
        <hr className="standing-separator"></hr>
        </div>
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(32, 32, 32, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Jack" driverFlag="/sc.png" driverPosition="16" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Octavian Ursu" driverFlag="/ro.png" driverPosition="17" driverPoints="0" />
        <F1DriverStanding teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driverName="Focuser" driverFlag="/es.png" driverPosition="18" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Romeo Gagliotti" driverFlag="/nl.png" driverPosition="19" driverPoints="0" />
        <F1DriverStanding teamColor="#F91536" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Jan Švrdlik" driverFlag="/cz.png" driverPosition="20" driverPoints="0" />
        <hr className="standing-separator"></hr>
    </div>
  ) */
}
