import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesQatarA4 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Qatar</h1>
        <h2 className="race-subtext">Tier A - Season 4</h2>
        <h3 className="race-subtext">Round 1 of 17</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="WINNER" driverFastLap="1:22.989" driverGrid="-" driverPosition="1" driverPoints="25"/>
        <F1RaceResult teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="Mclaren" driverName="Anton Chebotarov" driverFlag="/ua.png" driverInterval="+1.847" driverFastLap="1:23.014" driverGrid="-" driverPosition="2" driverPoints="18"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="+3.124" driverFastLap="1:23.069" driverGrid="-" driverPosition="3" driverPoints="15"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Lukáš Hendrych" driverFlag="/cz.png" driverInterval="+8.232" driverFastLap="1:22.569" driverGrid="12" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Sp3xtre" driverFlag="/se.png" driverInterval="+15.927" driverFastLap="1:23.313" driverGrid="6" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Daniel Bodnár" driverFlag="/sk.png" driverInterval="+16.794" driverFastLap="1:23.616" driverGrid="9" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Focuser" driverFlag="/es.png" driverInterval="+17.827" driverFastLap="1:23.203" driverGrid="-" driverPosition="7" driverPoints="6"/>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Adam Modan" driverFlag="/gb-eng.png" driverInterval="+21.203" driverFastLap="1:23.098" driverGrid="-" driverPosition="8" driverPoints="4"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="+21.961" driverFastLap="1:23.236" driverGrid="1" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Thomas Vink" driverFlag="/nl.png" driverInterval="+23.919" driverFastLap="1:23.973" driverGrid="3" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Justin Janson" driverFlag="/nl.png" driverInterval="+29.939" driverFastLap="1:23.830" driverGrid="7" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Jack" driverFlag="/gb-sct.png" driverInterval="+30.578" driverFastLap="1:23.413" driverGrid="2" driverPosition="12" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Paaveli" driverFlag="/fi.png" driverInterval="+32.031" driverFastLap="1:23.828" driverGrid="8" driverPosition="13" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="Mclaren" driverName="Dallan McTeer" driverFlag="/us.png" driverInterval="+33.023" driverFastLap="1:23.638" driverGrid="2" driverPosition="14" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Maxfan" driverFlag="/mc.png" driverInterval="+1:56.960" driverFastLap="1:23.745" driverGrid="6" driverPosition="15" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Antonio Brancati" driverFlag="/it.png" driverInterval="+1 Lap" driverFastLap="1:21.613" driverGrid="10" driverPosition="16" driverPoints="0" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Jan Švrdlik" driverFlag="/cz.png" driverInterval="DNF" driverFastLap="1:23.805" driverGrid="13" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="Tsar" driverFlag="/hu.png" driverInterval="DNF" driverFastLap="" driverGrid="2" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="Dopichu" driverFlag="/fi.png" driverInterval="DNF" driverFastLap="" driverGrid="-" driverPosition="-" driverPoints="0"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Lukáš Hendrych</h3>
          </div>
        </div>
    </div>
  )
}
