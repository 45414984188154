import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesTexasA5 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Bald', interval: '3000', fastestLap: '1:35.497', teamName: 'Red Bull', flag: '/ie.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Luca Stevens', interval: '29.469', fastestLap: '1:35.679', teamName: 'Red Bull', flag: '/gb-eng.png', gridChange: '5', arrow: 'up' })
  results.push({ name: 'Anton Chebotarov', interval: '38.033', fastestLap: '1:35.835', teamName: 'Mercedes', flag: '/ua.png', gridChange: '11', arrow: 'down' })
  results.push({ name: 'JSTN', interval: '112.424', fastestLap: '1:34.108', teamName: 'Mercedes', flag: '/nl.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Rigid', interval: '63.866', fastestLap: '1:36.595', teamName: 'Ferrari', flag: '/gb-eng.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Paaveli', interval: '14.460', fastestLap: '1:34.823', teamName: 'Ferrari', flag: '/fi.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Emil Lundell', interval: '7.603', fastestLap: '1:34.818', teamName: 'McLaren', flag: '/se.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Leo', interval: '3.750', fastestLap: '1:34.730', teamName: 'McLaren', flag: '/ro.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Jackz', interval: '10000', fastestLap: '1:35.497', teamName: 'Aston Martin', flag: '/gb-eng.png', gridChange: '10', arrow: 'down' })
  results.push({ name: 'Jrepo', interval: '0', fastestLap: '1:35.009', teamName: 'Alpine', flag: '/at.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Zeyy', interval: '2000', fastestLap: '1:33.940', fastest: true, teamName: 'Alpine', flag: '/gb-eng.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Melon', interval: '22.836', fastestLap: '1:34.592', teamName: 'Williams', flag: '/ro.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Thomas Vink', interval: '17.122', fastestLap: '1:34.848', teamName: 'Williams', flag: '/nl.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Sp3xtre', interval: '22.059', fastestLap: '1:35.775', teamName: 'AlphaTauri', flag: '/se.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Allu', interval: '33.464', fastestLap: '1:34.901', teamName: 'AlphaTauri', flag: '/fi.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Y. Kalenantovich', interval: '35.536', fastestLap: '1:35.806', teamName: 'Alfa Romeo', flag: '/ua.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Adam Modan', interval: '7.235', fastestLap: '1:34.798', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Stra', interval: '48.957', fastestLap: '1:35.919', teamName: 'Haas', flag: '/rs.png', gridChange: '5', arrow: 'up' })
  results.push({ name: 'Karlsson', interval: '2.320', fastestLap: '1:34.780', teamName: 'Haas', flag: '/se.png', gridChange: '1', arrow: 'down' })

  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Texas</h1>
    <h2 className="race-subtext">Tier A - Season 5</h2>
    <h3 className="race-subtext">Round 2 of 17</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Jackz</h3>
          </div>
        </div>
      </div>
  )
}
