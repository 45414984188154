import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesSingaporeCup3 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Singapore</h1>
        <h2 className="race-subtext">The Cup - Season 3</h2>
        <h3 className="race-subtext">Round 8 of 8</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(0, 0, 9, 0.2)" teamImage="/SR.png" constructorName="Semi Racing Esports" driverName="Čića Mile" driverFlag="/cz.png" driverInterval="WINNER" driverFastLap="" driverGrid="6" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Anton Chebotarov" driverFlag="/ua.png" driverInterval="+0.215" driverFastLap="" driverGrid="1" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Ghost" driverFlag="/gb-eng.png" driverInterval="+14.483" driverFastLap="" driverGrid="2" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(240, 183, 78, 0.2)" teamImage="/XPG.png" constructorName="XPG Esports" driverName="LinenRayBok" driverFlag="/se.png" driverInterval="+15.018" driverFastLap="" driverGrid="2" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Antonio Brancati" driverFlag="/it.png" driverInterval="+1:09.862" driverFastLap="" driverGrid="3" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="JP" driverFlag="/nl.png" driverInterval="+1:28.172" driverFastLap="" driverGrid="4" driverPosition="6" driverPoint="8" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="6 10" driverFlag="/se.png" driverInterval="+1:38.693" driverFastLap="1:38.273" driverGrid="1" driverPosition="7" driverPoints="7" arrow="fa-solid fa-angle-up" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Lifebout" driverFlag="/gb-eng.png" driverInterval="+1 LAP" driverFastLap="" driverGrid="3" driverPosition="8" driverPoints="2" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="Alex Fox-Johnson" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="5" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 35, 156, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Sp3xtre" driverFlag="/se.png" driverInterval="DNF" driverFastLap="" driverGrid="7" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 35, 156, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Lucid" driverFlag="/se.png" driverInterval="DNF" driverFastLap="" driverGrid="2" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Čića Mile</h3>
          </div>
        </div>
    </div>
  )
}
