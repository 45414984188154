/* eslint-disable no-unused-vars */
import React from 'react'
import '../../App.css'
import '../../stylesheets/WRCMain.css'
import SecondaryNav from '../SecondaryNav'
import WRCFormat from './WRCFormat'
import WRCMainButtons from './WRCMainButtons'
import WRCPrize from './WRCPrize'
import LatestVideos from '../LatestVideos'
import Footer from '../Footer'

function WRCMain () {
  return (
    <>
    {/* <SecondaryNav /> */}

      <div className="wrc-hero-image">
  <div className="hero-text">
    <h1>WRC</h1>
  </div>
</div>
<div className="page-content-full">
<WRCMainButtons />
</div>
<div className="page-content">
<WRCFormat />
</div>
<div className="page-content-full">
<WRCPrize />
</div>
<div className="page-content">
<LatestVideos />
<Footer/>
</div>
</>
  )
}

export default WRCMain
