import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesAustriaB5 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Micha', interval: '10.902', fastestLap: '1:10.867', teamName: 'Red Bull', flag: '/nl.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Sharky', interval: '23.288', fastestLap: '1:10.791', teamName: 'Mercedes', flag: '/se.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Jivodar', interval: '19.804', fastestLap: '1:11.244', teamName: 'Ferrari', flag: '/bg.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Spzyer', interval: '52.988', fastestLap: '1:10.869', teamName: 'McLaren', flag: '/nl.png', gridChange: '7', arrow: 'down' })
  results.push({ name: 'Mr Blandning', interval: '61.426', fastestLap: '1:11.655', teamName: 'Aston Martin', flag: '/se.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Sebeinkro', interval: '8.994', fastestLap: '1:10.112', teamName: 'Aston Martin', flag: '/no.png', gridChange: '9', arrow: 'up' })
  results.push({ name: 'F0rza55', interval: '23.580', fastestLap: '1:11.493', teamName: 'Alpine', flag: '/ph.png', gridChange: '6', arrow: 'up' })
  results.push({ name: 'JSTN NL', interval: '27.922', fastestLap: '1:11.865', teamName: 'Williams', flag: '/nl.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'AlphaRomeo', interval: '25.888', fastestLap: '1:11.238', teamName: 'Williams', flag: '/nl.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'VMartiskin', interval: '41.773', fastestLap: '1:10.763', teamName: 'AlphaTauri', flag: '/fi.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Denis K', interval: '0', fastestLap: '1:09.126', fastest: true, teamName: 'Alfa Romeo', flag: '/bg.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Bonji', interval: '10001', fastestLap: '1:13.943', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '5', arrow: 'down' })
  results.push({ name: 'Tinos', interval: '10003', fastestLap: '', teamName: 'Haas', flag: '/nl.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Tito', interval: '74.760', fastestLap: '1:11.080', teamName: 'Haas', flag: '/nl.png', gridChange: '-', arrow: '' })

  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Austria</h1>
    <h2 className="race-subtext">Tier B - Season 5</h2>
    <h3 className="race-subtext">Round 7 of 17</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Sebeinkro</h3>
          </div>
        </div>
      </div>
  )
}
