/* eslint-disable quotes */
import React from 'react'
import '../../../stylesheets/WRCResultsPage.css'
import F1RaceResult from '../WRCEventResult'
const csv = require('jquery-csv')

export default function WRCEventsScandia1 () {
  const data = csv.toArray(`Rank,DisplayName,Vehicle,Time,DifferenceToFirst,Platform,Points,
  1,TPABACTOK,Ford Fiesta Rally2,18:26.638,00:00.000,STEAM,25,
  2,ZLK52,ŠKODA Fabia RS Rally2,18:35.277,00:08.639,STEAM,18,
  3,Stra4505,ŠKODA Fabia RS Rally2,18:36.006,00:09.368,EA,15,
  4,PBJellingson,ŠKODA Fabia RS Rally2,18:49.035,00:22.397,XBOX,12,
  5,arionboii,ŠKODA Fabia Rally2 Evo,18:52.814,00:26.176,STEAM,10,
  6,Focuser3000,ŠKODA Fabia RS Rally2,19:12.673,00:46.035,STEAM,8,
  7,scienta,Ford Fiesta Rally2,19:27.591,01:00.953,STEAM,6,
  8,Vickepedian,Ford Fiesta Rally2,19:33.996,01:07.358,STEAM,4,
  9,WRC Player,ŠKODA Fabia RS Rally2,19:35.534,01:08.896,EA,2,
  10,go-skydiving2017,ŠKODA Fabia RS Rally2,19:36.808,01:10.170,STEAM,1,
  11,EsportPizza,ŠKODA Fabia RS Rally2,20:06.141,01:39.503,STEAM,0,
  12,GeorgeFair91,ŠKODA Fabia RS Rally2,20:25.690,01:59.052,PSN,0,
  13,Lap1024,Hyundai i20 N Rally2,20:52.553,02:25.915,STEAM,0,
  14,RastaaSquare420,ŠKODA Fabia RS Rally2,21:02.457,02:35.819,STEAM,0,
  15,Bananacy,ŠKODA Fabia RS Rally2,22:52.789,04:26.151,STEAM,0,`)
  data.splice(0, 7)

  /*
-------------------------------------------------------------
  FILL IN THE PROPERTIES FOR EACH DRIVER IN THE RESULT

  REMEMBER TO CHANGE:
    - THE EVENT NAME AT THE TOP OF THE FILE
    - THE EVENT NAME UNDER THE DRIVER LIST
    - THE EVENT TYPE UNDER THE DRIVER LIST
    - THE EVENT NUMBER UNDER THE DRIVER LIST
-------------------------------------------------------------
  */

  const results = []
  let driver = 0
  let dElement = 1
  data.forEach(element => {
    if (element !== '') {
      if (dElement === 1) results.push({ name: '', time: '', diff: '', car: '', points: '' })
      else if (dElement === 2) results[driver].name = element
      else if (dElement === 3) results[driver].car = element
      else if (dElement === 4) results[driver].time = element
      else if (dElement === 5) results[driver].diff = element
      else if (dElement === 7) results[driver].points = element

      if (dElement === 7) {
        dElement = 1
        driver++
      } else dElement++
    }
  })
  const sortedResults = results.sort((a, b) => {
    return a.time - b.time // Sort by time ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Scandia</h1>
    <h3 className="race-subtext">WRC2</h3>
    <br />
    <h2 className="race-subtext">WRC - Season 1</h2>
    <h3 className="race-subtext">Event 3 of 12</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th></th>
        <th><h6>Car</h6></th>
        <th><h6>Time</h6></th>
        <th><h6>Difference</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            const time = sortedResults[driverid].time
            const position = driverid + 1
            const car = sortedResults[driverid].car

            const lapColor = '#FFFFFF'
            const points = sortedResults[driverid].points

            let diff = `+${driver.diff}`
            if (diff === '+00:00.000') diff = '='

            return (
              <F1RaceResult key={driverid} teamBackground={'rgba(252, 76, 0, 0.2)'} constructorName={car} driverName={driver.name} driverFlag={'/driver-icon.png'} driverPosition={position} driverInterval={time} driverPoints={points} driverFastLap={diff} lapColor={lapColor} />
            )
          })}
        </table>
        <br />
        <br />
      </div>
  )
}
