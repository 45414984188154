import React from 'react'
import '../../App.css'
import '../../stylesheets/F1Prize.css'
import { Link } from 'react-router-dom'

function F1Prize () {
  return (
    <>
      <section className="next-race-container">
        <h2 className="next-race-heading">Prize</h2>
        <hr className="header-row"></hr>
        <div className="view-all-container">
        </div>
        <div className="next-race-box">
        <p style={{ textAlign: 'center' }}>THE WINNER OF THE CHAMPIONSHIP WILL TAKE HOME THE ENTIRE PRIZE POOL.</p>
        <p></p>
        <p></p>
        <h2>WINNER TAKES ALL!</h2>
          <div className="next-race-tier-container">
            <div className="f1-prize-container">
            <table className="prize-table">
    <tr>
      <th className="prize-header">Place</th>
      <th className="prize-header">$ USD</th>
    </tr>
    <tr className="prize-winner">
      <td className="prize-data">P1</td>
      <td className="prize-data">$50</td>
    </tr>
    </table>
    </div>
          </div>
          <div>
          <Link to='https://racenet.com/ea_sports_wrc/clubs/1056'><button className="tier-button">Join Now</button></Link>
          </div>
        </div>
</section>
</>
  )
}

export default F1Prize
