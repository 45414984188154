import React from 'react'
import '../../App.css'
import '../../stylesheets/F1MainButtons.css'
import { Link } from 'react-router-dom'

function WRCMainButtons () {
  return (
    <>
<div className="tier-grid-container-2">
          <Link to='/wrc/events'>
          <div className="main-button-link">
            Events
          </div>
          </Link>
          <Link to='/wrc/standings'>
          <div className="main-button-link">Standings</div>
          </Link>
          <Link to='https://racenet.com/ea_sports_wrc/clubs/1056'>
          <div className="main-button-link">Join</div>
          </Link>
        </div>
</>
  )
}

export default WRCMainButtons
