/* eslint-disable quotes */
/* eslint-disable no-lone-blocks */
import React from 'react'
import F1DriverStanding from '../WRCDriverStanding'
import '../../../stylesheets/F1Standings.css'
const csv = require('jquery-csv')

export default function WRCStandings1 () {
  const data = csv.toArray(`DisplayName,Rank,PointsAccumulated,Time,
  TPABACTOK,1,59,00:19:45.8230000,
  Focuser3000,2,49,00:00:00,
  arionboii,3,31,00:20:53.1570000,
  scienta,4,30,00:37:07.9840000,
  TarmacPirate,5,29,00:00:00,
  PBJellingson,6,28,00:48:00,
  TheBac0nat0or,7,27,00:00:00,
  ZLK52,8,24,00:00:00,
  Apoula73,9,22,00:00:00,
  EsportPizza,10,21,00:21:16.9130000,
  V10_TsaR,11,16,00:00:00,
  Vickepedian,12,16,00:19:50.2040000,
  itsthebxrman,13,14,00:00:00,
  PorTMeo,14,13,00:00:00,
  WRC Player,15,13,00:00:00,
  MrTheKents,16,13,00:00:00,
  Stra4505,17,12,00:00:00,
  RastaaSquare420,18,11,00:00:00,
  xxKUKxx,19,10,00:00:00,
  vebski,20,10,00:00:00,
  szymonski1905,21,10,00:00:00,
  Bananacy,22,8,00:26:08.8120000,
  go-skydiving2017,23,7,00:22:35.8250000,
  Lap1024,24,7,00:00:00,
  WRC Player,25,7,00:00:00,
  Jost691818,26,5,00:00:00,
  GeorgeFair91,27,4,00:37:36.6810000,
  arwoooooooooooo,28,3,00:00:00,
  EfficiencyVI,29,1,00:00:00,
  OrangeJuice841,30,0,00:00:00,
  GarrettNotGary,31,0,00:00:00,
  thehenrym,32,0,00:00:00,`)
  data.splice(0, 4)

  const drivers = []
  let driver = 0
  let dElement = 1
  data.forEach(element => {
    if (element !== '') {
      if (dElement === 1) drivers.push({ name: element, points: '', order: '0' })
      else if (dElement === 3) drivers[driver].points = element

      if (dElement === 3) {
        dElement = 1
        driver++
      } else dElement++
    }
  })
  const sortedDrivers = drivers.sort((a, b) => {
    if (b.points !== a.points) {
      return b.points - a.points // Sort by points descending
    } else {
      // If the points are equal, sort by order value (highest first)
      return b.order - a.order
    }
  })
  return (
    <div className="drivers-list-4">
        {sortedDrivers.map(driver => {
          const driverid = sortedDrivers.findIndex(d => d.name === driver.name)
          console.log(driverid)
          return (
            <F1DriverStanding key={driver.points} teamColor={'#CB7209'} teamBackground={'rgba(252, 76, 0, 0.2)'} driverName={driver.name} driverFlag={driver.flag} driverPosition={driverid + 1} driverPoints={sortedDrivers[driverid].points} />
          )
        })}
    </div>
  )
}
