import React from 'react'

import F1Form from './F1Form'
import Footer from '../Footer'

export default function F1Register () {
  return (
    <>
<div className="page-content">
<h1 className="page-content-heading">Register Now</h1>
    <F1Form />
    <Footer />
</div>
</>
  )
}
