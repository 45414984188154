import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesTexasCup4 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  FASTEST LAP IS DECLARED WITH fastest: true
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Adam Modan', interval: '0.987', fastestLap: '1:39.574', teamName: '10 Controls', flag: '/gb-eng.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Emil Lundell', interval: '11.006', fastestLap: '1:40.187', teamName: 'Virtual Racing League', flag: '/se.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Allu', interval: '31.763', fastestLap: '1:41.326', teamName: 'Virtual Racing League', flag: '/fi.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Maxlinxc', interval: '2000', fastestLap: '1:46.819', teamName: 'Premier Racing Series', flag: '/gb-eng.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Miron Pijl', interval: '10002', fastestLap: '1:47.859', teamName: 'Semi Racing eSports', flag: '/nl.png', gridChange: '9', arrow: 'down' })
  results.push({ name: 'Dominik Nielsen', interval: '65.494', fastestLap: '1:39.526', teamName: 'Piggys Online Racing', flag: '/no.png', gridChange: '5', arrow: 'down' })
  results.push({ name: 'Bigpou', interval: '10001', fastestLap: '1:46.643', teamName: 'Piggys Online Racing', flag: '/dz.png', gridChange: '5', arrow: 'down' })
  results.push({ name: 'Bladez', interval: '0', fastestLap: '1:39.946', teamName: 'Groundeffect Racing League', flag: '/gb-eng.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Darragh', interval: '0.533', fastestLap: '1:39.776', teamName: 'Groundeffect Racing League', flag: '/gb-eng.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Wellsh Jack', interval: '24.079', fastestLap: '1:41.063', teamName: 'CRF Racing League', flag: '/gb-wls.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Hopz', interval: '34.183', fastestLap: '1:41.391', teamName: 'CRF Racing League', flag: '/gb-eng.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Jack', interval: '20.258', fastestLap: '1:40.341', teamName: 'Team Fifty', flag: '/gb-sct.png', gridChange: '8', arrow: 'up' })
  results.push({ name: 'Focuser', interval: '54.898', fastestLap: '1:38.151', fastest: true, teamName: 'Team Fifty', flag: '/es.png', gridChange: '3', arrow: 'up' })
  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Texas</h1>
    <h2 className="race-subtext">The Cup - Season 4</h2>
    <h3 className="race-subtext">Round 8 of 14</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Jack</h3>
          </div>
        </div>
      </div>
  )
}
