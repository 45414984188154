import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesQatarC4 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Qatar</h1>
        <h2 className="race-subtext">Tier C - Season 4</h2>
        <h3 className="race-subtext">Round 1 of 17</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Lz Albo" driverFlag="/al.png" driverInterval="WINNER" driverFastLap="1:23.985" driverGrid="2" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="CrxssLiam" driverFlag="/gb-eng.png" driverInterval="+0.141" driverFastLap="1:23.680" driverGrid="13" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Aspec" driverFlag="/gb-eng.png" driverInterval="+8.794" driverFastLap="1:24.383" driverGrid="4" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Enis" driverFlag="/al.png" driverInterval="+18.072" driverFastLap="1:24.252" driverGrid="3" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" constructorName="Aston Martin" driverName="Adam" driverFlag="/gb-eng.png" driverInterval="+31.320" driverFastLap="1:24.327" driverGrid="11" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Afnonso" driverFlag="/es.png" driverInterval="+38.665" driverFastLap="1:24.324" driverGrid="4" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Lukas" driverFlag="/dk.png" driverInterval="+48.451" driverFastLap="1:23.444" driverGrid="1" driverPosition="7" driverPoints="7" arrow="fa-solid fa-angle-up" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="CRZY" driverFlag="/nl.png" driverInterval="+50.659" driverFastLap="1:24.809" driverGrid="3" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Hugyme" driverFlag="/au.png" driverInterval="+50.659" driverFastLap="1:24.430" driverGrid="5" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Uklucasw" driverFlag="/gb-eng.png" driverInterval="+55.849" driverFastLap="1:24.882" driverGrid="4" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Ukrain aim" driverFlag="/az.png" driverInterval="+1 Lap" driverFastLap="1:24.427" driverGrid="9" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Danottok" driverFlag="/fi.png" driverInterval="+1 Lap" driverFastLap="1:24.283" driverGrid="-" driverPosition="12" driverPoints="0"/>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Porp" driverFlag="/gb-eng.png" driverInterval="+2 Laps" driverFastLap="1:25.663" driverGrid="5" driverPosition="13" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="TTV Sweat" driverFlag="/gb-eng.png" driverInterval="+2 Laps" driverFastLap="1:25.345" driverGrid="3" driverPosition="14" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Tinos RL" driverFlag="/nl.png" driverInterval="+2 Laps" driverFastLap="1:26.467" driverGrid="10" driverPosition="15" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Stefan Ingi" driverFlag="/is.png" driverInterval="DNF" driverFastLap="1:23.850" driverGrid="6" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Forza55" driverFlag="/ph.png" driverInterval="DNF" driverFastLap="1:25.793" driverGrid="8" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Aki" driverFlag="/pl.png" driverInterval="DNF" driverFastLap="1:26.929" driverGrid="5" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>CrxssLiam</h3>
          </div>
        </div>
    </div>
  )
}
