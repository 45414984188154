import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesHungaryCup3 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Hungary</h1>
        <h2 className="race-subtext">The Cup - Season 3</h2>
        <h3 className="race-subtext">Round 6 of 8</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="WINNER" driverFastLap="" driverGrid="3" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="+0.066" driverFastLap="" driverGrid="-" driverPosition="2" driverPoints="18"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Romeo Gagliotti" driverFlag="/nl.png" driverInterval="+4.723" driverFastLap="" driverGrid="3" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="NoName" driverFlag="/de.png" driverInterval="+4.808" driverFastLap="" driverGrid="1" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Ghost" driverFlag="/gb-eng.png" driverInterval="+5.195" driverFastLap="" driverGrid="7" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Polo Q" driverFlag="/cz.png" driverInterval="+6.086" driverFastLap="" driverGrid="8" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Dallan McTeer" driverFlag="/us.png" driverInterval="+6.772" driverFastLap="" driverGrid="3" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Gloomy" driverFlag="/nl.png" driverInterval="+6.773" driverFastLap="" driverGrid="1" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+7.779" driverFastLap="" driverGrid="4" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="JP" driverFlag="/nl.png" driverInterval="+40.535" driverFastLap="" driverGrid="5" driverPosition="10" driverPoint="1" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="Alex Fox-Johnson" driverFlag="/gb-eng.png" driverInterval="+47.074" driverFastLap="1:17.100" driverGrid="4" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Antonio Brancati" driverFlag="/it.png" driverInterval="+1 LAP" driverFastLap="" driverGrid="4" driverPosition="12" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(255, 32, 2, 0.2)" teamImage="/PM.png" constructorName="Prime Motorsport" driverName="Tony Riché" driverFlag="/fr.png" driverInterval="DNF" driverFastLap="" driverGrid="12" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 35, 156, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Anthony" driverFlag="/gr.png" driverInterval="DNF" driverFastLap="" driverGrid="2" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="BanterBranden" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="2" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 35, 156, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Jordan" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Ghost</h3>
          </div>
        </div>
    </div>
  )
}
