import React, { useState } from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesMenu.css'
import WRCCalendar1 from './Season 1/Calendar'

export default function WRCEventsMenu () {
  const [selectedSeason, setSelectedSeason] = useState('Season 1')

  return (
    <div className="races-menu-container">
        <div className="tier-container">
        <div style={{ color: selectedSeason === 'Season 1' ? 'white' : '#C18794' }} className="races-menu-link" onClick={() => setSelectedSeason('Season 1')}>Season 1</div>
        </div>
        <div>
        {selectedSeason === 'Season 1'
          ? <WRCCalendar1 />
          : <h4>Something broke</h4>
        }
        </div>
        </div>
  )
}
