import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesFranceA2 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Kai', interval: '4.751', fastestLap: '1:35.145', teamName: 'Red Bull', flag: '/gb-eng.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Manuel', interval: '10003', fastestLap: '1:35.383', teamName: 'Ferrari', flag: '/it.png', gridChange: '8', arrow: 'down' })
  results.push({ name: 'Blackice', interval: '3.030', fastestLap: '1:33.801', fastest: true, teamName: 'Mercedes', flag: '/gb-eng.png', gridChange: '9', arrow: 'up' })
  results.push({ name: 'Lando', interval: '56.360', fastestLap: '1:34.458', teamName: 'Mercedes', flag: '/pl.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Finnlucky', interval: '10001', fastestLap: '-', teamName: 'Alpine', flag: '/nl.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'ukrain aim', interval: '22.262', fastestLap: '1:34.806', teamName: 'McLaren', flag: '/az.png', gridChange: '8', arrow: 'down' })
  results.push({ name: 'Aspec', interval: '10.102', fastestLap: '1:35.576', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '8', arrow: 'up' })
  results.push({ name: 'VideoSuper111', interval: '19.586', fastestLap: '1:35.450', teamName: 'Aston Martin', flag: '/nl.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Avid', interval: '10000', fastestLap: '1:37.685', teamName: 'Aston Martin', flag: '/gb-eng.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Lukas', interval: '10002', fastestLap: '-', teamName: 'Haas', flag: '/dk.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Arion', interval: '0', fastestLap: '1:34.267', teamName: 'Haas', flag: '/al.png', gridChange: '14', arrow: 'up' })
  results.push({ name: 'Pablo Mothiba', interval: '8.751', fastestLap: '1:36.060', teamName: 'AlphaTauri', flag: '/nl.png', gridChange: '8', arrow: 'up' })
  results.push({ name: 'Aki', interval: '1000', fastestLap: '1:35.906', teamName: 'AlphaTauri', flag: '/pl.png', gridChange: '-', arrow: '' })
  results.push({ name: 'lean', interval: '10.359', fastestLap: '1:33.919', teamName: 'Williams', flag: '/nl.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Jellytastick', interval: '0.710', fastestLap: '1:34.921', teamName: 'Reserve', flag: '/be.png', gridChange: '1', arrow: 'down' })
  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">France</h1>
    <h2 className="race-subtext">Tier C - Season 2</h2>
    <h3 className="race-subtext">Round 1 of 10</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest) {
              lapColor = '#ff00ff'
              points++
            }

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={driverid + 1} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Arion</h3>
          </div>
        </div>
      </div>
  )
}
