import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBoxComingSoon from './F1RaceBoxComingSoon'
// import F1RaceBox2 from './F1RaceBox2'
// import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierC3 () {
  const races = [
  ]
  races.push({ id: 1, name: 'Bahrain', date: new Date(2023, 2, 22).getTime(), flag: '/bh.png' })
  races.push({ id: 2, name: 'Great Britain', date: new Date(2023, 2, 29).getTime(), flag: '/gb.png' })
  races.push({ id: 3, name: 'Monaco', date: new Date(2023, 3, 5).getTime(), flag: '/mc.png' })
  races.push({ id: 4, name: 'Belgium (Sprint)', date: new Date(2023, 3, 12).getTime(), flag: '/be.png' })
  races.push({ id: 5, name: 'Miami', date: new Date(2023, 3, 19).getTime(), flag: '/us.png' })
  races.push({ id: 6, name: 'Saudi Arabia (Sprint)', date: new Date(2023, 3, 26).getTime(), flag: '/sa.png' })
  races.push({ id: 7, name: 'Netherlands', date: new Date(2023, 4, 3).getTime(), flag: '/nl.png' })
  races.push({ id: 8, name: 'Italy', date: new Date(2023, 4, 10).getTime(), flag: '/it.png' })
  races.push({ id: 9, name: 'Singapore', date: new Date(2023, 4, 17).getTime(), flag: '/sg.png' })
  races.push({ id: 10, name: 'Spain', date: new Date(2023, 3, 24).getTime(), flag: '/es.png' })
  return (
    <div className="race-tier-container">
        {races.map(race => {
          return (<F1RaceBoxComingSoon key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
        })}
        {/* <F1RaceBox raceName="Melbourne" raceDate={['5', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Silverstone" raceDate={['12', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Spa-Francorchamps" raceDate={['19', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Jeddah" raceDate={['26', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Zandvoort" raceDate={['2', <sup>nd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Monza (Sprint)" raceDate={['9', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Hungaroring" raceDate={['16', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Miami" raceDate={['23', <sup>rd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Mexico" raceDate={['30', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Imola" raceDate={['7', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Barcelona" raceDate={['14', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Suzuka" raceDate={['21', <sup>st</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Montreal" raceDate={['28', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Bahrain (Sprint)" raceDate={['4', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Yas Marina" raceDate={['11', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/> */}
    </div>
  )
}
