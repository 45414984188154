/* eslint-disable no-unused-vars */
import React from 'react'
import '../../App.css'
import '../../stylesheets/WRCMain.css'
import Footer from '../Footer'
import WRCEventsMenu from './WRCEventsMenu'

function WRCEvents () {
  return (
    <>
    {/* <SecondaryNav /> */}

      <div className="wrc-hero-image">
  <div className="hero-text">
    <h1>WRC Events</h1>
  </div>
</div>

<div className="page-content">
<WRCEventsMenu />
<Footer />
</div>
</>
  )
}

export default WRCEvents
