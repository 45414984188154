import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesBelgiumA4 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Thomas Vink', interval: '10000', fastestLap: '1:53.276', teamName: 'Red Bull', flag: '/nl.png', gridChange: '8', arrow: 'down' })
  results.push({ name: 'Maxfan', interval: '10003', fastestLap: '', teamName: 'Red Bull', flag: '/pl.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Emil Lundell', interval: '9.491', fastestLap: '1:50.065', teamName: 'Ferrari', flag: '/se.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Mizi', interval: '13.276', fastestLap: '1:51.525', teamName: 'Ferrari', flag: '/cz.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Lukáš Hendrych', interval: '13.501', fastestLap: '1:51.478', teamName: 'Mercedes', flag: '/cz.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Sp3xtre', interval: '0', fastestLap: '1:51.632', teamName: 'Mercedes', flag: '/se.png', gridChange: '6', arrow: 'up' })
  results.push({ name: 'Justin Janson', interval: '17.911', fastestLap: '1:51.061', teamName: 'Alpine', flag: '/nl.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Mippio', interval: '74.755', fastestLap: '1:44.942', fastest: true, teamName: 'McLaren', flag: '/nl.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Dallan McTeer', interval: '2.813', fastestLap: '1:51.886', teamName: 'McLaren', flag: '/us.png', gridChange: '5', arrow: 'up' })
  results.push({ name: 'Ondřej Macháč', interval: '10001', fastestLap: '', teamName: 'Alfa Romeo', flag: '/cz.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Adam Modan', interval: '0.545', fastestLap: '1:50.678', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '13', arrow: 'up' })
  results.push({ name: 'Dopichu', interval: '10005', fastestLap: '', teamName: 'Aston Martin', flag: '/fi.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Paaveli', interval: '6.868', fastestLap: '1:52.211', teamName: 'Haas', flag: '/fi.png', gridChange: '10', arrow: 'up' })
  results.push({ name: 'Focuser', interval: '12.494', fastestLap: '1:46.772', teamName: 'AlphaTauri', flag: '/es.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Čića Mile', interval: '10002', fastestLap: '', teamName: 'Williams', flag: '/cz.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Allu', interval: '10004', fastestLap: '', teamName: 'Williams', flag: '/fi.png', gridChange: '9', arrow: 'down' })
  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Belgium</h1>
    <h2 className="race-subtext">Tier A - Season 4</h2>
    <h3 className="race-subtext">Round 8 of 17</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Adam Modan</h3>
          </div>
        </div>
      </div>
  )
}
