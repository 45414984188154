import React from 'react'
import F1ConstructorStanding from '../../F1ConstructorStanding'
import '../../../../stylesheets/F1Standings.css'

export default function F1ConstructorsCup4 () {
  const constructors = []
  constructors.push({ name: '10 Controls', points: '186', order: '0', teamColor: '#C18794', teamBackground: 'rgba(193, 135, 148, 0.2)', teamName: '10 Controls', teamImage: '/10C.png', flag: '/nl.png' })
  constructors.push({ name: 'Virtual Racing League', points: '203', order: '0', teamColor: '#181D32', teamBackground: 'rgba(24, 29, 50, 0.2)', teamName: 'Virtual Racing League', teamImage: '/VRL.png', flag: '/it.png' })
  constructors.push({ name: 'Premier Racing Series', points: '49', order: '0', teamColor: '#FFD700', teamBackground: 'rgba(255, 215, 0, 0.2)', teamName: 'Premier Racing Series', teamImage: '/PRS.png', flag: '/se.png' })
  constructors.push({ name: 'OGKROE', points: '18', order: '18', teamColor: '#DD8912', teamBackground: 'rgba(221, 137, 18, 0.2)', teamName: 'OGKROE', teamImage: '/OGK.png', flag: '/sk.png' })
  constructors.push({ name: 'VF1 Racing Community', points: '14', order: '0', teamColor: '#FFFFFF', teamBackground: 'rgba(255, 255, 255, 0.2)', teamName: 'VF1 Racing Community', teamImage: '/VF1.png', flag: '/us.png' })
  constructors.push({ name: 'Semi Racing eSports', points: '46', order: '0', teamColor: '#FF4242', teamBackground: 'rgba(255, 66, 66, 0.2)', teamName: 'Semi Racing eSports', teamImage: '/SR.png', flag: '/cz.png' })
  constructors.push({ name: "Piggy's Online Racing", points: '51', order: '0', teamColor: '#3BEC2C', teamBackground: 'rgba(59, 236, 44, 0.2)', teamName: "Piggy's Online Racing", teamImage: '/POR.png', flag: '/fi.png' })
  constructors.push({ name: 'Groundeffect Racing League', points: '164', order: '0', teamColor: '#FFF500', teamBackground: 'rgba(255, 245, 0, 0.2)', teamName: 'Groundeffect Racing League', teamImage: '/GRL.png', flag: '/ro.png' })
  constructors.push({ name: 'CRF Racing League', points: '58', order: '0', teamColor: '#61D836', teamBackground: 'rgba(97, 216, 54, 0.2)', teamName: 'CRF Racing League', teamImage: '/CRF.png', flag: '/ro.png' })
  constructors.push({ name: 'Team Fifty', points: '25', order: '0', teamColor: '#E67E22', teamBackground: 'rgba(230, 126, 34, 0.2)', teamName: 'Team Fifty', teamImage: '/TEAMFIFTY.png', flag: '/ro.png' })
  const sortedconstructors = constructors.sort((a, b) => {
    if (b.points !== a.points) {
      return b.points - a.points // Sort by points descending
    } else {
      // If the points are equal, sort by order value (highest first)
      return b.order - a.order
    }
  })
  return (
    <div className="constructors-list-4">
        {sortedconstructors.map((constructor, index) => {
          console.log(constructor.name)
          return (
              <F1ConstructorStanding key={constructor.name} teamColor={constructor.teamColor} teamBackground={constructor.teamBackground} teamImage={constructor.teamImage} constructorName={constructor.teamName} constructorPosition={index + 1} constructorPoints={constructor.points} />
          )
        })}
      </div>
  )
}
