import React, { useState } from 'react'
import '../../App.css'
import '../../stylesheets/F1Drivers.css'
import '../../stylesheets/F1TeamCup.css'
import F1Team from './F1Team'
import F1TeamCup from './F1TeamCup'
import Footer from '../Footer'

export default function F1Drivers () {
  const [selectedSeason, setSelectedSeason] = useState('Season 5')
  const [selectedDivision, setSelectedDivision] = useState('Tier A')
  return (
    <>
    <div className="page-content">
    <h1 className="page-content-heading">Drivers</h1>
    <div className="division-menu-container">
        <h4 className="division-menu-item" style={{ color: selectedSeason === 'Season 1' ? 'white' : '#C18794' }} onClick={() => setSelectedSeason('Season 1')}>Season 1</h4>
        <h4 className="division-menu-item" style={{ color: selectedSeason === 'Season 2' ? 'white' : '#C18794' }} onClick={() => setSelectedSeason('Season 2')}>Season 2</h4>
        <h4 className="division-menu-item" style={{ color: selectedSeason === 'Season 3' ? 'white' : '#C18794' }} onClick={() => setSelectedSeason('Season 3')}>Season 3</h4>
        <h4 className="division-menu-item" style={{ color: selectedSeason === 'Season 4' ? 'white' : '#C18794' }} onClick={() => setSelectedSeason('Season 4')}>Season 4</h4>
        <h4 className="division-menu-item" style={{ color: selectedSeason === 'Season 5' ? 'white' : '#C18794' }} onClick={() => setSelectedSeason('Season 5')}>Season 5</h4>
    </div>
    <div className="division-menu-container">
        {
        selectedSeason === 'Season 1'
          ? <>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier A' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier A')}>Tier A</h4>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier B' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier B')}>Tier B</h4>
        </>
          : selectedSeason === 'Season 2'
            ? <>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier A' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier A')}>Tier A</h4>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier B' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier B')}>Tier B</h4>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier C' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier C')}>Tier C</h4>
          </>
            : <>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier A' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier A')}>Tier A</h4>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier B' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier B')}>Tier B</h4>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'Tier C' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('Tier C')}>Tier C</h4>
        <h4 className="division-menu-item" style={{ color: selectedDivision === 'The Cup' ? 'white' : '#C18794' }} onClick={() => setSelectedDivision('The Cup')}>THE CUP</h4>
        </>
        }
    </div>
    <div className="drivers-list">
    {selectedDivision === 'Tier A' && selectedSeason === 'Season 1'
      ? <>
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Mute" driver1Number="1" driver1Flag="/ng.png" driver2Name="Eddyworth" driver2Number="67" driver2Flag="/fr.png" />
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Thomas Vink" driver1Number="8" driver1Flag="/nl.png" driver2Name="Hailee" driver2Number="69" driver2Flag="/nl.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Newman" driver1Number="16" driver1Flag="/it.png" driver2Name="Cortes" driver2Number="44" driver2Flag="/es.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Im3j3n" driver1Number="13" driver1Flag="/de.png" driver2Name="Kudos" driver2Number="35" driver2Flag="/de.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Nik Kei" driver1Number="4" driver1Flag="/ua.png" driver2Name="Faded" driver2Number="30" driver2Flag="/us.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Jaysen" driver1Number="59" driver1Flag="/nl.png" driver2Name="Kyan" driver2Number="88" driver2Flag="/nl.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Luca" driver1Number="40" driver1Flag="/en.png" driver2Name="counterfeit" driver2Number="27" driver2Flag="/en.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Kalcess" driver1Number="49" driver1Flag="/no.png" driver2Name="lawrenceselling" driver2Number="93" driver2Flag="/ph.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="focuser" driver1Number="22" driver1Flag="/es.png" driver2Name="countmuttly" driver2Number="14" driver2Flag="/dk.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Anoldenemy" driver1Number="19" driver1Flag="/hr.png" driver2Name="Flaw" driver2Number="70" driver2Flag="/nl.png" />
          </>
      : selectedDivision === 'Tier B' && selectedSeason === 'Season 1'
        ? <>
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Nydrow" driver1Number="25" driver1Flag="/be.png" driver2Name="Zerotix" driver2Number="99" driver2Flag="/de.png" />
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Maxfan" driver1Number="33" driver1Flag="/pl.png" driver2Name="Arab" driver2Number="28" driver2Flag="/us.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Veixel" driver1Number="42" driver1Flag="/se.png" driver2Name="Cheers" driver2Number="7" driver2Flag="/de.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Sp3xtre" driver1Number="31" driver1Flag="/se.png" driver2Name="Brim" driver2Number="8" driver2Flag="/nl.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Vade" driver1Number="21" driver1Flag="/se.png" driver2Name="Andrew" driver2Number="19" driver2Flag="/ro.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Rinky" driver1Number="24" driver1Flag="/nl.png" driver2Name="Br0wn" driver2Number="32" driver2Flag="/de.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="ARBITERELITEX" driver1Number="2" driver1Flag="/it.png" driver2Name="EZCAKEARDA" driver2Number="20" driver2Flag="/be.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Uklucasw" driver1Number="66" driver1Flag="/en.png" driver2Name="Romeo Gagliotti" driver2Number="19" driver2Flag="/nl.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Daniel Bodnar" driver1Number="18" driver1Flag="/sk.png" driver2Name="Philip" driver2Number="22" driver2Flag="/nl.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Luc" driver1Number="13" driver1Flag="/nl.png" driver2Name="Melon" driver2Number="5" driver2Flag="/ro.png" />
          </>
        : selectedDivision === 'Tier A' && selectedSeason === 'Season 2'
          ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="ThomasVink" driver1Number="8" driver1Flag="/nl.png" driver2Name="Focuser" driver2Number="22" driver2Flag="/es.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Newman" driver1Number="16" driver1Flag="/it.png" driver2Name="Cortes" driver2Number="44" driver2Flag="/es.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Luca" driver1Number="40" driver1Flag="/en.png" driver2Name="Counterfeit" driver2Number="27" driver2Flag="/en.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Faded" driver1Number="30" driver1Flag="/us.png" driver2Name="Nik Kei" driver2Number="4" driver2Flag="/ua.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Luc" driver1Number="31" driver1Flag="/nl.png" driver2Name="Brim" driver2Number="8" driver2Flag="/nl.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Nydrow" driver1Number="25" driver1Flag="/be.png" driver2Name="Veixel" driver2Number="23" driver2Flag="/se.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Kudos" driver1Number="35" driver1Flag="/de.png" driver2Name="Im3j3n" driver2Number="13" driver2Flag="/de.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Daniel Bodnar" driver1Number="18" driver1Flag="/sk.png" driver2Name="Philip" driver2Number="24" driver2Flag="/nl.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Kalcess" driver1Number="49" driver1Flag="/no.png" driver2Name="Cheers" driver2Number="7" driver2Flag="/de.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Flaw" driver1Number="70" driver1Flag="/nl.png" driver2Name="Romeo Gagliotti" driver2Number="19" driver2Flag="/nl.png" />
          </>
          : selectedDivision === 'Tier B' && selectedSeason === 'Season 2'
            ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Arab" driver1Number="28" driver1Flag="/us.png" driver2Name="Maxfan" driver2Number="33" driver2Flag="/pl.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="ARBITERELITE" driver1Number="2" driver1Flag="/it.png" driver2Name="LUKAS HENDRYCH" driver2Number="20" driver2Flag="/cz.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="UKLUCASW" driver1Number="66" driver1Flag="/en.png" driver2Name="SP3XTRE" driver2Number="31" driver2Flag="/se.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="KIMIRAI" driver1Number="32" driver1Flag="/de.png" driver2Name="VADE" driver2Number="7" driver2Flag="/se.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="CountMuttly" driver1Number="14" driver1Flag="/dk.png" driver2Name="Melon" driver2Number="5" driver2Flag="/ro.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="VLADREDD" driver1Number="86" driver1Flag="/ro.png" driver2Name="ANDREW" driver2Number="91" driver2Flag="/ro.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Anoldenemy" driver1Number="19" driver1Flag="/nl.png" driver2Name="Lawrenceselling" driver2Number="93" driver2Flag="/ph.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="MAX HOUSLEY" driver1Number="8" driver1Flag="/en.png" driver2Name="KJAERBO" driver2Number="3" driver2Flag="/dk.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Br0wn" driver1Number="32" driver1Flag="/de.png" driver2Name="ZEROTIX" driver2Number="99" driver2Flag="/de.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="KOGODA" driver1Number="21" driver1Flag="/ie.png" driver2Name="BARTUSG" driver2Number="70" driver2Flag="/hu.png" />
          </>
            : selectedDivision === 'Tier C' && selectedSeason === 'Season 2'
              ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Kai" driver1Flag="/en.png" driver2Name="Avid" driver2Flag="/nl.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Saad" driver1Flag="/sa.png" driver2Name="Tib" driver2Flag="/rs.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Jarmo" driver1Flag="/nl.png" driver2Name="Rogier Boersma" driver2Flag="/nl.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Afnonso" driver1Flag="/es.png" driver2Name="George" driver2Flag="/en.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="ukrain aim" driver1Flag="/az.png" driver2Name="jahames" driver2Flag="/blank.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Aspec" driver1Flag="/en.png" driver2Name="Sharky" driver2Flag="/se.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="quintusj" driver1Flag="/nl.png" driver2Name="haz" driver2Flag="/en.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Lukas" driver1Flag="/dk.png" driver2Name="Manuel" driver2Flag="/it.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="luuk" driver1Flag="/nl.png" driver2Name="Aki" driver2Flag="/pl.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Egamer" driver1Flag="/gb-sct.png" driver2Name="Rayhan" driver2Flag="/be.png" />
          </>
              : selectedDivision === 'Tier A' && selectedSeason === 'Season 3'
                ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Thomas Vink" driver1Number="8" driver1Flag="/nl.png" driver2Name="Focuser" driver2Number="2" driver2Flag="/es.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Melon" driver1Number="16" driver1Flag="/ro.png" driver2Name="Kalcess" driver2Number="27" driver2Flag="/no.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Luca" driver1Number="40" driver1Flag="/en.png" driver2Name="Counterfeit" driver2Number="44" driver2Flag="/en.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Faded" driver1Number="30" driver1Flag="/us.png" driver2Name="Nik kei" driver2Number="4" driver2Flag="/ua.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Stra" driver1Number="31" driver1Flag="/ko.png" driver2Name="Veixel" driver2Number="42" driver2Flag="/se.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Alpha Romeo" driver1Number="25" driver1Flag="/nl.png" driver2Name="Antonio Brancati" driver2Number="23" driver2Flag="/it.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Cortes" driver1Number="35" driver1Flag="/es.png" driver2Name="Daniel Bodnar" driver2Number="13" driver2Flag="/sk.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Maxfan" driver1Number="18" driver1Flag="/pl.png" driver2Name="Max Housley" driver2Number="24" driver2Flag="/en.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Sp3xtre" driver1Number="49" driver1Flag="/se.png" driver2Name="EndAndrews" driver2Number="7" driver2Flag="/cz.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Justin Janson" driver1Number="70" driver1Flag="/nl.png" driver2Name="Lukas Hendrych" driver2Number="19" driver2Flag="/cz.png" />
          </>
                : selectedDivision === 'Tier B' && selectedSeason === 'Season 3'
                  ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Kisiel" driver1Number="28" driver1Flag="/pl.png" driver2Name="Lando" driver2Number="33" driver2Flag="/pl.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Wymen" driver1Number="2" driver1Flag="/tr.png" driver2Name="Tsar" driver2Number="20" driver2Flag="/hu.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Vaipz" driver1Number="66" driver1Flag="/dk.png" driver2Name="HackoDK" driver2Number="31" driver2Flag="/dk.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Vade" driver1Number="32" driver1Flag="/se.png" driver2Name="Adam" driver2Number="7" driver2Flag="/en.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Spyzer" driver1Number="14" driver1Flag="/nl.png" driver2Name="UKLUCASW" driver2Number="5" driver2Flag="/en.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Bald" driver1Number="86" driver1Flag="/nl.png" driver2Name="Supergaming" driver2Number="91" driver2Flag="/se.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Cortes" driver1Number="19" driver1Flag="/es.png" driver2Name="Jan Svrdlik" driver2Number="93" driver2Flag="/cz.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Rocky" driver1Number="8" driver1Flag="/de.png" driver2Name="ukrain aim" driver2Number="3" driver2Flag="/az.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Hugyme" driver1Number="32" driver1Flag="/au.png" driver2Name="Dopi" driver2Number="99" driver2Flag="/fi.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Bartusg" driver1Number="21" driver1Flag="/hu.png" driver2Name="Bolar" driver2Number="70" driver2Flag="/nl.png" />
          </>
                  : selectedDivision === 'Tier C' && selectedSeason === 'Season 3'
                    ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Sharky" driver1Flag="/se.png" driver2Name="Lukas" driver2Flag="/dk.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Manuel" driver1Flag="/it.png" driver2Name="afnonso" driver2Flag="/es.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="c0zm1c" driver1Flag="/en.png" driver2Name="Rayhan" driver2Flag="/be.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="BasiqlyCrazy" driver1Flag="/se.png" driver2Name="Sea Pulse" driver2Flag="/nl.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="jarmo" driver1Flag="/nl.png" driver2Name="luuk" driver2Flag="/nl.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="danottok" driver1Flag="/fi.png" driver2Name="Henry" driver2Flag="/us.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Aki" driver1Flag="/pl.png" driver2Name="porp" driver2Flag="/gb.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="CurtisJones" driver1Flag="/gb.png" driver2Name="OGK Snake" driver2Flag="/gb.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Flerber" driver1Flag="/gb.png" driver2Name="Rabas" driver2Flag="/es.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Saad" driver1Flag="/sa.png" driver2Name="Tinos_RL" driver2Flag="/nl.png" />
          </>
                    : selectedDivision === 'Tier A' && selectedSeason === 'Season 4'
                      ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Thomas Vink" driver1Number="3" driver1Flag="/nl.png" driver2Name="Maxfan" driver2Number="93" driver2Flag="/pl.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Mizi" driver1Number="13" driver1Flag="/cz.png" driver2Name="Emil Lundell" driver2Number="36" driver2Flag="/se.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Sp3xtre" driver1Number="40" driver1Flag="/se.png" driver2Name="Lukáš Hendrych" driver2Number="19" driver2Flag="/cz.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Justin Janson" driver1Number="92" driver1Flag="/nl.png" driver2Name="Daniel Bodnár" driver2Number="80" driver2Flag="/sk.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Dallan McTeer" driver1Number="32" driver1Flag="/us.png" driver2Name="Anton Chebotarov" driver2Number="95" driver2Flag="/ua.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Ondřej Macháč" driver1Number="73" driver1Flag="/cz.png" driver2Name="Adam Modan" driver2Number="12" driver2Flag="/gb-eng.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Nedim Lemezan" driver1Number="90" driver1Flag="/ba.png" driver2Name="James Taylor" driver2Number="71" driver2Flag="/cz.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Paaveli" driver1Number="18" driver1Flag="/fi.png" driver2Name="Jack" driver2Number="24" driver2Flag="/gb-sct.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Octavian Ursu" driver1Number="8" driver1Flag="/ro.png" driver2Name="Focuser" driver2Number="42" driver2Flag="/es.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Romeo Gagliotti" driver1Number="68" driver1Flag="/nl.png" driver2Name="Allu" driver2Number="12" driver2Flag="/fi.png" />
          </>
                      : selectedDivision === 'Tier B' && selectedSeason === 'Season 4'
                        ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Luca Stevens" driver1Number="40" driver1Flag="/en.png" driver2Name="Cameron Clarke" driver2Number="72" driver2Flag="/en.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Stra" driver1Number="8" driver1Flag="/rs.png" driver2Name="Wymen" driver2Number="3" driver2Flag="/tr.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Veixel" driver1Number="42" driver1Flag="/se.png" driver2Name="Spyzer" driver2Number="25" driver2Flag="/nl.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Brim" driver1Number="70" driver1Flag="/nl.png" driver2Name="Sharky" driver2Number="71" driver2Flag="/se.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Tsar" driver1Number="17" driver1Flag="/hu.png" driver2Name="Lando" driver2Number="45" driver2Flag="/pl.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Epiclexer" driver1Number="89" driver1Flag="/cz.png" driver2Name="Illyrian" driver2Number="9" driver2Flag="/al.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Bald" driver1Number="88" driver1Flag="/ie.png" driver2Name="Cammy" driver2Number="66" driver2Flag="/gb-sct.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Yulian Kalenantovich" driver1Number="87" driver1Flag="/ua.png" driver2Name="Manuel" driver2Number="90" driver2Flag="/it.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Tony Seroka" driver1Number="80" driver1Flag="/pl.png" driver2Name="Lionator" driver2Number="19" driver2Flag="/de.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Enis Noka" driver1Number="38" driver1Flag="/al.png" driver2Name="Nojus Gedvillas" driver2Number="56" driver2Flag="/lt.png" />
          </>
                        : selectedDivision === 'Tier C' && selectedSeason === 'Season 4'
                          ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Enis" driver1Flag="/al.png" driver2Name="Lukas" driver2Flag="/dk.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="afnonso" driver1Flag="/es.png" driver2Name="Danottok" driver2Flag="/fi.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="uklucasw" driver1Flag="/en.png" driver2Name="Stefan Ingi" driver2Flag="/is.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Aki" driver1Flag="/pl.png" driver2Name="Egamer" driver2Flag="/gb-sct.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="jarmo" driver1Flag="/nl.png" driver2Name="luuk" driver2Flag="/nl.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="Alfa Romeo" driver1Name="Maurits" driver1Flag="/nl.png" driver2Name="Philip" driver2Flag="/nl.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Tinos RL" driver1Flag="/nl.png" driver2Name="Rabas" driver2Flag="/es.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Teetoe" driver1Flag="/nl.png" driver2Name="porp" driver2Flag="/en.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Ukrain aim" driver1Flag="/az.png" driver2Name="Sharky" driver2Flag="/se.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="TTV Sweat" driver1Flag="/gb-eng.png" driver2Name="Maciekajo" driver2Flag="/pl.png" />
          </>
                          : selectedDivision === 'The Cup' && selectedSeason === 'Season 4'
                            ? <>
          <div className="gridContainer">
          { /* <div className="comingsoon"><h2>TO BE ANNOUNCED™</h2></div> */ }
          <F1TeamCup teamColor="#C18794" teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" teamName="10 Controls" driver1Name="Octavian Ursu" driver1Number="40" driver1Flag="/en.png" driver2Name="Lukáš Hendrych" driver2Number="93" driver2Flag="/en.png" driver3Name="Sauber fan" driver3Number="40" driver3Flag="/en.png" driver4Name="Anton Chebotarov" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Arion" />
          <F1TeamCup teamColor="#181D32" teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" teamName="Virtual Racing League" driver1Name="Emil Lundell" driver1Number="16" driver1Flag="/rs.png" driver2Name="Ondřej Macháč" driver2Number="27" driver2Flag="/gr.png" driver3Name="Katana" driver3Number="40" driver3Flag="/en.png" driver4Name="Bimjobs" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Bimjobs" />
          <F1TeamCup teamColor="#FFD700" teamBackground="rgba(255, 215, 0, 0.2)" teamImage="/PRS.png" teamName="Premier Racing Series" driver1Name="Tsar" driver1Number="40" driver1Flag="/se.png" driver2Name="Ergodog" driver2Number="44" driver2Flag="/nl.png" driver3Name="Campina" driver3Number="40" driver3Flag="/en.png" driver4Name="Joebean74" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Alfie" />
          <F1TeamCup teamColor="#DD8912" teamBackground="rgba(221, 137, 18, 0.2)" teamImage="/OGK.png" teamName="OGKROE" driver1Name="Bigcheff" driver1Number="30" driver1Flag="/ru.png" driver2Name="Robertgab93" driver2Number="4" driver2Flag="/en.png" driver3Name="Boss game" driver3Number="40" driver3Flag="/en.png" driver4Name="Smooth Criminal" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Dom" />
          <F1TeamCup teamColor="#FFFFFF" teamBackground="rgba(255, 255, 255, 0.2)" teamImage="/VF1.png" teamName="VF1 Racing Community" driver1Name="Sp3xtre" driver1Number="31" driver1Flag="/hu.png" driver2Name="Antsa" driver2Number="8" driver2Flag="/pl.png" driver3Name="Anthony" driver3Number="40" driver3Flag="/en.png" driver4Name="Bubbly" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Adama" />
          <F1TeamCup teamColor="#000009" teamBackground="rgba(0, 0, 9, 0.2)" teamImage="/SR.png" teamName="Semi Racing eSports" driver1Name="Miron Pijl" driver1Number="25" driver1Flag="/ng.png" driver2Name="Čića Mile" driver2Number="23" driver2Flag="/al.png" driver3Name="CasperSky" driver3Number="40" driver3Flag="/en.png" driver4Name="TTR Adrian" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Max" />
          <F1TeamCup teamColor="#3BEC2C" teamBackground="rgba(59, 236, 44, 0.2)" teamImage="/POR.png" teamName="Piggy's Online Racing" driver1Name="Cot5" driver1Number="35" driver1Flag="/ie.png" driver2Name="Bigpou" driver2Number="13" driver2Flag="/dk.png" driver3Name="hazza2trappy" driver3Number="40" driver3Flag="/en.png" driver4Name="Lewis Innes" driver4Number="93" driver4Flag="/en.png" teamPrincipal="PIGGY" />
          <F1TeamCup teamColor="#6D5EB4" teamBackground="rgba(109, 94, 180, 0.2)" teamImage="/VASA.png" teamName="VASA Racing League" driver1Name="Hackodk" driver1Number="18" driver1Flag="/ua.png" driver2Name="Kjaerbo" driver2Number="24" driver2Flag="/it.png" driver3Name="Vaipz" driver3Number="40" driver3Flag="/en.png" driver4Name="Larzen" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Vaipz" />
          <F1TeamCup teamColor="#FFF500" teamBackground="rgba(255, 245, 0, 0.2)" teamImage="/GRL.png" teamName="Groundeffect Racing League" driver1Name="ROM Bford" driver1Number="49" driver1Flag="/en.png" driver2Name="Blade" driver2Number="7" driver2Flag="/se.png" driver3Name="JWG1bson" driver3Number="40" driver3Flag="/en.png" driver4Name="darragh thacker" driver4Number="93" driver4Flag="/en.png" teamPrincipal="darragh thacker" />
          <F1TeamCup teamColor="#37BEDD" teamBackground="rgba(0, 0, 255, 0.2)" teamImage="/LRL.png" teamName="Limitless Racing League" driver1Name="Jake" driver1Number="70" driver1Flag="/nl.png" driver2Name="InterstellarZach" driver2Number="19" driver2Flag="/lt.png" driver3Name="Levi" driver3Number="40" driver3Flag="/en.png" driver4Name="JSTN" driver4Number="93" driver4Flag="/en.png" teamPrincipal="Levi" />
          </div>
          </>
                            : selectedDivision === 'Tier A' && selectedSeason === 'Season 5'
                              ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Bald" driver1Number="90" driver1Flag="/ie.png" driver2Name="Luca Stevens" driver2Number="80" driver2Flag="/gb-eng.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Anton Chebotarov" driver1Number="95" driver1Flag="/ua.png" driver2Name="Dallan McTeer" driver2Number="32" driver2Flag="/us.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Lukáš Hendrych" driver1Number="19" driver1Flag="/cz.png" driver2Name="Paaveli" driver2Number="26" driver2Flag="/fi.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Emil Lundell" driver1Number="36" driver1Flag="/se.png" driver2Name="Leo" driver2Number="97" driver2Flag="/ro.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Focuser" driver1Number="42" driver1Flag="/es.png" driver2Name="Jack" driver2Number="88" driver2Flag="/gb-sct.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Antonio Brancati" driver1Number="7" driver1Flag="/it.png" driver2Name="Zeyy" driver2Number="43" driver2Flag="/gb-eng.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Octavian Ursu" driver1Number="8" driver1Flag="/ro.png" driver2Name="Thomas Vink" driver2Number="3" driver2Flag="/nl.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Sp3xtre" driver1Number="40" driver1Flag="/se.png" driver2Name="Allu" driver2Number="41" driver2Flag="/fi.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="Yulian Kalentovich" driver1Number="87" driver1Flag="/ua.png" driver2Name="Tony Seroka" driver2Number="13" driver2Flag="/pl.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Stra" driver1Number="58" driver1Flag="/rs.png" driver2Name="Karlsson" driver2Number="99" driver2Flag="/se.png" />
          </>
                              : selectedDivision === 'Tier B' && selectedSeason === 'Season 5'
                                ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Cammy" driver1Number="99" driver1Flag="/gb-sct.png" driver2Name="Tsar" driver2Number="17" driver2Flag="/hu.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="Manuel" driver1Number="88" driver1Flag="/it.png" driver2Name="Sharky" driver2Number="37" driver2Flag="/se.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Nojus Gedvilas" driver1Number="67" driver1Flag="/lt.png" driver2Name="Jivodar" driver2Number="45" driver2Flag="/bg.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Daniel Bodnár" driver1Number="80" driver1Flag="/sk.png" driver2Name="Spyzer" driver2Number="25" driver2Flag="/nl.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Curtis Kaos" driver1Number="15" driver1Flag="/us.png" driver2Name="Jackz" driver2Number="32" driver2Flag="/gb-eng.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Tinos" driver1Number="13" driver1Flag="/nl.png" driver2Name="Menly1ss" driver2Number="26" driver2Flag="/ua.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="Jstn NL" driver1Number="92" driver1Flag="/nl.png" driver2Name="AlphaRomeo" driver2Number="19" driver2Flag="/nl.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="Jay Azier" driver1Number="90" driver1Flag="/nl.png" driver2Name="Sandro" driver2Number="7" driver2Flag="/nl.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="alfa romeo" driver1Name="LordPerceval" driver1Number="95" driver1Flag="/de.png" driver2Name="Frederik Dawson" driver2Number="84" driver2Flag="/tr.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Pengu" driver1Number="46" driver1Flag="/pl.png" driver2Name="TeeToe" driver2Number="12" driver2Flag="/nl.png" />
          </>
                                : selectedDivision === 'Tier C' && selectedSeason === 'Season 5'
                                  ? <>
          <F1Team teamColor="#3671C6" teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" teamName="Red Bull" driver1Name="Luzxy" driver1Flag="/gb-eng.png" driver2Name="Pablo" driver2Flag="/gb-sct.png" />
          <F1Team teamColor="#6CD3BF" teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" teamName="Mercedes" driver1Name="uklucasw" driver1Flag="/gb-eng.png" driver2Name="Bartusg5" driver2Flag="/hu.png" />
          <F1Team teamColor="#F91536" teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" teamName="Ferrari" driver1Name="Mixx" driver1Flag="/no.png" driver2Name="Akerdre7m" driver2Flag="/se.png" />
          <F1Team teamColor="#F58020" teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" teamName="Mclaren" driver1Name="Dotty" driver1Flag="/gb-eng.png" driver2Name="TabulateStorm" driver2Flag="/gb-eng.png" />
          <F1Team teamColor="#358C75" teamBackground="rgba(53, 140, 117, 0.2)" teamImage="/aston-martin.png" teamName="Aston Martin" driver1Name="Maximonster" driver1Flag="/nl.png" driver2Name="Paddyx" driver2Flag="/ie.png" />
          <F1Team teamColor="#2293D1" teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" teamName="Alpine" driver1Name="Stefan Ingi" driver1Flag="/is.png" driver2Name="Zsomborilles" driver2Flag="/hu.png" />
          <F1Team teamColor="#37BEDD" teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" teamName="Williams" driver1Name="CasperSky" driver1Flag="/pl.png" driver2Name="Jahamez" driver2Flag="/gb-eng.png" />
          <F1Team teamColor="#5E8FAA" teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" teamName="Alphatauri" driver1Name="x ArtiK Sc0l" driver1Flag="/se.png" driver2Name="TheoTH" driver2Flag="/se.png" />
          <F1Team teamColor="#C92D4B" teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" teamName="Alfa Romeo" driver1Name="Joe Caruso" driver1Flag="/ca.png" driver2Name="Randro" driver2Flag="/uy.png" />
          <F1Team teamColor="#B6BABD" teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" teamName="Haas" driver1Name="Keyes Baud" driver1Flag="/ca.png" driver2Name="Torelle" driver2Flag="/se.png" />

          </>

                                  : selectedDivision === 'Tier C' && selectedSeason === 'Season 1'
                                    ? setSelectedDivision('Tier A')
                                    : (selectedDivision === 'The Cup' && selectedSeason === 'Season 1') || (selectedDivision === 'The Cup' && selectedSeason === 'Season 2')
                                        ? setSelectedDivision('Tier A')
                                        : <> <h4 id='broken'>SOMETHING BROKE</h4></>
    }
    </div>
    <Footer />
    </div>
    </>
  )
}
