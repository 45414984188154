import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesGreatBritainA4 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Thomas Vink', interval: '3.155', fastestLap: '1:28.182', teamName: 'Red Bull', flag: '/nl.png', gridChange: '6', arrow: 'up' })
  results.push({ name: 'Maxfan', interval: '21.508', fastestLap: '1:28.152', teamName: 'Red Bull', flag: '/pl.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Emil Lundell', interval: '0.072', fastestLap: '1:28.063', teamName: 'Ferrari', flag: '/se.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Mizi', interval: '6.402', fastestLap: '1:28.111', teamName: 'Ferrari', flag: '/cz.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Lukáš Hendrych', interval: '1.717', fastestLap: '1:28.028', teamName: 'Mercedes', flag: '/cz.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Sp3xtre', interval: '3.616', fastestLap: '1:28.185', teamName: 'Mercedes', flag: '/se.png', gridChange: '13', arrow: 'up' })
  results.push({ name: 'Justin Janson', interval: '17.468', fastestLap: '1:28.303', teamName: 'Alpine', flag: '/nl.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Daniel Bodnár', interval: '28.395', fastestLap: '1:29.161', teamName: 'Alpine', flag: '/sk.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'James Taylor', interval: '10001', fastestLap: '1:28.236', teamName: 'McLaren', flag: '/cz.png', gridChange: '7', arrow: 'down' })
  results.push({ name: 'Dallan McTeer', interval: '20.073', fastestLap: '1:29.003', teamName: 'McLaren', flag: '/us.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Soy Bean', interval: '15.925', fastestLap: '1:29.081', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Adam Modan', interval: '0', fastestLap: '1:28.572', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Dopichu', interval: '10003', fastestLap: '1:29.879', teamName: 'Aston Martin', flag: '/fi.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Nedim Lemezan', interval: '2.050', fastestLap: '1:28.144', teamName: 'Aston Martin', flag: '/ba.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Paaveli', interval: '9.695', fastestLap: '1:28.340', teamName: 'Haas', flag: '/fi.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Jack', interval: '10002', fastestLap: '1:26.874', fastest: true, teamName: 'Haas', flag: '/gb-sct.png', gridChange: '10', arrow: 'down' })
  results.push({ name: 'Octavian Ursu', interval: '2.504', fastestLap: '1:27,290', teamName: 'AlphaTauri', flag: '/ro.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Focuser', interval: '17.979', fastestLap: '1:28.517', teamName: 'AlphaTauri', flag: '/es.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Romeo Gagliotti', interval: '13.835', fastestLap: '1:29.002', teamName: 'Williams', flag: '/nl.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Allu', interval: '10000', fastestLap: '1:28.447', teamName: 'Williams', flag: '/fi.png', gridChange: '10', arrow: 'down' })
  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
  <div className="page-content">
  <h1 className="page-content-heading">Great Britain</h1>
  <h2 className="race-subtext">Tier A - Season 4</h2>
  <h3 className="race-subtext">Round 10 of 17</h3>
  <table className="results-table">
    <tr>
      <th></th>
      <th className="flag-result"></th>
      <th></th>
      <th className="team-image-standing"></th>
      <th className="grid-position"></th>
      <th><h6>Interval</h6></th>
      <th className="fastest-lap"><h6>Fastest Lap</h6></th>
      <th><h6>Pts</h6></th>
      </tr>
        {results.map(driver => {
          const driverid = results.findIndex(d => d.name === driver.name)
          let interval = '+' + sortedResults[driverid].interval
          let position = driverid + 1
          if (sortedResults[driverid].interval === '0') interval = 'WINNER'
          if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
            const amount = Math.floor(sortedResults[driverid].interval / 1000)
            let lap
            if (amount >= 1 && amount < 2) lap = ' Lap'
            else lap = ' Laps'
            interval = '+' + amount + lap
          }
          if (parseInt(sortedResults[driverid].interval) >= 10000) {
            interval = 'DNF'
            position = '-'
          }

          const team = driver.teamName.replace(/\s/g, '').toLowerCase()
          const background = teams[team].background
          const image = teams[team].image
          let arrow = '-'
          if (driver.arrow) {
            if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
            else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
          }

          let lapColor = '#FFFFFF'
          let points = pointsformat[driverid]
          if (driver.fastest && (driverid + 1 <= 10)) {
            lapColor = '#ff00ff'
            points++
          } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

          return (
            <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
          )
        })}
      </table>
      <div className="driver-day-container">
        <div>
          <h4>Driver of the day</h4>
        </div>
        <div>
        <h3>Adam Modan</h3>
        </div>
      </div>
    </div>
  )
}
