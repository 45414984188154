import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesSaudiArabiaCup3 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Saudi Arabia</h1>
        <h2 className="race-subtext">The Cup - Season 3</h2>
        <h3 className="race-subtext">Round 5 of 8</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(255, 32, 2, 0.2)" teamImage="/PM.png" constructorName="Prime Motorsport" driverName="Tony Riché" driverFlag="/fr.png" driverInterval="WINNER" driverFastLap="" driverGrid="-" driverPosition="1" driverPoints="25"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+0.206" driverFastLap="" driverGrid="-" driverPosition="2" driverPoints="18"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Lifebout" driverFlag="/gb-eng.png" driverInterval="+6.284" driverFastLap="" driverGrid="4" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="+8.322" driverFastLap="" driverGrid="1" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Thomas Vink" driverFlag="/nl.png" driverInterval="+15.817" driverFastLap="" driverGrid="-" driverPosition="5" driverPoints="10"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="Alex Fox-Johnson" driverFlag="/gb-eng.png" driverInterval="+18.521" driverFastLap="" driverGrid="3" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Light Zolta" driverFlag="/cz.png" driverInterval="+19.965" driverFastLap="" driverGrid="8" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(240, 183, 78, 0.2)" teamImage="/XPG.png" constructorName="XPG Esports" driverName="Flame" driverFlag="/gb-eng.png" driverInterval="+19.965" driverFastLap="" driverGrid="8" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Gloomy" driverFlag="/nl.png" driverInterval="+21.781" driverFastLap="" driverGrid="2" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(240, 183, 78, 0.2)" teamImage="/XPG.png" constructorName="XPG Esports" driverName="MartinezPinezzz" driverFlag="/se.png" driverInterval="+23.000" driverFastLap="" driverGrid="2" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Anton Chebotarov" driverFlag="/ua.png" driverInterval="+25.653" driverFastLap="" driverGrid="7" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="BanterBranden" driverFlag="/gb-eng.png" driverInterval="+1:12.118" driverFastLap="" driverGrid="2" driverPosition="12" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="JP" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="" driverGrid="5" driverPosition="-" driverPoint="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="Lewis Pyper" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="1" driverPosition="-" driverPoints="15" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(235, 80, 89, 0.2)" teamImage="/SRL.png" constructorName="Syndicate Racing League" driverName="OS DEGREEZ" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="5" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Dallan McTeer" driverFlag="/us.png" driverInterval="DNF" driverFastLap="" driverGrid="10" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Lifebout</h3>
          </div>
        </div>
    </div>
  )
}
