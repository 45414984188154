import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBox from './F1RaceBox'
import F1RaceBoxComplete from './F1RaceBoxComplete'
// import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierA3 () {
  const races = []
  return (
    <div className="race-tier-container">
      <F1RaceBoxComplete raceName="Bahrain" raceDate={new Date(2023, 2, 24).getTime()} raceFlag="/bh.png" raceLink="/f1/races/tier-a/season-3/bahrain" videoLink="https://www.youtube.com/watch?v=0A3a1aERL_o"/>
      <F1RaceBoxComplete raceName="Great Britain" raceDate={new Date(2023, 2, 31).getTime()} raceFlag="/gb.png" raceLink="/f1/races/tier-a/season-3/great-britain" videoLink="https://www.youtube.com/watch?v=K348U8ozDaY"/>
      <F1RaceBoxComplete raceName="Monaco" raceDate={new Date(2023, 3, 7).getTime()} raceFlag="/mc.png" raceLink="/f1/races/tier-a/season-3/monaco" videoLink="https://www.youtube.com/watch?v=VBX5eRO61No"/>
      <F1RaceBoxComplete raceName="Belgium (Sprint)" raceDate={new Date(2023, 3, 14).getTime()} raceFlag="/be.png" raceLink="/f1/races/tier-a/season-3/belgium" videoLink="https://www.youtube.com/watch?v=etMC3m6V7-0"/>
      <F1RaceBoxComplete raceName="Miami" raceDate={new Date(2023, 3, 21).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-a/season-3/miami" videoLink="https://www.youtube.com/watch?v=hBaXeZFfEyU"/>
      <F1RaceBoxComplete raceName="Saudi Arabia (Sprint)" raceDate={new Date(2023, 3, 28).getTime()} raceFlag="/sa.png" raceLink="/f1/races/tier-a/season-3/saudi-arabia" videoLink="https://www.youtube.com/watch?v=40HI79jXoGc"/>
      <F1RaceBoxComplete raceName="Netherlands" raceDate={new Date(2023, 4, 5).getTime()} raceFlag="/nl.png" raceLink="/f1/races/tier-a/season-3/netherlands" videoLink="https://www.youtube.com/@10controls22"/>
      <F1RaceBoxComplete raceName="Italy" raceDate={new Date(2023, 4, 12).getTime()} raceFlag="/it.png" raceLink="/f1/races/tier-a/season-3/italy" videoLink="https://www.youtube.com/watch?v=Ue5p9ug_Ors"/>
      <F1RaceBoxComplete raceName="Singapore" raceDate={new Date(2023, 4, 19).getTime()} raceFlag="/sg.png" raceLink="/f1/races/tier-a/season-3/singapore" videoLink="https://www.youtube.com/@10controls22"/>
      <F1RaceBoxComplete raceName="Spain" raceDate={new Date(2023, 4, 26).getTime()} raceFlag="/es.png" raceLink="/f1/races/tier-a/season-3/spain" videoLink="https://www.youtube.com/@10controls22"/>
      {races.map(race => {
        return (<F1RaceBox key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
      })}
    </div>
  )
}
