import React from 'react'
import '../../stylesheets/Articles.css'
import { Link } from 'react-router-dom'

export default function V10EsportsLaunchArticle () {
  return (
    <div className='articleContainer'>
        <h1 className="title">
            V10 ESPORTS ANNOUNCED
        </h1>
        <h4 className='date'>August 13th, 2023</h4>
        <img src="/v10-esports-full.jpg" className='cover'/>
        <div className="contentContainer">
            <p className="content">
                In collaboration with ExA and VF1 Racing Community, we are proud to unveil our cross-platform F1 esports racing team, V10 Esports!
            </p>
            <p className="content">
                V10 Esports is not just about winning; it is about pushing boundaries and setting new standards. We are not here to participate, we are here to dominate (famous last words).
            </p>
            <p className="content">
                V10 is unique in our ability to offer drivers the following:
            </p>
            <p className="content">
                ⁕ 💰 Participation bonuses (regardless of winning or not)
            </p>
            <p className="content">
                ⁕ 💸 Prize Money
            </p>
            <p className="content">
                ⁕ 🤑 Winning bonuses
            </p>
            <p className="content">
                ⁕ 😎 Immaculate vibes
            </p>
            <p>
            Our teams support system is built on a foundation of innovation, training, and mentorship, ensuring that each driver reaches new heights in their virtual racing journey. With V10 Esports, our drivers are not just competitors – they are partners in a relentless pursuit of perfection, and we will stop at nothing to see them shine.
            </p>
            </div>
            <div className="buttonContainer">
                <Link to='https://discord.gg/etEQ65gcvV'><button className="button">JOIN</button></Link>
            </div>
    </div>
  )
}
