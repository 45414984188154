import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesQatarB4 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Saudi Arabia</h1>
        <h2 className="race-subtext">Tier B - Season 4</h2>
        <h3 className="race-subtext">Round 2 of 17</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Cammy" driverFlag="/gb-sct.png" driverInterval="WINNER" driverFastLap="1:30.649" driverGrid="3" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Yulian Kalenantovich" driverFlag="/ua.png" driverInterval="+0.962" driverFastLap="1:30.375" driverGrid="3" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Illyrian LZ9" driverFlag="/al.png" driverInterval="+1.735" driverFastLap="1:30.972" driverGrid="7" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(182, 186, 189, 0.2)" teamImage="/haas.png" constructorName="Haas" driverName="Manuel" driverFlag="/it.png" driverInterval="+1.790" driverFastLap="1:30.929" driverGrid="7" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Nojus Gedvilas" driverFlag="/lt.png" driverInterval="+4.594" driverFastLap="1:30.638" driverGrid="1" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(108, 211, 191, 0.2)" teamImage="/mercedes.png" constructorName="Mercedes" driverName="Short" driverFlag="/gb-eng.png" driverInterval="+6.192" driverFastLap="1:31.320" driverGrid="2" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Stra" driverFlag="/ko.png" driverInterval="+7.900" driverFastLap="1:30.633" driverGrid="5" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Cameron Clarke" driverFlag="/gb-eng.png" driverInterval="+17.062" driverFastLap="1:30.336" driverGrid="7" driverPosition="8" driverPoints="5" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(94, 143, 170, 0.2)" teamImage="/alpha-tauri.png" constructorName="AlphaTauri" driverName="Aspec" driverFlag="/gb-eng.png" driverInterval="+35.659" driverFastLap="1:30.597" driverGrid="6" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(55, 190, 221, 0.2)" teamImage="/williams.png" constructorName="Williams" driverName="Bolar" driverFlag="/nl.png" driverInterval="+96.913" driverFastLap="1:31.636" driverGrid="1" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(201, 45, 75, 0.2)" teamImage="/alfa-romeo.png" constructorName="Alfa Romeo" driverName="Pooldiver" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="1:31.908" driverGrid="4" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(54, 113, 198, 0.2)" teamImage="/red-bull.png" constructorName="Red Bull" driverName="Luca Stevens" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:32.222" driverGrid="2" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(34, 147, 209, 0.2)" teamImage="/alpine.png" constructorName="Alpine" driverName="Jay Azier" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="1:31.861" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(245, 128, 32, 0.2)" teamImage="/mclaren.png" constructorName="Mclaren" driverName="Lando" driverFlag="/pl.png" driverInterval="DNF" driverFastLap="1:31.566" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(249, 21, 54, 0.2)" teamImage="/ferrari.png" constructorName="Ferrari" driverName="Greezeuh" driverFlag="/fr.png" driverInterval="DNF" driverFastLap="1:31.017" driverGrid="8" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Illyrian</h3>
          </div>
        </div>
    </div>
  )
}
