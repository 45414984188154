import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesMexicoCup4 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Mexico</h1>
        <h2 className="race-subtext">The Cup - Season 4</h2>
        <h3 className="race-subtext">Round 2 of 14</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="WINNER" driverFastLap="1:17.047" driverGrid="-" driverPosition="1" driverPoints="26" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(255, 245, 0, 0.2)" teamImage="/GRL.png" constructorName="Groundeffect Racing League" driverName="Bladez" driverFlag="/gb-eng.png" driverInterval="+0.095" driverFastLap="1:17.854" driverGrid="1" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 215, 0, 0.2)" teamImage="/PRS.png" constructorName="Premier Racing Series" driverName="Tsar" driverFlag="/hu.png" driverInterval="+2.107" driverFastLap="1:17.843" driverGrid="6" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+3.570" driverFastLap="1:17.112" driverGrid="1" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 66, 66, 0.2)" teamImage="/SR.png" constructorName="Semi Racing Esports" driverName="CasperSky" driverFlag="/pl.png" driverInterval="+4.550" driverFastLap="1:18.777" driverGrid="2" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 255, 255, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Antsa" driverFlag="/fi.png" driverInterval="+8.235" driverFastLap="1:18.952" driverGrid="-" driverPosition="6" driverPoints="8"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Sauber Fan" driverFlag="/gb-eng.png" driverInterval="+8.754" driverFastLap="1:19.237" driverGrid="3" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(59, 236, 44, 0.2)" teamImage="/POR.png" constructorName="Piggy's Online Racing" driverName="Bigpou" driverFlag="/dz.png" driverInterval="+9.079" driverFastLap="1:17.458" driverGrid="-" driverPosition="8" driverPoints="4"/>
        <F1RaceResult teamBackground="rgba(255, 215, 0, 0.2)" teamImage="/PRS.png" constructorName="Premier Racing Series" driverName="Kampina" driverFlag="/nl.png" driverInterval="+9.385" driverFastLap="1:20.510" driverGrid="2" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 66, 66, 0.2)" teamImage="/SR.png" constructorName="Semi Racing Esports" driverName="Kisiel" driverFlag="/pl.png" driverInterval="+9.527" driverFastLap="1:19.220" driverGrid="2" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(59, 236, 44, 0.2)" teamImage="/POR.png" constructorName="Piggy's Online Racing" driverName="Smiffy" driverFlag="/ro.png" driverInterval="+10.545" driverFastLap="1:19.821" driverGrid="1" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="+14.345" driverFastLap="1:18.714" driverGrid="10" driverPosition="12" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(255, 245, 0, 0.2)" teamImage="/GRL.png" constructorName="Groundeffect Racing League" driverName="Darragh" driverFlag="/gb-eng.png" driverInterval="+11.603" driverFastLap="1:19.254" driverGrid="-" driverPosition="13" driverPoints="0"/>
        <F1RaceResult teamBackground="rgba(221, 137, 18, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Dom" driverFlag="/gb-eng.png" driverInterval="+33.302" driverFastLap="1:21.159" driverGrid="3" driverPosition="14" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(221, 137, 18, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Boss game" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="1:22.567" driverGrid="-" driverPosition="-" driverPoints="0"/>
        <F1RaceResult teamBackground="rgba(0, 0, 255, 0.2)" teamImage="/LRL.png" constructorName="Limitless Racing League" driverName="Jake" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:19.697" driverGrid="2" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 0, 255, 0.2)" teamImage="/LRL.png" constructorName="Limitless Racing League" driverName="Levi" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:21.620" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Octavian Ursu</h3>
          </div>
        </div>
    </div>
  )
}
