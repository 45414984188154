import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBoxCup from './F1RaceBoxCup'
// import F1RaceBox2 from './F1RaceBox2'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesCup4 () {
  const races = [
  ]
  races.push({ id: 9, name: 'Brazil', date: new Date(2023, 10, 1).getTime(), flag: '/br.png' })
  races.push({ id: 10, name: 'Spain', date: new Date(2023, 10, 8).getTime(), flag: '/es.png' })
  races.push({ id: 11, name: 'Miami', date: new Date(2023, 10, 15).getTime(), flag: '/us.png' })
  races.push({ id: 12, name: 'Great Britain', date: new Date(2023, 10, 22).getTime(), flag: '/gb.png' })
  races.push({ id: 13, name: 'France', date: new Date(2023, 10, 29).getTime(), flag: '/fr.png' })
  races.push({ id: 14, name: 'Abu Dhabi', date: new Date(2023, 11, 6).getTime(), flag: '/ae.png' })
  return (
    <div className="race-tier-container">
    <F1RaceBoxComplete raceName="Hungary" raceDate={new Date(2023, 7, 30).getTime()} raceFlag="/hu.png" raceLink="/f1/races/the-cup/season-4/hungary" videoLink="https://www.youtube.com/watch?v=DxriYwPdaOM"/>
    <F1RaceBoxComplete raceName="Mexico" raceDate={new Date(2023, 8, 6).getTime()} raceFlag="/mx.png" raceLink="/f1/races/the-cup/season-4/mexico" videoLink="https://www.youtube.com/watch?v=AMqm01R9BtQ"/>
    <F1RaceBoxComplete raceName="Singapore" raceDate={new Date(2023, 8, 13).getTime()} raceFlag="/sg.png" raceLink="/f1/races/the-cup/season-4/singapore" videoLink="https://www.youtube.com/watch?v=V88PzBKYU8g"/>
    <F1RaceBoxComplete raceName="Japan" raceDate={new Date(2023, 8, 20).getTime()} raceFlag="/jp.png" raceLink="/f1/races/the-cup/season-4/japan" videoLink="https://www.youtube.com/watch?v=ErLozvs_V_I"/>
    <F1RaceBoxComplete raceName="Monaco" raceDate={new Date(2023, 8, 27).getTime()} raceFlag="/mc.png" raceLink="/f1/races/the-cup/season-4/monaco" videoLink="https://www.youtube.com/watch?v=e3hkgiI_fC4"/>
    <F1RaceBoxComplete raceName="Qatar" raceDate={new Date(2023, 9, 4).getTime()} raceFlag="/qa.png" raceLink="/f1/races/the-cup/season-4/qatar" videoLink="https://www.youtube.com/watch?v=nXJJzAym_6w"/>
    <F1RaceBoxComplete raceName="Imola" raceDate={new Date(2023, 9, 11).getTime()} raceFlag="/it.png" raceLink="/f1/races/the-cup/season-4/imola" videoLink="https://www.youtube.com/@10controls22"/>
    <F1RaceBoxComplete raceName="Texas" raceDate={new Date(2023, 9, 18).getTime()} raceFlag="/us.png" raceLink="/f1/races/the-cup/season-4/texas" videoLink="https://www.youtube.com/@10controls22"/>
        {races.map(race => {
          return (<F1RaceBoxCup key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
        })}
        {/* <F1RaceBox raceName="Melbourne" raceDate={['5', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Silverstone" raceDate={['12', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Spa-Francorchamps" raceDate={['19', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Jeddah" raceDate={['26', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Zandvoort" raceDate={['2', <sup>nd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Monza (Sprint)" raceDate={['9', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Hungaroring" raceDate={['16', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Miami" raceDate={['23', <sup>rd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Mexico" raceDate={['30', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Imola" raceDate={['7', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Barcelona" raceDate={['14', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Suzuka" raceDate={['21', <sup>st</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Montreal" raceDate={['28', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Bahrain (Sprint)" raceDate={['4', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Yas Marina" raceDate={['11', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/> */}
    </div>
  )
}
