import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBox from './F1RaceBox'
// import F1RaceBox2 from './F1RaceBox2'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTier1005 () {
  const races = []
  races.push({ id: 3, name: '???', date: new Date(2024, 1, 11).getTime(), flag: '/blank.png' })
  races.push({ id: 4, name: '???', date: new Date(2024, 1, 18).getTime(), flag: '/blank.png' })
  races.push({ id: 5, name: '???', date: new Date(2024, 2, 25).getTime(), flag: '/blank.png' })
  races.push({ id: 6, name: '???', date: new Date(2024, 3, 3).getTime(), flag: '/blank.png' })
  races.push({ id: 7, name: '???', date: new Date(2024, 3, 10).getTime(), flag: '/blank.png' })
  races.push({ id: 8, name: '???', date: new Date(2024, 3, 17).getTime(), flag: '/blank.png' })
  races.push({ id: 9, name: '???', date: new Date(2024, 3, 24).getTime(), flag: '/blank.png' })
  races.push({ id: 10, name: '???', date: new Date(2024, 3, 31).getTime(), flag: '/blank.png' })
  races.push({ id: 11, name: '???', date: new Date(2024, 4, 7).getTime(), flag: '/blank.png' })
  races.push({ id: 12, name: '???', date: new Date(2024, 4, 14).getTime(), flag: '/blank.png' })
  races.push({ id: 13, name: '???', date: new Date(2024, 4, 21).getTime(), flag: '/blank.png' })
  races.push({ id: 14, name: '???', date: new Date(2024, 4, 28).getTime(), flag: '/blank.png' })
  return (
    <div className="race-tier-container">
      <F1RaceBoxComplete raceName="Brazil" raceDate={new Date(2024, 0, 28).getTime()} raceFlag="/br.png" raceLink="/f1/races/tier-100/season-5/brazil" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Abu Dhabi" raceDate={new Date(2024, 1, 4).getTime()} raceFlag="/ae.png" raceLink="/f1/races/tier-100/season-5/abu-dhabi" videoLink="https://www.youtube.com/@10controls24"/>
        {races.map(race => {
          return (<F1RaceBox key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
        })}
    </div>
  )
}
