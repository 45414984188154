import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesBelgiumB4 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Luca Stevens', interval: '10000', fastestLap: '', teamName: 'Red Bull', flag: '/gb-eng.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Cameron Clarke', interval: '4.639', fastestLap: '', teamName: 'Red Bull', flag: '/gb-eng.png', gridChange: '4', arrow: 'up' })
  results.push({ name: 'Veixel', interval: '17.285', fastestLap: '', teamName: 'Mercedes', flag: '/se.png', gridChange: '5', arrow: 'up' })
  results.push({ name: 'Spyzer', interval: '10001', fastestLap: '', teamName: 'Mercedes', flag: '/nl.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Brim', interval: '10004', fastestLap: '', teamName: 'Alpine', flag: '/nl.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Noiz', interval: '0', fastestLap: '', teamName: 'McLaren', flag: '/tr.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Curtis Jones', interval: '10002', fastestLap: '', teamName: 'McLaren', flag: '/gb-eng.png', gridChange: '8', arrow: 'down' })
  results.push({ name: 'Illyrian', interval: '0.009', fastestLap: '', teamName: 'Alfa Romeo', flag: '/al.png', gridChange: '12', arrow: 'up' })
  results.push({ name: 'Epiclexer', interval: '10003', fastestLap: '', teamName: 'Alfa Romeo', flag: '/cz.png', gridChange: '11', arrow: 'down' })
  results.push({ name: 'Bald', interval: '12.194', fastestLap: '', teamName: 'Aston Martin', flag: '/ie.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Yulian Kalenantovich', interval: '8.398', fastestLap: '', teamName: 'Haas', flag: '/ua.png', gridChange: '2', arrow: 'down' })
  results.push({ name: 'Pooldiver', interval: '46.904', fastestLap: '', teamName: 'Haas', flag: '/gb-eng.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Sharky', interval: '7.921', fastestLap: '', teamName: 'AlphaTauri', flag: '/se.png', gridChange: '6', arrow: 'up' })
  results.push({ name: 'Nojus Gedvilas', interval: '87.904', fastestLap: '', teamName: 'Williams', flag: '/lt.png', gridChange: '-', arrow: '' })
  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Belgium</h1>
    <h2 className="race-subtext">Tier B - Season 4</h2>
    <h3 className="race-subtext">Round 8 of 17</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Illyrian</h3>
          </div>
        </div>
      </div>
  )
}
