import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesSpainCup3 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Spain</h1>
        <h2 className="race-subtext">The Cup - Season 3</h2>
        <h3 className="race-subtext">Round 7 of 8</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Thomas Vink" driverFlag="/nl.png" driverInterval="WINNER" driverFastLap="" driverGrid="3" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+1.029" driverFastLap="" driverGrid="12" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="JP" driverFlag="/nl.png" driverInterval="+5.531" driverFastLap="" driverGrid="6" driverPosition="3" driverPoint="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Ghost" driverFlag="/gb-eng.png" driverInterval="+9.673" driverFastLap="" driverGrid="3" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="9.929" driverFastLap="" driverGrid="3" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 35, 156, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Sp3xtre" driverFlag="/se.png" driverInterval="+13.314" driverFastLap="" driverGrid="5" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Dallan McTeer" driverFlag="/us.png" driverInterval="+19.070" driverFastLap="" driverGrid="2" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="+30.149" driverFastLap="" driverGrid="5" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Gloomy" driverFlag="/nl.png" driverInterval="+39.354" driverFastLap="" driverGrid="2" driverPosition="9" driverPoints="2" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Antonio Brancati" driverFlag="/it.png" driverInterval="+43.883" driverFastLap="1:18.288" driverGrid="7" driverPosition="10" driverPoints="2" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Lifebout" driverFlag="/gb-eng.png" driverInterval="+1 LAP" driverFastLap="" driverGrid="5" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="Lewis Pyper" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="-" driverPosition="-" driverPoints="0"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="CasperSky" driverFlag="/pl.png" driverInterval="DNF" driverFastLap="" driverGrid="5" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(240, 183, 78, 0.2)" teamImage="/XPG.png" constructorName="XPG Esports" driverName="LinenRayBok" driverFlag="/se.png" driverInterval="DNF" driverFastLap="" driverGrid="4" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Ondřej Macháč</h3>
          </div>
        </div>
    </div>
  )
}
