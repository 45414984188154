import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
// import F1RaceBoxComingSoon from './F1RaceBoxComingSoon'
// import F1RaceBox2 from './F1RaceBox2'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesCup3 () {
  const races = [
  ]
  races.push({ id: 1, name: 'Australia', date: new Date(2023, 2, 25).getTime(), flag: '/au.png', link: '/f1/races/the-cup/season-3/australia', videoLink: 'https://www.youtube.com/watch?v=WMqTmtG1BOw' })
  races.push({ id: 2, name: 'Great Britain', date: new Date(2023, 3, 1).getTime(), flag: '/gb.png', link: '/f1/races/the-cup/season-3/great-britain', videoLink: 'https://www.youtube.com/@10controls22' })
  races.push({ id: 3, name: 'Abu Dhabi', date: new Date(2023, 3, 8).getTime(), flag: '/ae.png', link: '/f1/races/the-cup/season-3/abu-dhabi', videoLink: 'https://www.youtube.com/@10controls22' })
  races.push({ id: 4, name: 'Bahrain (Sprint)', date: new Date(2023, 3, 15).getTime(), flag: '/bh.png', link: '/f1/races/the-cup/season-3/bahrain', videoLink: 'https://www.youtube.com/@10controls22' })
  races.push({ id: 5, name: 'Saudi Arabia', date: new Date(2023, 3, 22).getTime(), flag: '/sa.png', link: '/f1/races/the-cup/season-3/saudi-arabia', videoLink: 'https://www.youtube.com/watch?v=bVBSwyWH-O4' })
  races.push({ id: 6, name: 'Hungary', date: new Date(2023, 3, 29).getTime(), flag: '/hu.png', link: '/f1/races/the-cup/season-3/hungary', videoLink: 'https://www.youtube.com/@10controls22' })
  races.push({ id: 7, name: 'Spain', date: new Date(2023, 4, 6).getTime(), flag: '/es.png', link: '/f1/races/the-cup/season-3/spain', videoLink: 'https://www.youtube.com/@10controls22' })
  races.push({ id: 8, name: 'Singapore (Sprint)', date: new Date(2023, 4, 13).getTime(), flag: '/sg.png', link: '/f1/races/the-cup/season-3/singapore', videoLink: 'https://www.youtube.com/@10controls22' })
  return (
    <div className="race-tier-container">
        {races.map(race => {
          return (<F1RaceBoxComplete key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} raceLink={race.link} videoLink={race.videoLink} />)
        })}
        {/* <F1RaceBox raceName="Melbourne" raceDate={['5', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Silverstone" raceDate={['12', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Spa-Francorchamps" raceDate={['19', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Jeddah" raceDate={['26', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Zandvoort" raceDate={['2', <sup>nd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Monza (Sprint)" raceDate={['9', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Hungaroring" raceDate={['16', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Miami" raceDate={['23', <sup>rd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Mexico" raceDate={['30', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Imola" raceDate={['7', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Barcelona" raceDate={['14', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Suzuka" raceDate={['21', <sup>st</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Montreal" raceDate={['28', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Bahrain (Sprint)" raceDate={['4', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Yas Marina" raceDate={['11', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/> */}
    </div>
  )
}
