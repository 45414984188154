import React from 'react'
import '../../stylesheets/F1TeamCup.css'
import PropTypes from 'prop-types'

export default function F1TeamCup ({ teamColor, teamBackground, teamImage, teamName, driver1Name, driver1Number, driver1Flag, driver2Name, driver2Number, driver2Flag, driver3Name, driver3Number, driver3Flag, driver4Name, driver4Number, driver4Flag, teamPrincipal }) {
  return (
      <div className="teamcontainer" style={{ border: '4px solid', borderColor: teamColor, backgroundColor: teamBackground }}>
      <img className="teamimage" src={teamImage} />
        <h3 className="teamname">{teamName}</h3>
        <div className="drivers">
          <div className="driver1">{driver1Name}</div>
          <div className="driver2">{driver2Name}</div>
          <div className="driver3">{driver3Name}</div>
          <div className="driver4">{driver4Name}</div>
        </div>
      </div>
  )
}

F1TeamCup.propTypes = {
  teamColor: PropTypes.string.isRequired,
  teamBackground: PropTypes.string.isRequired,
  teamImage: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  driver1Name: PropTypes.string.isRequired,
  driver1Flag: PropTypes.string.isRequired,
  driver1Number: PropTypes.string.isRequired,
  driver2Name: PropTypes.string.isRequired,
  driver2Flag: PropTypes.string.isRequired,
  driver2Number: PropTypes.string.isRequired,
  driver3Name: PropTypes.string.isRequired,
  driver3Flag: PropTypes.string.isRequired,
  driver3Number: PropTypes.string.isRequired,
  driver4Name: PropTypes.string.isRequired,
  driver4Flag: PropTypes.string.isRequired,
  driver4Number: PropTypes.string.isRequired,
  teamPrincipal: PropTypes.string.isRequired
}
