import React from 'react'
import F1ConstructorStanding from '../../F1ConstructorStanding'
import '../../../../stylesheets/F1Standings.css'

export default function F1ConstructorsCup3 () {
  const constructors = []
  constructors.push({ name: '10 Controls', points: '187', order: '0', teamColor: '#C18794', teamBackground: 'rgba(193, 135, 148, 0.2)', teamName: '10 Controls', teamImage: '/10C.png', flag: '/nl.png' })
  constructors.push({ name: 'Virtual Racing League', points: '145', order: '0', teamColor: '#181d32', teamBackground: 'rgba(24, 29, 50, 0.2)', teamName: 'Virtual Racing', teamImage: '/VRL.png', flag: '/it.png' })
  constructors.push({ name: 'Amethyst Racing', points: '100', order: '0', teamColor: '#d557ff', teamBackground: 'rgba(213, 87, 255, 0.2)', teamName: 'Amethyst Racing', teamImage: '/ARL.png', flag: '/se.png' })
  constructors.push({ name: 'No Speed Racing', points: '89', order: '0', teamColor: '#2A8B08', teamBackground: 'rgba(42, 139, 8, 0.2)', teamName: 'No Speed Racing', teamImage: '/NSR.png', flag: '/sk.png' })
  constructors.push({ name: 'Prime Motorsport', points: '83', order: '0', teamColor: '#FF2002', teamBackground: 'rgba(255, 32, 2, 0.2)', teamName: 'Prime Motorsport', teamImage: '/PM.png', flag: '/us.png' })
  constructors.push({ name: 'APPF1 Racing Community', points: '78', order: '0', teamColor: '#FEC726', teamBackground: 'rgba(254, 199, 38, 0.2)', teamName: 'APPF1 Racing Community', teamImage: '/APP.png', flag: '/cz.png' })
  constructors.push({ name: 'OGRKOE', points: '70', order: '0', teamColor: '#212DDB', teamBackground: 'rgba(33, 45, 219, 0.2)', teamName: 'OGKROE', teamImage: '/OGK.png', flag: '/OGK.png' })
  constructors.push({ name: 'XPG Esports', points: '37', order: '0', teamColor: '#F0B74E', teamBackground: 'rgba(240, 183, 78, 0.2)', teamName: 'XPG Esports', teamImage: '/XPG.png', flag: '/XPG.png' })
  constructors.push({ name: 'PowerF1', points: '15', order: '0', teamColor: '#800080', teamBackground: 'rgba(128, 0, 128, 0.2)', teamName: 'PowerF1', teamImage: '/PF1.png', flag: '/powerf1.png' })
  constructors.push({ name: 'VF1 Racing Community', points: '8', order: '0', teamColor: '#00239c', teamBackground: 'rgba(0, 35, 156, 0.2)', teamName: 'VF1 Racing Community', teamImage: '/VF1.png', flag: '/nl.png' })
  const sortedconstructors = constructors.sort((a, b) => {
    if (b.points !== a.points) {
      return b.points - a.points // Sort by points descending
    } else {
      // If the points are equal, sort by order value (highest first)
      return b.order - a.order
    }
  })
  return (
    <div className="constructors-list-4">
        {sortedconstructors.map((constructor, index) => {
          console.log(constructor.name)
          return (
              <F1ConstructorStanding key={constructor.name} teamColor={constructor.teamColor} teamBackground={constructor.teamBackground} teamImage={constructor.teamImage} constructorName={constructor.teamName} constructorPosition={index + 1} constructorPoints={constructor.points} />
          )
        })}
      </div>
  )
}
