/* eslint-disable quotes */
import React from 'react'
import '../../../stylesheets/WRCResultsPage.css'
import F1RaceResult from '../WRCEventResult'
const csv = require('jquery-csv')

export default function WRCEventsPortugal1 () {
  const data = csv.toArray(`Rank,DisplayName,Vehicle,Time,DifferenceToFirst,Platform,Points,
  1,TPABACTOK,Peugeot 205 T16 Evo 2,19:45.823,00:00.000,STEAM,25,
  2,Vickepedian,Ford RS200,19:50.204,00:04.381,STEAM,18,
  3,arionboii,Peugeot 205 T16 Evo 2,20:53.157,01:07.334,STEAM,15,
  4,EsportPizza,Lancia Delta S4,21:16.913,01:31.090,STEAM,12,
  5,go-skydiving2017,Ford RS200,22:35.825,02:50.002,STEAM,10,
  6,Bananacy,Ford RS200,26:08.812,06:22.989,STEAM,8,`)
  data.splice(0, 7)

  /*
-------------------------------------------------------------
  FILL IN THE PROPERTIES FOR EACH DRIVER IN THE RESULT

  REMEMBER TO CHANGE:
    - THE EVENT NAME AT THE TOP OF THE FILE
    - THE EVENT NAME UNDER THE DRIVER LIST
    - THE EVENT TYPE UNDER THE DRIVER LIST
    - THE EVENT NUMBER UNDER THE DRIVER LIST
-------------------------------------------------------------
  */

  const results = []
  let driver = 0
  let dElement = 1
  data.forEach(element => {
    if (element !== '') {
      if (dElement === 1) results.push({ name: '', time: '', diff: '', car: '', points: '' })
      else if (dElement === 2) results[driver].name = element
      else if (dElement === 3) results[driver].car = element
      else if (dElement === 4) results[driver].time = element
      else if (dElement === 5) results[driver].diff = element
      else if (dElement === 7) results[driver].points = element

      if (dElement === 7) {
        dElement = 1
        driver++
      } else dElement++
    }
  })
  const sortedResults = results.sort((a, b) => {
    return a.time - b.time // Sort by time ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Portugal</h1>
    <h3 className="race-subtext">Group B 4WD</h3>
    <br />
    <h2 className="race-subtext">WRC - Season 1</h2>
    <h3 className="race-subtext">Event 4 of 12</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th></th>
        <th><h6>Car</h6></th>
        <th><h6>Time</h6></th>
        <th><h6>Difference</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            const time = sortedResults[driverid].time
            const position = driverid + 1
            const car = sortedResults[driverid].car

            const lapColor = '#FFFFFF'
            const points = sortedResults[driverid].points

            let diff = `+${driver.diff}`
            if (diff === '+00:00.000') diff = '='

            return (
              <F1RaceResult key={driverid} teamBackground={'rgba(252, 76, 0, 0.2)'} constructorName={car} driverName={driver.name} driverFlag={'/driver-icon.png'} driverPosition={position} driverInterval={time} driverPoints={points} driverFastLap={diff} lapColor={lapColor} />
            )
          })}
        </table>
        <br />
        <br />
      </div>
  )
}
