import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesNetherlandsA5 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Anton Chebotarov', interval: '7.833', fastestLap: '1:11.831', teamName: 'Mercedes', flag: '/ua.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Zeyy', interval: '21.265', fastestLap: '1:11.705', teamName: 'Mercedes', flag: '/gb-eng.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Paaveli', interval: '21.557', fastestLap: '1:11.992', teamName: 'Ferrari', flag: '/fi.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Emil Lundell', interval: '0.289', fastestLap: '1:11.627', teamName: 'McLaren', flag: '/se.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Max Housley', interval: '10001', fastestLap: '1:11.827', teamName: 'McLaren', flag: '/gb-eng.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Nikalin', interval: '29.279', fastestLap: '1:11.839', teamName: 'Aston Martin', flag: '/lv.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'TsaR', interval: '10002', fastestLap: '1:11.895', teamName: 'Aston Martin', flag: '/hu.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Melon', interval: '0', fastestLap: '1:11.577', teamName: 'Williams', flag: '/ro.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Thomas Vink', interval: '76.240', fastestLap: '1:11.537', fasteset: true, teamName: 'Williams', flag: '/nl.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Tim Tornemo', interval: '12.151', fastestLap: '1:11.653', teamName: 'AlphaTauri', flag: '/se.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Aleksi Kovero', interval: '3.946', fastestLap: '1:11.675', teamName: 'AlphaTauri', flag: '/fi.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Y. Kalenantovich', interval: '10003', fastestLap: '1:11.781', teamName: 'Alfa Romeo', flag: '/ua.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Adam Modan', interval: '6.746', fastestLap: '1:11.693', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Stra', interval: '1000', fastestLap: '1:11.974', teamName: 'Haas', flag: '/rs.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Karlsson', interval: '17.868', fastestLap: '1:11.860', teamName: 'Haas', flag: '/se.png', gridChange: '3', arrow: 'up' })

  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Netherlands</h1>
    <h2 className="race-subtext">Tier A - Season 5</h2>
    <h3 className="race-subtext">Round 9 of 17</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Melon</h3>
          </div>
        </div>
      </div>
  )
}
