/* eslint-disable quotes */
import React from 'react'
import '../../../stylesheets/WRCResultsPage.css'
import F1RaceResult from '../WRCEventResult'
const csv = require('jquery-csv')

export default function WRCEventsCroatia1 () {
  const data = csv.toArray(`Rank,DisplayName,Vehicle,Time,DifferenceToFirst,Platform,Points,1,TheBac0nat0or,Lancia Delta HF Integrale,17:18.781,00:00.000,STEAM,25,2,Apoula73,Lancia Delta HF Integrale,17:52.278,00:33.497,EA,18,3,Focuser3000,SUBARU Impreza 1995,17:54.824,00:36.043,STEAM,15,4,TPABACTOK,Lancia Delta HF Integrale,17:57.845,00:39.064,STEAM,12,5,scienta,Lancia Delta HF Integrale,18:01.256,00:42.475,STEAM,10,6,V10_TsaR,Lancia Delta HF Integrale,18:17.710,00:58.929,EA,8,7,TarmacPirate,Lancia Delta HF Integrale,18:27.189,01:08.408,STEAM,6,8,itsthebxrman,SUBARU Impreza 1995,18:46.388,01:27.607,STEAM,4,9,PorTMeo,Ford Escort RS Cosworth,19:11.210,01:52.429,STEAM,2,10,PBJellingson,Lancia Delta HF Integrale,19:13.504,01:54.723,XBOX,1,11,rhcp1905,Lancia Delta HF Integrale,19:26.275,02:07.494,XBOX,0,12,xxKUKxx,Lancia Delta HF Integrale,19:30.053,02:11.272,XBOX,0,13,RastaaSquare420,Ford Escort RS Cosworth,19:59.477,02:40.696,STEAM,0,14,Pedauss,SUBARU Impreza 1995,20:10.960,02:52.179,STEAM,0,15,Bananacy,Mitsubishi Galant VR4,21:03.106,03:44.325,STEAM,0,16,Jost691818,Lancia Delta HF Integrale,21:16.211,03:57.430,XBOX,0,17,MrTheKents,SUBARU Impreza 1995,21:45.829,04:27.048,STEAM,0,`)
  data.splice(0, 7)

  /*
-------------------------------------------------------------
  FILL IN THE PROPERTIES FOR EACH DRIVER IN THE RESULT

  REMEMBER TO CHANGE:
    - THE EVENT NAME AT THE TOP OF THE FILE
    - THE EVENT NAME UNDER THE DRIVER LIST
    - THE EVENT TYPE UNDER THE DRIVER LIST
    - THE EVENT NUMBER UNDER THE DRIVER LIST
-------------------------------------------------------------
  */

  const results = []
  let driver = 0
  let dElement = 1
  data.forEach(element => {
    if (element !== '') {
      if (dElement === 1) results.push({ name: '', time: '', diff: '', car: '', points: '' })
      else if (dElement === 2) results[driver].name = element
      else if (dElement === 3) results[driver].car = element
      else if (dElement === 4) results[driver].time = element
      else if (dElement === 5) results[driver].diff = element
      else if (dElement === 7) results[driver].points = element

      if (dElement === 7) {
        dElement = 1
        driver++
      } else dElement++
    }
  })
  const sortedResults = results.sort((a, b) => {
    return a.time - b.time // Sort by time ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Croatia</h1>
    <h3 className="race-subtext">Group A</h3>
    <br />
    <h2 className="race-subtext">WRC - Season 1</h2>
    <h3 className="race-subtext">Event 1 of 12</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th></th>
        <th><h6>Car</h6></th>
        <th><h6>Time</h6></th>
        <th><h6>Difference</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            const time = sortedResults[driverid].time
            const position = driverid + 1
            const car = sortedResults[driverid].car

            const lapColor = '#FFFFFF'
            const points = sortedResults[driverid].points

            let diff = `+${driver.diff}`
            if (diff === '+00:00.000') diff = '='

            return (
              <F1RaceResult key={driverid} teamBackground={'rgba(252, 76, 0, 0.2)'} constructorName={car} driverName={driver.name} driverFlag={'/driver-icon.png'} driverPosition={position} driverInterval={time} driverPoints={points} driverFastLap={diff} lapColor={lapColor} />
            )
          })}
        </table>
        <br />
        <br />
      </div>
  )
}
