import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBox from './F1RaceBox'
// import F1RaceBox2 from './F1RaceBox2'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierB4 () {
  const races = [
  ]
  return (
    <div className="race-tier-container">
    <F1RaceBoxComplete raceName="Qatar" raceDate={new Date(2023, 7, 18).getTime()} raceFlag="/qa.png" raceLink="/f1/races/tier-b/season-4/qatar" videoLink="https://www.youtube.com/watch?v=0XfzpEtve9A"/>
    <F1RaceBoxComplete raceName="Saudi Arabia" raceDate={new Date(2023, 7, 25).getTime()} raceFlag="/sa.png" raceLink="/f1/races/tier-b/season-4/saudi-arabia" videoLink="https://www.youtube.com/watch?v=dzVU6F4YMYY"/>
    <F1RaceBoxComplete raceName="Azerbaijan" raceDate={new Date(2023, 8, 1).getTime()} raceFlag="/az.png" raceLink="/f1/races/tier-b/season-4/azerbaijan" videoLink="https://www.youtube.com/watch?v=7vKDB3P6QoA"/>
    <F1RaceBoxComplete raceName="Austria" raceDate={new Date(2023, 8, 8).getTime()} raceFlag="/at.png" raceLink="/f1/races/tier-b/season-4/austria" videoLink="https://www.youtube.com/watch?v=WeuLyqVCO3A"/>
    <F1RaceBoxComplete raceName="Imola" raceDate={new Date(2023, 8, 15).getTime()} raceFlag="/it.png" raceLink="/f1/races/tier-b/season-4/imola" videoLink="https://www.youtube.com/@10controls22"/>
    <F1RaceBoxComplete raceName="Spain" raceDate={new Date(2023, 8, 22).getTime()} raceFlag="/es.png" raceLink="/f1/races/tier-b/season-4/spain" videoLink="https://www.youtube.com/watch?v=jy949aeAQhs"/>
    <F1RaceBoxComplete raceName="Portugal" raceDate={new Date(2023, 8, 29).getTime()} raceFlag="/pt.png" raceLink="/f1/races/tier-b/season-4/portugal" videoLink="https://www.youtube.com/@10controls22"/>
    <F1RaceBoxComplete raceName="Belgium" raceDate={new Date(2023, 9, 6).getTime()} raceFlag="/be.png" raceLink="/f1/races/tier-b/season-4/belgium" videoLink="https://www.youtube.com/watch?v=2zkoM5pL0_c"/>
    <F1RaceBoxComplete raceName="Netherlands" raceDate={new Date(2023, 9, 13).getTime()} raceFlag="/nl.png" raceLink="/f1/races/tier-b/season-4/netherlands" videoLink="https://www.youtube.com/@10controls22"/>
    <F1RaceBoxComplete raceName="Great Britain" raceDate={new Date(2023, 9, 27).getTime()} raceFlag="/gb.png" raceLink="/f1/races/tier-b/season-4/great-britain" videoLink="https://www.youtube.com/watch?v=p_jQkn2LSAs"/>
    <F1RaceBoxComplete raceName="Canada" raceDate={new Date(2023, 10, 3).getTime()} raceFlag="/ca.png" raceLink="/f1/races/tier-b/season-4/canada" videoLink="https://www.youtube.com/watch?v=Ywt27VbjkJI"/>
    <F1RaceBoxComplete raceName="Texas" raceDate={new Date(2023, 10, 10).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-b/season-4/texas" videoLink="https://www.youtube.com/@10controls22"/>
    <F1RaceBoxComplete raceName="Mexico" raceDate={new Date(2023, 10, 17).getTime()} raceFlag="/mx.png" raceLink="/f1/races/tier-b/season-4/mexico" videoLink="https://www.youtube.com/watch?v=1NX15jBiTUo"/>
    <F1RaceBoxComplete raceName="Las Vegas" raceDate={new Date(2023, 10, 24).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-b/season-4/las-vegas" videoLink="https://www.youtube.com/@10controls22"/>
    <F1RaceBoxComplete raceName="China" raceDate={new Date(2023, 11, 1).getTime()} raceFlag="/cn.png" raceLink="/f1/races/tier-b/season-4/china" videoLink="https://www.youtube.com/watch?v=bCV-1CtYink"/>
    <F1RaceBoxComplete raceName="Japan" raceDate={new Date(2023, 11, 8).getTime()} raceFlag="/jp.png" raceLink="/f1/races/tier-b/season-4/japan" videoLink="https://www.youtube.com/watch?v=BmmxdgSj7DM"/>
    <F1RaceBoxComplete raceName="Brazil" raceDate={new Date(2023, 11, 15).getTime()} raceFlag="/br.png" raceLink="/f1/races/tier-b/season-4/brazil" videoLink="https://www.youtube.com/watch?v=jisP7L4mP_4"/>
        {races.map(race => {
          return (<F1RaceBox key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
        })}
        {/* <F1RaceBox raceName="Melbourne" raceDate={['5', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Silverstone" raceDate={['12', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Spa-Francorchamps" raceDate={['19', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Jeddah" raceDate={['26', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Zandvoort" raceDate={['2', <sup>nd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Monza (Sprint)" raceDate={['9', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Hungaroring" raceDate={['16', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Miami" raceDate={['23', <sup>rd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Mexico" raceDate={['30', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Imola" raceDate={['7', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Barcelona" raceDate={['14', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Suzuka" raceDate={['21', <sup>st</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Montreal" raceDate={['28', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Bahrain (Sprint)" raceDate={['4', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Yas Marina" raceDate={['11', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/> */}
    </div>
  )
}
