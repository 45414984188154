import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesPortugalA4 () {
  const pointsformat = ['25', '18', '15', '12', '10', '8', '6', '4', '2', '1', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Thomas Vink', interval: '14.142', fastestLap: '1:19.204', teamName: 'Red Bull', flag: '/nl.png', gridChange: '5', arrow: 'up' })
  results.push({ name: 'Maxfan', interval: '17.227', fastestLap: '1:19.353', teamName: 'Red Bull', flag: '/pl.png', gridChange: '6', arrow: 'down' })
  results.push({ name: 'Emil Lundell', interval: '0', fastestLap: '1:18.608', teamName: 'Ferrari', flag: '/se.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Mizi', interval: '10001', fastestLap: '1:20.314', teamName: 'Ferrari', flag: '/cz.png', gridChange: '8', arrow: 'down' })
  results.push({ name: 'Lukáš Hendrych', interval: '14.262', fastestLap: '1:19.209', teamName: 'Mercedes', flag: '/cz.png', gridChange: '6', arrow: 'up' })
  results.push({ name: 'Sp3xtre', interval: '66.427', fastestLap: '1:19.838', teamName: 'Mercedes', flag: '/se.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Justin Janson', interval: '10000', fastestLap: '1:19.328', teamName: 'Alpine', flag: '/nl.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Tony Seroka', interval: '28.956', fastestLap: '1:19.874', teamName: 'Alpine', flag: '/sk.png', gridChange: '7', arrow: 'up' })
  results.push({ name: 'Anton Chebotarov', interval: '15.979', fastestLap: '1:19.382', teamName: 'McLaren', flag: '/ua.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Dallan McTeer', interval: '31.850', fastestLap: '1:19.804', teamName: 'McLaren', flag: '/us.png', gridChange: '5', arrow: 'down' })
  results.push({ name: 'Ondřej Macháč', interval: '15.464', fastestLap: '1:19.204', teamName: 'Alfa Romeo', flag: '/cz.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Adam Modan', interval: '19.580', fastestLap: '1:17.779', fastest: true, teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '6', arrow: 'down' })
  results.push({ name: 'Dopichu', interval: '44.590', fastestLap: '1:19.389', teamName: 'Aston Martin', flag: '/fi.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Paaveli', interval: '43.739', fastestLap: '1:20.138', teamName: 'Haas', flag: '/fi.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Jack', interval: '16.532', fastestLap: '1:19.050', teamName: 'Haas', flag: '/gb-eng.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Allu', interval: '12.947', fastestLap: '1:19.008', teamName: 'AlphaTauri', flag: '/fi.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Lionator', interval: '36.095', fastestLap: '1:19.805', teamName: 'AlphaTauri', flag: '/de.png', gridChange: '5', arrow: 'up' })
  results.push({ name: 'Romeo Gagliotti', interval: '34.262', fastestLap: '1:19.622', teamName: 'Williams', flag: '/nl.png', gridChange: '5', arrow: 'down' })
  results.push({ name: 'Reasonb', interval: '2000', fastestLap: '1:19.914', teamName: 'Williams', flag: '/fi.png', gridChange: '3', arrow: 'up' })
  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Portugal</h1>
    <h2 className="race-subtext">Tier A - Season 4</h2>
    <h3 className="race-subtext">Round 7 of 17</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest) {
              lapColor = '#ff00ff'
              points++
            }

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Anirudh Ariyadka</h3>
          </div>
        </div>
      </div>
  )
}
