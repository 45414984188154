import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'
const teams = require('../../F1Teams.json')

export default function F1RacesBrazil1005 () {
  const pointsformat = ['35', '29', '24', '21', '19', '17', '15', '13', '12', '11', '10', '9', '8', '7', '6', '5', '4', '3', '2', '1']

  /*
-------------------------------------------------------------
  LAPPED DRIVERS SHOULD BE PUT AS NUMBER OF LAPS IN THOUSANDS
  1 LAP = 1000
  3 LAPS = 3000
  IF MULTIPLE DRIVERS ARE LAPPED YOU CAN DO
  FIRST LAPPED = 1000
  SECOND LAPPED = 1001
-------------------------------------------------------------
  DNF DRIVERS ARE INTERVALS OVER 10000
  FIRST DNF = 10000
  SECOND DNF = 10001
  THIRD DNF = 10002
-------------------------------------------------------------
  */

  const results = []
  results.push({ name: 'Emil Lundell', interval: '0', fastestLap: '1:09.228', teamName: 'Alfa Romeo', flag: '/se.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Thomas Vink', interval: '9.608', fastestLap: '1:09.540', teamName: 'Red Bull', flag: '/nl.png', gridChange: '1', arrow: 'down' })
  results.push({ name: 'Janmilton', interval: '27.729', fastestLap: '1:09.721', teamName: 'McLaren', flag: '/ga.png', gridChange: '-', arrow: '' })
  results.push({ name: 'Denis K', interval: '30.903', fastestLap: '1:10.149', teamName: 'Haas', flag: '/bg.png', gridChange: '2', arrow: 'up' })
  results.push({ name: 'Bald', interval: '30.922', fastestLap: '1:09.580', teamName: 'Aston Martin', flag: '/ie.png', gridChange: '11', arrow: 'up' })
  results.push({ name: 'Stra', interval: '32.494', fastestLap: '1:09.902', teamName: 'Williams', flag: '/rs.png', gridChange: '7', arrow: 'up' })
  results.push({ name: 'Jivodar', interval: '56.127', fastestLap: '1:10.216', teamName: 'McLaren', flag: '/bg.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'TsaR', interval: '57.742', fastestLap: '1:08.705', fastest: true, teamName: 'Ferrari', flag: '/hu.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Spyzer', interval: '66.224', fastestLap: '1:09.297', teamName: 'Aston Martin', flag: '/nl.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'Sebeinkro', interval: '1000', fastestLap: '1:10.830', teamName: 'Ferrari', flag: '/no.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Curtis Kaos', interval: '1001', fastestLap: '1:09.502', teamName: 'Red Bull', flag: '/us.png', gridChange: '4', arrow: 'down' })
  results.push({ name: 'WizardOz', interval: '10000', fastestLap: '1:10.417', teamName: 'AlphaTauri', flag: '/be.png', gridChange: '3', arrow: 'up' })
  results.push({ name: 'Micha', interval: '10001', fastestLap: '1:08.772', teamName: 'Mercedes', flag: '/nl.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Manuel', interval: '10002', fastestLap: '1:10.831', teamName: 'Mercedes', flag: '/it.png', gridChange: '5', arrow: 'down' })
  results.push({ name: 'Kozak', interval: '10003', fastestLap: '1:11.734', teamName: 'AlphaTauri', flag: '/be.png', gridChange: '3', arrow: 'down' })
  results.push({ name: 'Alerr', interval: '10004', fastestLap: '1:11.072', teamName: 'Alfa Romeo', flag: '/gb-eng.png', gridChange: '1', arrow: 'up' })
  results.push({ name: 'Wymen', interval: '10005', fastestLap: '1:12.483', teamName: 'Haas', flag: '/tr.png', gridChange: '7', arrow: 'down' })

  const sortedResults = results.sort((a, b) => {
    return a.interval - b.interval // Sort by interval ascending
  })
  return (
    <div className="page-content">
    <h1 className="page-content-heading">Brazil</h1>
    <h2 className="race-subtext">Tier 100 - Season 5</h2>
    <h3 className="race-subtext">Round 1 of 14</h3>
    <table className="results-table">
      <tr>
        <th></th>
        <th className="flag-result"></th>
        <th></th>
        <th className="team-image-standing"></th>
        <th className="grid-position"></th>
        <th><h6>Interval</h6></th>
        <th className="fastest-lap"><h6>Fastest Lap</h6></th>
        <th><h6>Pts</h6></th>
        </tr>
          {results.map(driver => {
            const driverid = results.findIndex(d => d.name === driver.name)
            let interval = '+' + sortedResults[driverid].interval
            let position = driverid + 1
            if (sortedResults[driverid].interval === '0') interval = 'WINNER'
            if (parseInt(sortedResults[driverid].interval) >= 1000 && parseInt(sortedResults[driverid].interval) < 10000) {
              const amount = Math.floor(sortedResults[driverid].interval / 1000)
              let lap
              if (amount >= 1 && amount < 2) lap = ' Lap'
              else lap = ' Laps'
              interval = '+' + amount + lap
            }
            if (parseInt(sortedResults[driverid].interval) >= 10000) {
              interval = 'DNF'
              position = '-'
            }

            const team = driver.teamName.replace(/\s/g, '').toLowerCase()
            const background = teams[team].background
            const image = teams[team].image
            let arrow = '-'
            if (driver.arrow) {
              if (driver.arrow.toLowerCase() === 'up') arrow = 'fa-solid fa-angle-up'
              else if (driver.arrow.toLowerCase() === 'down') arrow = 'fa-solid fa-angle-down'
            }

            let lapColor = '#FFFFFF'
            let points = pointsformat[driverid]
            if (driver.fastest && (driverid + 1 <= 10)) {
              lapColor = '#ff00ff'
              points++
            } else if (driver.fastest && (driverid + 1 > 10)) lapColor = '#ff00ff'

            return (
              <F1RaceResult key={driverid} teamBackground={background} teamImage={image} constructorName={driver.teamName} driverName={driver.name} driverFlag={driver.flag} driverPosition={position} driverInterval={interval} driverGrid={driver.gridChange} driverFastLap={driver.fastestLap} arrow={arrow} driverPoints={points} lapColor={lapColor} />
            )
          })}
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Thomas Vink</h3>
          </div>
        </div>
      </div>
  )
}
