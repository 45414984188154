import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBox from './F1RaceBox'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierA2 () {
  const races = [
  ]
  return (
    <div className="race-tier-container">
      <F1RaceBoxComplete raceName="Australia" raceDate={new Date(2022, 11, 2).getTime()} raceFlag="/au.png" raceLink="/f1/races/tier-a/season-2/australia" videoLink="https://www.youtube.com/watch?v=vIwjRb97EXw"/>
      <F1RaceBoxComplete raceName="Portugal" raceDate={new Date(2022, 11, 9).getTime()} raceFlag="/pt.png" raceLink="/f1/races/tier-a/season-2/portugal" videoLink="https://www.youtube.com/watch?v=Fzu8GGtsgNg"/>
      <F1RaceBoxComplete raceName="France (Sprint)" raceDate={new Date(2022, 11, 16).getTime()} raceFlag="/fr.png" raceLink="/f1/races/tier-a/season-2/france" videoLink="https://www.youtube.com/watch?v=EsIOA2crutc"/>
      <F1RaceBoxComplete raceName="Japan" raceDate={new Date(2023, 0, 6).getTime()} raceFlag="/jp.png" raceLink="/f1/races/tier-a/season-2/japan" videoLink="https://www.youtube.com/watch?v=PzhicbQGaX4"/>
      <F1RaceBoxComplete raceName="Hungary" raceDate={new Date(2023, 0, 13).getTime()} raceFlag="/hu.png" raceLink="/f1/races/tier-a/season-2/hungary" videoLink="https://www.youtube.com/watch?v=ccWjV6nppiY"/>
      <F1RaceBoxComplete raceName="Austria (Sprint)" raceDate={new Date(2023, 0, 20).getTime()} raceFlag="/at.png" raceLink="/f1/races/tier-a/season-2/austria" videoLink="https://www.youtube.com/watch?v=8-TlKZYW5h4"/>
      <F1RaceBoxComplete raceName="Azerbaijan" raceDate={new Date(2023, 0, 27).getTime()} raceFlag="/az.png" raceLink="/f1/races/tier-a/season-2/azerbaijan" videoLink="https://www.youtube.com/watch?v=DCzd0qxRVk0"/>
      <F1RaceBoxComplete raceName="China" raceDate={new Date(2023, 1, 3).getTime()} raceFlag="/cn.png" raceLink="/f1/races/tier-a/season-2/china" videoLink="https://www.youtube.com/watch?v=VfIok-85HT4"/>
      <F1RaceBoxComplete raceName="Texas (Sprint)" raceDate={new Date(2023, 1, 10).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-a/season-2/texas" videoLink="https://www.youtube.com/watch?v=9YYJ9Reb0G8"/>
      <F1RaceBoxComplete raceName="Imola" raceDate={new Date(2023, 1, 17).getTime()} raceFlag="/it.png" raceLink="/f1/races/tier-a/season-2/imola" videoLink="https://www.youtube.com/@10controls22"/>
      <F1RaceBoxComplete raceName="Singapore" raceDate={new Date(2023, 1, 24).getTime()} raceFlag="/sg.png" raceLink="/f1/races/tier-a/season-2/singapore" videoLink="https://www.youtube.com/@10controls22" />
      <F1RaceBoxComplete raceName="Brazil" raceDate={new Date(2023, 2, 3).getTime()} raceFlag="/br.png" raceLink="/f1/races/tier-a/season-2/brazil" videoLink="https://www.youtube.com/@10controls22"/>
      {races.map(race => {
        return (<F1RaceBox key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
      })}
        {/* <F1RaceBox raceName="Melbourne" raceDate={['5', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Silverstone" raceDate={['12', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Spa-Francorchamps" raceDate={['19', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Jeddah" raceDate={['26', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Zandvoort" raceDate={['2', <sup>nd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Monza (Sprint)" raceDate={['9', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Hungaroring" raceDate={['16', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Miami" raceDate={['23', <sup>rd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Mexico" raceDate={['30', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Imola" raceDate={['7', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Barcelona" raceDate={['14', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Suzuka" raceDate={['21', <sup>st</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Montreal" raceDate={['28', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Bahrain (Sprint)" raceDate={['4', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Yas Marina" raceDate={['11', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/> */}
    </div>
  )
}
