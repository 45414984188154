import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBoxCancelled from './F1RaceBoxCancelled'
// import F1RaceBox2 from './F1RaceBox2'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierC5 () {
  const races = []
  races.push({ id: 5, name: 'Monza', date: new Date(2024, 1, 17).getTime(), flag: '/it.png' })
  races.push({ id: 6, name: 'France', date: new Date(2024, 1, 24).getTime(), flag: '/fr.png' })
  races.push({ id: 7, name: 'Austria', date: new Date(2024, 2, 2).getTime(), flag: '/at.png' })
  races.push({ id: 8, name: 'Monaco', date: new Date(2024, 2, 9).getTime(), flag: '/mc.png' })
  races.push({ id: 9, name: 'Netherlands', date: new Date(2024, 2, 16).getTime(), flag: '/nl.png' })
  races.push({ id: 10, name: 'Spain', date: new Date(2024, 2, 23).getTime(), flag: '/es.png' })
  races.push({ id: 11, name: 'Great Britain', date: new Date(2024, 2, 30).getTime(), flag: '/gb.png' })
  races.push({ id: 12, name: 'Singapore', date: new Date(2024, 3, 6).getTime(), flag: '/sg.png' })
  races.push({ id: 13, name: 'Japan', date: new Date(2024, 3, 13).getTime(), flag: '/jp.png' })
  races.push({ id: 14, name: 'Bahrain', date: new Date(2024, 3, 20).getTime(), flag: '/bh.png' })
  races.push({ id: 15, name: 'Qatar', date: new Date(2024, 3, 27).getTime(), flag: '/qa.png' })
  races.push({ id: 16, name: 'Saudi Arabia', date: new Date(2024, 4, 4).getTime(), flag: '/sa.png' })
  races.push({ id: 17, name: 'Abu Dhabi', date: new Date(2024, 4, 11).getTime(), flag: '/ae.png' })
  return (
    <div className="race-tier-container">
      <F1RaceBoxComplete raceName="Australia" raceDate={new Date(2024, 0, 20).getTime()} raceFlag="/au.png" raceLink="/f1/races/tier-c/season-5/australia" videoLink="https://www.youtube.com/watch?v=QsfPPpJQ5ws"/>
      <F1RaceBoxComplete raceName="Texas" raceDate={new Date(2024, 0, 27).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-c/season-5/texas" videoLink="https://www.youtube.com/watch?v=ijUHGVSjoU4"/>
      <F1RaceBoxComplete raceName="Brazil" raceDate={new Date(2024, 1, 3).getTime()} raceFlag="/br.png" raceLink="/f1/races/tier-c/season-5/brazil" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Hungary" raceDate={new Date(2024, 1, 10).getTime()} raceFlag="/hu.png" raceLink="/f1/races/tier-c/season-5/hungary" videoLink="https://www.youtube.com/@10controls24"/>
      {races.map(race => {
        return (<F1RaceBoxCancelled key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
      })}
    </div>
  )
}
