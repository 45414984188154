import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBox from './F1RaceBox'
// import F1RaceBox2 from './F1RaceBox2'
import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierB5 () {
  const races = []
  races.push({ id: 13, name: 'Japan', date: new Date(2024, 3, 12).getTime(), flag: '/jp.png' })
  races.push({ id: 14, name: 'Bahrain', date: new Date(2024, 3, 19).getTime(), flag: '/bh.png' })
  races.push({ id: 15, name: 'Qatar', date: new Date(2024, 3, 26).getTime(), flag: '/qa.png' })
  races.push({ id: 16, name: 'Saudi Arabia', date: new Date(2024, 4, 3).getTime(), flag: '/sa.png' })
  races.push({ id: 17, name: 'Abu Dhabi', date: new Date(2024, 4, 10).getTime(), flag: '/ae.png' })
  return (
    <div className="race-tier-container">
      <F1RaceBoxComplete raceName="Australia" raceDate={new Date(2024, 0, 19).getTime()} raceFlag="/au.png" raceLink="/f1/races/tier-b/season-5/australia" videoLink="https://www.youtube.com/watch?v=BMvj8kBueIY"/>
      <F1RaceBoxComplete raceName="Texas" raceDate={new Date(2024, 0, 26).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-b/season-5/texas" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Brazil" raceDate={new Date(2024, 1, 2).getTime()} raceFlag="/br.png" raceLink="/f1/races/tier-b/season-5/brazil" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Hungary" raceDate={new Date(2024, 1, 9).getTime()} raceFlag="/hu.png" raceLink="/f1/races/tier-b/season-5/hungary" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Italy" raceDate={new Date(2024, 1, 16).getTime()} raceFlag="/it.png" raceLink="/f1/races/tier-b/season-5/italy" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="France" raceDate={new Date(2024, 1, 23).getTime()} raceFlag="/fr.png" raceLink="/f1/races/tier-b/season-5/france" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Austria" raceDate={new Date(2024, 2, 1).getTime()} raceFlag="/at.png" raceLink="/f1/races/tier-b/season-5/austria" videoLink="https://www.youtube.com/watch?v=33dz6fYj-m8"/>
      <F1RaceBoxComplete raceName="Monaco" raceDate={new Date(2024, 2, 8).getTime()} raceFlag="/mc.png" raceLink="/f1/races/tier-b/season-5/monaco" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Netherlands" raceDate={new Date(2024, 2, 15).getTime()} raceFlag="/nl.png" raceLink="/f1/races/tier-b/season-5/netherlands" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Spain" raceDate={new Date(2024, 2, 22).getTime()} raceFlag="/es.png" raceLink="/f1/races/tier-b/season-5/spain" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Great Britain" raceDate={new Date(2024, 2, 29).getTime()} raceFlag="/gb.png" raceLink="/f1/races/tier-b/season-5/great-britain" videoLink="https://www.youtube.com/@10controls24"/>
      <F1RaceBoxComplete raceName="Singapore" raceDate={new Date(2024, 3, 5).getTime()} raceFlag="/sg.png" raceLink="/f1/races/tier-b/season-5/singapore" videoLink="https://www.youtube.com/@10controls24"/>
        {races.map(race => {
          return (<F1RaceBox key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
        })}
    </div>
  )
}
