/* eslint-disable no-lone-blocks */
import React from 'react'
import '../../../App.css'
import '../../../stylesheets/F1RacesTier.css'
import WRCRaceBox from '../WRCRaceBox'
// import F1RaceBox2 from './F1RaceBox2'
import WRCRaceBoxComplete from '../WRCRaceBoxComplete'

export default function WRCCalendar1 () {
  const races = [
  ]
  races.push({ id: 5, name: 'Monte-carlo', type: 'H1 FWD', date: new Date(2023, 11, 3).getTime(), flag: '/mc.png' })
  races.push({ id: 6, name: 'Finland', type: 'WRC', date: new Date(2023, 11, 10).getTime(), flag: '/fi.png' })
  races.push({ id: 7, name: 'Iberia', type: 'F2 Kitcar', date: new Date(2023, 11, 17).getTime(), flag: '/es.png' })
  races.push({ id: 8, name: 'Italy', type: 'H3 RWD', date: new Date(2023, 11, 24).getTime(), flag: '/it.png' })
  races.push({ id: 9, name: 'Mediterraneo', type: 'NR4/R4', date: new Date(2023, 11, 31).getTime(), flag: '/fr.png' })
  races.push({ id: 10, name: 'Sweden', type: 'S1600', date: new Date(2024, 0, 7).getTime(), flag: '/se.png' })
  races.push({ id: 11, name: 'Estonia', type: 'Rally4', date: new Date(2024, 0, 14).getTime(), flag: '/ee.png' })
  races.push({ id: 12, name: 'Japan', type: 'WRC 1997-2011', date: new Date(2024, 0, 21).getTime(), flag: '/jp.png' })
  return (
    <div className="race-tier-container">
        <WRCRaceBoxComplete raceName="Croatia" raceType='Group A' raceDate={new Date(2023, 10, 12).getTime()} raceFlag="/hr.png" raceLink="/wrc/events/season-1/croatia"/>
        <WRCRaceBoxComplete raceName="Kenya" raceType='S2000' raceDate={new Date(2023, 10, 19).getTime()} raceFlag="/ke.png" raceLink="/wrc/events/season-1/kenya"/>
        <WRCRaceBoxComplete raceName="Scandia" raceType='WRC2' raceDate={new Date(2023, 10, 26).getTime()} raceFlag="/no.png" raceLink="/wrc/events/season-1/scandia"/>
        <WRCRaceBoxComplete raceName="Portugal" raceType='Group B 4WD' raceDate={new Date(2023, 11, 3).getTime()} raceFlag="/pt.png" raceLink="/wrc/events/season-1/portugal"/>
        {races.map(race => {
          return (<WRCRaceBox key={race.id} raceName={race.name} raceType={race.type} raceDateStart={race.date} raceFlag={race.flag} />)
        })}
    </div>
  )
}
