import React from 'react'
import './App.css'
import ScrollToTop from './components/ScrollToTop'
import Navigation from './components/Navigation'
import Home from './components/Home'
import F1SelectTeam from './components/f1/F1SelectTeam'
import F1Main from './components/f1/F1Main'
import F1Races from './components/f1/F1Races'
import F1Register from './components/f1/F1Register'
import F1Drivers from './components/f1/F1Drivers'
import F1Standings from './components/f1/F1Standings'
import F1RacesMelbourneA from './components/f1/Tier A/Season 1/F1RacesMelbourneA'
import F1RacesMelbourneB from './components/f1/Tier B/Season 1/F1RacesMelbourneB'
import F1RacesSilverstoneA from './components/f1/Tier A/Season 1/F1RacesSilverstoneA'
import F1RacesSilverstoneB from './components/f1/Tier B/Season 1/F1RacesSilverstoneB'
import F1RacesSpaA from './components/f1/Tier A/Season 1/F1RacesSpaA'
import F1RacesSpaB from './components/f1/Tier B/Season 1/F1RacesSpaB'
import F1RacesJeddahA from './components/f1/Tier A/Season 1/F1RacesJeddahA'
import F1RacesJeddahB from './components/f1/Tier B/Season 1/F1RacesJeddahB'
import F1RacesZandvoortA from './components/f1/Tier A/Season 1/F1RacesZandvoortA'
import F1RacesZandvoortB from './components/f1/Tier B/Season 1/F1RacesZandvoortB'
import F1RacesMonzaA from './components/f1/Tier A/Season 1/F1RacesMonzaA'
import F1RacesMonzaB from './components/f1/Tier B/Season 1/F1RacesMonzaB'
import F1RacesMonzaC from './components/f1/F1RacesMonzaC'
import F1RacesHungaroringA from './components/f1/Tier A/Season 1/F1RacesHungaroringA'
import F1RacesHungaroringB from './components/f1/Tier B/Season 1/F1RacesHungaroringB'
import F1RacesHungaroringC from './components/f1/F1RacesHungaroringC'
import F1RacesMiamiA from './components/f1/Tier A/Season 1/F1RacesMiamiA'
import F1RacesMexicoA from './components/f1/Tier A/Season 1/F1RacesMexicoA'
import F1RacesMexicoB from './components/f1/Tier B/Season 1/F1RacesMexicoB'
import F1RacesMiamiB from './components/f1/Tier B/Season 1/F1RacesMiamiB'
import F1RacesImolaA from './components/f1/Tier A/Season 1/F1RacesImolaA'
import F1RacesImolaB from './components/f1/Tier B/Season 1/F1RacesImolaB'
import F1RacesSpainA from './components/f1/Tier A/Season 1/F1RacesSpainA'
import F1RacesSpainB from './components/f1/Tier B/Season 1/F1RacesSpainB'
import F1RacesSuzukaA from './components/f1/Tier A/Season 1/F1RacesSuzukaA'
import F1RacesSuzukaB from './components/f1/Tier B/Season 1/F1RacesSuzukaB'
import F1RacesMontrealA from './components/f1/Tier A/Season 1/F1RacesMontrealA'
import F1RacesMontrealB from './components/f1/Tier B/Season 1/F1RacesMontrealB'
import F1RacesBahrainA from './components/f1/Tier A/Season 1/F1RacesBahrainA'
import F1RacesBahrainB from './components/f1/Tier B/Season 1/F1RacesBahrainB'
import F1RacesAbuDhabiA from './components/f1/Tier A/Season 1/F1RacesAbuDhabiA'
import F1RacesAbuDhabiB from './components/f1/Tier B/Season 1/F1RacesAbuDhabiB'
import F1RacesAustraliaA2 from './components/f1/Tier A/Season 2/F1RacesAustraliaA'
import F1RacesAustraliaB2 from './components/f1/Tier B/Season 2/F1RacesAustraliaB'
import F1RacesPortugalA2 from './components/f1/Tier A/Season 2/F1RacesPortugalA'
import F1RacesPortugalB2 from './components/f1/Tier B/Season 2/F1RacesPortugalB'
import F1RacesFranceA2 from './components/f1/Tier A/Season 2/F1RacesFranceA'
import F1RacesFranceB2 from './components/f1/Tier B/Season 2/F1RacesFranceB'
import F1RacesFranceC2 from './components/f1/Tier C/Season 2/F1RacesFranceC'
import F1RacesJapanA2 from './components/f1/Tier A/Season 2/F1RacesJapanA'
import F1RacesJapanB2 from './components/f1/Tier B/Season 2/F1RacesJapanB'
import F1RacesHungaryA2 from './components/f1/Tier A/Season 2/F1RacesHungaryA'
import F1RacesHungaryB2 from './components/f1/Tier B/Season 2/F1RacesHungaryB'
import F1RacesAustriaA2 from './components/f1/Tier A/Season 2/F1RacesAustriaA'
import F1RacesAustriaB2 from './components/f1/Tier B/Season 2/F1RacesAustriaB'
import F1RacesAzerbaijanA2 from './components/f1/Tier A/Season 2/F1RacesAzerbaijanA'
import F1RacesAzerbaijanB2 from './components/f1/Tier B/Season 2/F1RacesAzerbaijanB'
import F1RacesChinaA2 from './components/f1/Tier A/Season 2/F1RacesChinaA'
import F1RacesChinaB2 from './components/f1/Tier B/Season 2/F1RacesChinaB'
import F1RacesTexasA2 from './components/f1/Tier A/Season 2/F1RacesTexasA'
import F1RacesTexasB2 from './components/f1/Tier B/Season 2/F1RacesTexasB'
import F1RacesImolaA2 from './components/f1/Tier A/Season 2/F1RacesImolaA'
import F1RacesImolaB2 from './components/f1/Tier B/Season 2/F1RacesImolaB'
import F1RacesSingaporeA2 from './components/f1/Tier A/Season 2/F1RacesSingaporeA'
import F1RacesSingaporeB2 from './components/f1/Tier B/Season 2/F1RacesSingaporeB'
import F1RacesBrazilA2 from './components/f1/Tier A/Season 2/F1RacesBrazilA'
import F1RacesBrazilB2 from './components/f1/Tier B/Season 2/F1RacesBrazilB'
import F1RacesBahrainA3 from './components/f1/Tier A/Season 3/F1RacesBahrainA'
import F1RacesBahrainB3 from './components/f1/Tier B/Season 3/F1RacesBahrainB'
import F1RacesGreatBritainA3 from './components/f1/Tier A/Season 3/F1RacesGreatBritainA'
import F1RacesGreatBritainB3 from './components/f1/Tier B/Season 3/F1RacesGreatBritainB'
import F1RacesMonacoA3 from './components/f1/Tier A/Season 3/F1RacesMonacoA'
import F1RacesMonacoB3 from './components/f1/Tier B/Season 3/F1RacesMonacoB'
import F1RacesBelgiumA3 from './components/f1/Tier A/Season 3/F1RacesBelgiumA'
import F1RacesBelgiumB3 from './components/f1/Tier B/Season 3/F1RacesBelgiumB'
import F1RacesMiamiA3 from './components/f1/Tier A/Season 3/F1RacesMiamiA'
import F1RacesMiamiB3 from './components/f1/Tier B/Season 3/F1RacesMiamiB'
import F1RacesSaudiArabiaA3 from './components/f1/Tier A/Season 3/F1RacesSaudiArabiaA'
import F1RacesSaudiArabiaB3 from './components/f1/Tier B/Season 3/F1RacesSaudiArabiaB'
import F1RacesNetherlandsA3 from './components/f1/Tier A/Season 3/F1RacesNetherlandsA'
import F1RacesNetherlandsB3 from './components/f1/Tier B/Season 3/F1RacesNetherlandsB'
import F1RacesItalyA3 from './components/f1/Tier A/Season 3/F1RacesItalyA'
import F1RacesSingaporeA3 from './components/f1/Tier A/Season 3/F1RacesSingaporeA'
import F1RacesSpainA3 from './components/f1/Tier A/Season 3/F1RacesSpainA'
import F1RacesAustraliaCup3 from './components/f1/The Cup/Season 3/F1RacesAustraliaCup'
import F1RacesGreatBritainCup3 from './components/f1/The Cup/Season 3/F1RacesGreatBritainCup'
import F1RacesAbuDhabiCup3 from './components/f1/The Cup/Season 3/F1RacesAbuDhabiCup'
import F1RacesBahrainCup3 from './components/f1/The Cup/Season 3/F1RacesBahrainCup'
import F1RacesSaudiArabiaCup3 from './components/f1/The Cup/Season 3/F1RacesSaudiArabiaCup'
import F1RacesHungaryCup3 from './components/f1/The Cup/Season 3/F1RacesHungaryCup'
import F1RacesSpainCup3 from './components/f1/The Cup/Season 3/F1RacesSpainCup'
import F1RacesSingaporeCup3 from './components/f1/The Cup/Season 3/F1RacesSingaporeCup'
import F1RacesQatarA4 from './components/f1/Tier A/Season 4/F1RacesQatarA'
import F1RacesQatarB4 from './components/f1/Tier B/Season 4/F1RacesQatarB'
import F1RacesQatarC4 from './components/f1/Tier C/Season 4/F1RacesQatarC'
import F1RacesSaudiArabiaA4 from './components/f1/Tier A/Season 4/F1RacesSaudiArabiaA'
import F1RacesSaudiArabiaB4 from './components/f1/Tier B/Season 4/F1RacesSaudiArabiaB'
import F1RacesSaudiArabiaC4 from './components/f1/Tier C/Season 4/F1RacesSaudiArabiaC'
import F1RacesHungaryCup4 from './components/f1/The Cup/Season 4/F1RacesHungaryCup'
import F1RacesAzerbaijanA4 from './components/f1/Tier A/Season 4/F1RacesAzerbaijanA'
import F1RacesAzerbaijanB4 from './components/f1/Tier B/Season 4/F1RacesAzerbaijanB'
import F1RacesAzerbaijanC4 from './components/f1/Tier C/Season 4/F1RacesAzerbaijanC'
import F1RacesMexicoCup4 from './components/f1/The Cup/Season 4/F1RacesMexicoCup'
import F1RacesAustriaA4 from './components/f1/Tier A/Season 4/F1RacesAustriaA'
import F1RacesAustriaB4 from './components/f1/Tier B/Season 4/F1RacesAustriaB'
import F1RacesAustriaC4 from './components/f1/Tier C/Season 4/F1RacesAustriaC'
import F1RacesImolaA4 from './components/f1/Tier A/Season 4/F1RacesImolaA'
import F1RacesImolaB4 from './components/f1/Tier B/Season 4/F1RacesImolaB'
import F1RacesImolaC4 from './components/f1/Tier C/Season 4/F1RacesImolaC'
import F1RacesSpainA4 from './components/f1/Tier A/Season 4/F1RacesSpainA'
import F1RacesSpainB4 from './components/f1/Tier B/Season 4/F1RacesSpainB'
import F1RacesSpainC4 from './components/f1/Tier C/Season 4/F1RacesSpainC'
import F1RacesPortugalA4 from './components/f1/Tier A/Season 4/F1RacesPortugalA'
import F1RacesPortugalB4 from './components/f1/Tier B/Season 4/F1RacesPortugalB'
import F1RacesPortugalC4 from './components/f1/Tier C/Season 4/F1RacesPortugalC'
import F1RacesSingaporeCup4 from './components/f1/The Cup/Season 4/F1RacesSingaporeCup'
import F1RacesBelgiumA4 from './components/f1/Tier A/Season 4/F1RacesBelgiumA'
import F1RacesBelgiumB4 from './components/f1/Tier B/Season 4/F1RacesBelgiumB'
import F1RacesBelgiumC4 from './components/f1/Tier C/Season 4/F1RacesBelgiumC'
import F1RacesJapanCup4 from './components/f1/The Cup/Season 4/F1RacesJapanCup'
import V10EsportsLaunchArticle from './components/articles/v10esportslaunch'
import F1RacesMonacoCup4 from './components/f1/The Cup/Season 4/F1RacesMonacoCup'
import F1RacesQatarCup4 from './components/f1/The Cup/Season 4/F1RacesQatarCup'
import F1RacesNetherlandsA4 from './components/f1/Tier A/Season 4/F1RacesNetherlandsA'
import F1RacesNetherlandsB4 from './components/f1/Tier B/Season 4/F1RacesNetherlandsB'
import F1RacesNetherlandsC4 from './components/f1/Tier C/Season 4/F1RacesNetherlandsC'
import F1RacesImolaCup4 from './components/f1/The Cup/Season 4/F1RacesImolaCup'
import F1RacesTexasCup4 from './components/f1/The Cup/Season 4/F1RacesTexasCup'
import F1RacesGreatBritainA4 from './components/f1/Tier A/Season 4/F1RacesGreatBritainA'
import F1RacesGreatBritainB4 from './components/f1/Tier B/Season 4/F1RacesGreatBritainB'
import F1RacesGreatBritainC4 from './components/f1/Tier C/Season 4/F1RacesGreatBritainC'
import F1RacesCanadaA4 from './components/f1/Tier A/Season 4/F1RacesCanadaA'
import F1RacesCanadaB4 from './components/f1/Tier B/Season 4/F1RacesCanadaB'
import F1RacesCanadaC4 from './components/f1/Tier C/Season 4/F1RacesCanadaC'
import F1RacesTexasA4 from './components/f1/Tier A/Season 4/F1RacesTexasA'
import F1RacesTexasB4 from './components/f1/Tier B/Season 4/F1RacesTexasB'
import F1RacesTexasC4 from './components/f1/Tier C/Season 4/F1RacesTexasC'
import F1RacesMexicoA4 from './components/f1/Tier A/Season 4/F1RacesMexicoA'
import F1RacesMexicoB4 from './components/f1/Tier B/Season 4/F1RacesMexicoB'
import F1RacesMexicoC4 from './components/f1/Tier C/Season 4/F1RacesMexicoC'
import F1RacesLasVegasA4 from './components/f1/Tier A/Season 4/F1RacesLasVegasA'
import F1RacesLasVegasB4 from './components/f1/Tier B/Season 4/F1RacesLasVegasB'
import F1RacesLasVegasC4 from './components/f1/Tier C/Season 4/F1RacesLasVegasC'
import F1RacesChinaA4 from './components/f1/Tier A/Season 4/F1RacesChinaA'
import F1RacesChinaB4 from './components/f1/Tier B/Season 4/F1RacesChinaB'
import F1RacesChinaC4 from './components/f1/Tier C/Season 4/F1RacesChinaC'
import F1RacesJapanA4 from './components/f1/Tier A/Season 4/F1RacesJapanA'
import F1RacesJapanB4 from './components/f1/Tier B/Season 4/F1RacesJapanB'
import F1RacesJapanC4 from './components/f1/Tier C/Season 4/F1RacesJapanC'
import F1RacesBrazilA4 from './components/f1/Tier A/Season 4/F1RacesBrazilA'
import F1RacesBrazilB4 from './components/f1/Tier B/Season 4/F1RacesBrazilB'
import F1RacesBrazilC4 from './components/f1/Tier C/Season 4/F1RacesBrazilC'
import F1RacesAustraliaA5 from './components/f1/Tier A/Season 5/F1RacesAustraliaA'
import F1RacesAustraliaB5 from './components/f1/Tier B/Season 5/F1RacesAustraliaB'
import F1RacesAustraliaC5 from './components/f1/Tier C/Season 5/F1RacesAustraliaC'
import F1RacesTexasA5 from './components/f1/Tier A/Season 5/F1RacesTexasA'
import F1RacesTexasB5 from './components/f1/Tier B/Season 5/F1RacesTexasB'
import F1RacesTexasC5 from './components/f1/Tier C/Season 5/F1RacesTexasC'
import F1RacesBrazilA5 from './components/f1/Tier A/Season 5/F1RacesBrazilA'
import F1RacesBrazilB5 from './components/f1/Tier B/Season 5/F1RacesBrazilB'
import F1RacesBrazilC5 from './components/f1/Tier C/Season 5/F1RacesBrazilC'
import F1RacesHungaryA5 from './components/f1/Tier A/Season 5/F1RacesHungaryA'
import F1RacesHungaryB5 from './components/f1/Tier B/Season 5/F1RacesHungaryB'
import F1RacesHungaryC5 from './components/f1/Tier C/Season 5/F1RacesHungaryC'
import F1RacesItalyA5 from './components/f1/Tier A/Season 5/F1RacesItalyA'
import F1RacesItalyB5 from './components/f1/Tier B/Season 5/F1RacesItalyB'
import F1RacesFranceA5 from './components/f1/Tier A/Season 5/F1RacesFranceA'
import F1RacesFranceB5 from './components/f1/Tier B/Season 5/F1RacesFranceB'
import F1RacesAustriaA5 from './components/f1/Tier A/Season 5/F1RacesAustriaA'
import F1RacesAustriaB5 from './components/f1/Tier B/Season 5/F1RacesAustriaB'
import F1RacesMonacoA5 from './components/f1/Tier A/Season 5/F1RacesMonacoA'
import F1RacesMonacoB5 from './components/f1/Tier B/Season 5/F1RacesMonacoB'
import F1RacesNetherlandsA5 from './components/f1/Tier A/Season 5/F1RacesNetherlandsA'
import F1RacesNetherlandsB5 from './components/f1/Tier B/Season 5/F1RacesNetherlandsB'
import F1RacesSpainA5 from './components/f1/Tier A/Season 5/F1RacesSpainA'
import F1RacesSpainB5 from './components/f1/Tier B/Season 5/F1RacesSpainB'
import F1RacesGreatBritainB5 from './components/f1/Tier B/Season 5/F1RacesGreatBritainB'
import F1RacesSingaporeB5 from './components/f1/Tier B/Season 5/F1RacesSingaporeB'
import F1RacesBrazil1005 from './components/f1/Tier 100/Season 5/F1RacesBrazil100'
import F1RacesAbuDhabi1005 from './components/f1/Tier 100/Season 5/F1RacesAbuDhabi100'
import WRCMain from './components/wrc/WRCMain'
import WRCStandings from './components/wrc/WRCStandings'
import WRCEvents from './components/wrc/WRCEvents'
import WRCEventsCroatia1 from './components/wrc/Season 1/WRCEventsCroatia1'
import WRCEventsKenya1 from './components/wrc/Season 1/WRCEventsKenya1'
import WRCEventsScandia1 from './components/wrc/Season 1/WRCEventsScandia1'
import WRCEventsPortugal1 from './components/wrc/Season 1/WRCEventsPortugal1'
import Admin from './components/admin/Admin'
// import AdminACC from './components/admin/AdminACC'
import AdminF1 from './components/admin/AdminF1'
import AdminF1Seasons from './components/admin/AdminF1Seasons'
// import AdminRL from './components/admin/AdminRL'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App () {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <header>
          <Navigation />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/f1" element={<F1Main />} />
            <Route path="/f1/select-team" element={<F1SelectTeam />} />
            <Route path="/f1/races" element={<F1Races />} />
            <Route path="/f1/races/tier-a/season-1/melbourne" element={<F1RacesMelbourneA />} />
            <Route path="/f1/races/tier-b/melbourne" element={<F1RacesMelbourneB />} />
            <Route path="/f1/races/tier-a/silverstone" element={<F1RacesSilverstoneA />} />
            <Route path="/f1/races/tier-b/silverstone" element={<F1RacesSilverstoneB />} />
            <Route path="/f1/races/tier-a/spa-francorchamps" element={<F1RacesSpaA />} />
            <Route path="/f1/races/tier-b/spa-francorchamps" element={<F1RacesSpaB />} />
            <Route path="/f1/races/tier-a/jeddah" element={<F1RacesJeddahA />} />
            <Route path="/f1/races/tier-b/jeddah" element={<F1RacesJeddahB />} />
            <Route path="/f1/races/tier-a/zandvoort" element={<F1RacesZandvoortA />} />
            <Route path="/f1/races/tier-b/zandvoort" element={<F1RacesZandvoortB />} />
            <Route path="/f1/races/tier-a/hungaroring" element={<F1RacesHungaroringA />} />
            <Route path="/f1/races/tier-b/hungaroring" element={<F1RacesHungaroringB />} />
            <Route path="/f1/races/tier-c/hungaroring" element={<F1RacesHungaroringC />} />
            <Route path="/f1/races/tier-a/miami" element={<F1RacesMiamiA />} />
            <Route path="/f1/races/tier-b/miami" element={<F1RacesMiamiB />} />
            <Route path="/f1/races/tier-b/monza" element={<F1RacesMonzaB />} />
            <Route path="/f1/races/tier-c/monza" element={<F1RacesMonzaC />} />
            <Route path="/f1/races/tier-a/monza" element={<F1RacesMonzaA />} />
            <Route path="/f1/races/tier-a/mexico" element={<F1RacesMexicoA />} />
            <Route path="/f1/races/tier-b/mexico" element={<F1RacesMexicoB />} />
            <Route path="/f1/races/tier-a/imola" element={<F1RacesImolaA />} />
            <Route path="/f1/races/tier-b/imola" element={<F1RacesImolaB />} />
            <Route path="/f1/races/tier-a/spain" element={<F1RacesSpainA />} />
            <Route path="/f1/races/tier-a/suzuka" element={<F1RacesSuzukaA />} />
            <Route path="/f1/races/tier-b/suzuka" element={<F1RacesSuzukaB />} />
            <Route path="/f1/races/tier-b/spain" element={<F1RacesSpainB />} />
            <Route path="/f1/races/tier-a/montreal" element={<F1RacesMontrealA />} />
            <Route path="/f1/races/tier-b/montreal" element={<F1RacesMontrealB />} />
            <Route path="/f1/races/tier-a/bahrain" element={<F1RacesBahrainA />} />
            <Route path="/f1/races/tier-b/bahrain" element={<F1RacesBahrainB />} />
            <Route path="/f1/races/tier-a/abu-dhabi" element={<F1RacesAbuDhabiA />} />
            <Route path="/f1/races/tier-b/abu-dhabi" element={<F1RacesAbuDhabiB />} />
            <Route path="/f1/races/tier-a/season-2/australia" element={<F1RacesAustraliaA2 />} />
            <Route path="/f1/races/tier-b/season-2/australia" element={<F1RacesAustraliaB2 />} />
            <Route path="/f1/races/tier-a/season-2/portugal" element={<F1RacesPortugalA2 />} />
            <Route path="/f1/races/tier-b/season-2/portugal" element={<F1RacesPortugalB2 />} />
            <Route path="/f1/races/tier-a/season-2/france" element={<F1RacesFranceA2 />} />
            <Route path="/f1/races/tier-b/season-2/france" element={<F1RacesFranceB2 />} />
            <Route path="/f1/races/tier-c/season-2/france" element={<F1RacesFranceC2 />} />
            <Route path="/f1/races/tier-a/season-2/japan" element={<F1RacesJapanA2 />} />
            <Route path="/f1/races/tier-b/season-2/japan" element={<F1RacesJapanB2 />} />
            <Route path="/f1/races/tier-a/season-2/hungary" element={<F1RacesHungaryA2 />} />
            <Route path="/f1/races/tier-b/season-2/hungary" element={<F1RacesHungaryB2 />} />
            <Route path="/f1/races/tier-a/season-2/austria" element={<F1RacesAustriaA2 />} />
            <Route path="/f1/races/tier-b/season-2/austria" element={<F1RacesAustriaB2 />} />
            <Route path="/f1/races/tier-a/season-2/azerbaijan" element={<F1RacesAzerbaijanA2 />} />
            <Route path="/f1/races/tier-b/season-2/azerbaijan" element={<F1RacesAzerbaijanB2 />} />
            <Route path="/f1/races/tier-a/season-2/china" element={<F1RacesChinaA2 />} />
            <Route path="/f1/races/tier-b/season-2/china" element={<F1RacesChinaB2 />} />
            <Route path="/f1/races/tier-a/season-2/texas" element={<F1RacesTexasA2 />} />
            <Route path="/f1/races/tier-b/season-2/texas" element={<F1RacesTexasB2 />} />
            <Route path="/f1/races/tier-a/season-2/imola" element={<F1RacesImolaA2 />} />
            <Route path="/f1/races/tier-b/season-2/imola" element={<F1RacesImolaB2 />} />
            <Route path="/f1/races/tier-a/season-2/singapore" element={<F1RacesSingaporeA2 />} />
            <Route path="/f1/races/tier-b/season-2/singapore" element={<F1RacesSingaporeB2 />} />
            <Route path="/f1/races/tier-a/season-2/brazil" element={<F1RacesBrazilA2 />} />
            <Route path="/f1/races/tier-b/season-2/brazil" element={<F1RacesBrazilB2 />} />
            <Route path="/f1/races/tier-a/season-3/bahrain" element={<F1RacesBahrainA3 />} />
            <Route path="/f1/races/tier-b/season-3/bahrain" element={<F1RacesBahrainB3 />} />
            <Route path="/f1/races/tier-a/season-3/great-britain" element={<F1RacesGreatBritainA3 />} />
            <Route path="/f1/races/tier-b/season-3/great-britain" element={<F1RacesGreatBritainB3 />} />
            <Route path="/f1/races/tier-a/season-3/monaco" element={<F1RacesMonacoA3 />} />
            <Route path="/f1/races/tier-b/season-3/monaco" element={<F1RacesMonacoB3 />} />
            <Route path="/f1/races/tier-a/season-3/belgium" element={<F1RacesBelgiumA3 />} />
            <Route path="/f1/races/tier-b/season-3/belgium" element={<F1RacesBelgiumB3 />} />
            <Route path="/f1/races/tier-a/season-3/miami" element={<F1RacesMiamiA3 />} />
            <Route path="/f1/races/tier-b/season-3/miami" element={<F1RacesMiamiB3 />} />
            <Route path="/f1/races/tier-a/season-3/saudi-arabia" element={<F1RacesSaudiArabiaA3 />} />
            <Route path="/f1/races/tier-b/season-3/saudi-arabia" element={<F1RacesSaudiArabiaB3 />} />
            <Route path="/f1/races/tier-a/season-3/netherlands" element={<F1RacesNetherlandsA3 />} />
            <Route path="/f1/races/tier-b/season-3/netherlands" element={<F1RacesNetherlandsB3 />} />
            <Route path="/f1/races/tier-a/season-3/italy" element={<F1RacesItalyA3 />} />
            <Route path="/f1/races/tier-a/season-3/singapore" element={<F1RacesSingaporeA3 />} />
            <Route path="/f1/races/tier-a/season-3/spain" element={<F1RacesSpainA3 />} />
            <Route path="/f1/races/the-cup/season-3/australia" element={<F1RacesAustraliaCup3 />} />
            <Route path="/f1/races/the-cup/season-3/great-britain" element={<F1RacesGreatBritainCup3 />} />
            <Route path="/f1/races/the-cup/season-3/abu-dhabi" element={<F1RacesAbuDhabiCup3 />} />
            <Route path="/f1/races/the-cup/season-3/bahrain" element={<F1RacesBahrainCup3 />} />
            <Route path="/f1/races/the-cup/season-3/saudi-arabia" element={<F1RacesSaudiArabiaCup3 />} />
            <Route path="/f1/races/the-cup/season-3/hungary" element={<F1RacesHungaryCup3 />} />
            <Route path="/f1/races/the-cup/season-3/spain" element={<F1RacesSpainCup3/>} />
            <Route path="/f1/races/the-cup/season-3/singapore" element={<F1RacesSingaporeCup3 />} />
            <Route path="/f1/races/tier-a/season-4/qatar" element={<F1RacesQatarA4 />} />
            <Route path="/f1/races/tier-b/season-4/qatar" element={<F1RacesQatarB4 />} />
            <Route path="/f1/races/tier-c/season-4/qatar" element={<F1RacesQatarC4 />} />
            <Route path="/f1/races/tier-a/season-4/saudi-arabia" element={<F1RacesSaudiArabiaA4 />} />
            <Route path="/f1/races/tier-b/season-4/saudi-arabia" element={<F1RacesSaudiArabiaB4 />} />
            <Route path="/f1/races/tier-c/season-4/saudi-arabia" element={<F1RacesSaudiArabiaC4 />} />
            <Route path="/f1/races/the-cup/season-4/hungary" element={<F1RacesHungaryCup4 />} />
            <Route path="/f1/races/tier-a/season-4/azerbaijan" element={<F1RacesAzerbaijanA4 />} />
            <Route path="/f1/races/tier-b/season-4/azerbaijan" element={<F1RacesAzerbaijanB4 />} />
            <Route path="/f1/races/tier-c/season-4/azerbaijan" element={<F1RacesAzerbaijanC4 />} />
            <Route path="/f1/races/the-cup/season-4/mexico" element={<F1RacesMexicoCup4 />} />
            <Route path="/f1/races/tier-a/season-4/austria" element={<F1RacesAustriaA4 />} />
            <Route path="/f1/races/tier-b/season-4/austria" element={<F1RacesAustriaB4 />} />
            <Route path="/f1/races/tier-c/season-4/austria" element={<F1RacesAustriaC4 />} />
            <Route path="/f1/races/tier-a/season-4/imola" element={<F1RacesImolaA4 />} />
            <Route path="/f1/races/tier-b/season-4/imola" element={<F1RacesImolaB4 />} />
            <Route path="/f1/races/tier-c/season-4/imola" element={<F1RacesImolaC4 />} />
            <Route path="/f1/races/the-cup/season-4/singapore" element={<F1RacesSingaporeCup4 />} />
            <Route path="/f1/races/the-cup/season-4/japan" element={<F1RacesJapanCup4 />} />
            <Route path="/f1/races/tier-a/season-4/spain" element={<F1RacesSpainA4 />} />
            <Route path="/f1/races/tier-b/season-4/spain" element={<F1RacesSpainB4 />} />
            <Route path="/f1/races/tier-c/season-4/spain" element={<F1RacesSpainC4 />} />
            <Route path="/f1/races/tier-a/season-4/portugal" element={<F1RacesPortugalA4 />} />
            <Route path="/f1/races/tier-b/season-4/portugal" element={<F1RacesPortugalB4 />} />
            <Route path="/f1/races/tier-c/season-4/portugal" element={<F1RacesPortugalC4 />} />
            <Route path="/f1/races/tier-a/season-4/belgium" element={<F1RacesBelgiumA4 />} />
            <Route path="/f1/races/tier-b/season-4/belgium" element={<F1RacesBelgiumB4 />} />
            <Route path="/f1/races/tier-c/season-4/belgium" element={<F1RacesBelgiumC4 />} />
            <Route path="/f1/races/the-cup/season-4/monaco" element={<F1RacesMonacoCup4 />} />
            <Route path="/f1/races/the-cup/season-4/qatar" element={<F1RacesQatarCup4 />} />
            <Route path="/f1/races/tier-a/season-4/netherlands" element={<F1RacesNetherlandsA4 />} />
            <Route path="/f1/races/tier-b/season-4/netherlands" element={<F1RacesNetherlandsB4 />} />
            <Route path="/f1/races/tier-c/season-4/netherlands" element={<F1RacesNetherlandsC4 />} />
            <Route path="/f1/races/the-cup/season-4/imola" element={<F1RacesImolaCup4 />} />
            <Route path="/f1/races/the-cup/season-4/texas" element={<F1RacesTexasCup4 />} />
            <Route path="/f1/races/tier-a/season-4/great-britain" element={<F1RacesGreatBritainA4 />} />
            <Route path="/f1/races/tier-b/season-4/great-britain" element={<F1RacesGreatBritainB4 />} />
            <Route path="/f1/races/tier-c/season-4/great-britain" element={<F1RacesGreatBritainC4 />} />
            <Route path="/f1/races/tier-a/season-4/canada" element={<F1RacesCanadaA4 />} />
            <Route path="/f1/races/tier-b/season-4/canada" element={<F1RacesCanadaB4 />} />
            <Route path="/f1/races/tier-c/season-4/canada" element={<F1RacesCanadaC4 />} />
            <Route path="/f1/races/tier-a/season-4/texas" element={<F1RacesTexasA4 />} />
            <Route path="/f1/races/tier-b/season-4/texas" element={<F1RacesTexasB4 />} />
            <Route path="/f1/races/tier-c/season-4/texas" element={<F1RacesTexasC4 />} />
            <Route path="/f1/races/tier-a/season-4/mexico" element={<F1RacesMexicoA4 />} />
            <Route path="/f1/races/tier-b/season-4/mexico" element={<F1RacesMexicoB4 />} />
            <Route path="/f1/races/tier-c/season-4/mexico" element={<F1RacesMexicoC4 />} />
            <Route path="/f1/races/tier-a/season-4/las-vegas" element={<F1RacesLasVegasA4 />} />
            <Route path="/f1/races/tier-b/season-4/las-vegas" element={<F1RacesLasVegasB4 />} />
            <Route path="/f1/races/tier-c/season-4/las-vegas" element={<F1RacesLasVegasC4 />} />
            <Route path="/f1/races/tier-a/season-4/china" element={<F1RacesChinaA4 />} />
            <Route path="/f1/races/tier-b/season-4/china" element={<F1RacesChinaB4 />} />
            <Route path="/f1/races/tier-c/season-4/china" element={<F1RacesChinaC4 />} />
            <Route path="/f1/races/tier-a/season-4/japan" element={<F1RacesJapanA4 />} />
            <Route path="/f1/races/tier-b/season-4/japan" element={<F1RacesJapanB4 />} />
            <Route path="/f1/races/tier-c/season-4/japan" element={<F1RacesJapanC4 />} />
            <Route path="/f1/races/tier-a/season-4/brazil" element={<F1RacesBrazilA4 />} />
            <Route path="/f1/races/tier-b/season-4/brazil" element={<F1RacesBrazilB4 />} />
            <Route path="/f1/races/tier-c/season-4/brazil" element={<F1RacesBrazilC4 />} />
            <Route path="/f1/races/tier-a/season-5/australia" element={<F1RacesAustraliaA5 />} />
            <Route path="/f1/races/tier-b/season-5/australia" element={<F1RacesAustraliaB5 />} />
            <Route path="/f1/races/tier-c/season-5/australia" element={<F1RacesAustraliaC5 />} />
            <Route path="/f1/races/tier-a/season-5/texas" element={<F1RacesTexasA5 />} />
            <Route path="/f1/races/tier-b/season-5/texas" element={<F1RacesTexasB5 />} />
            <Route path="/f1/races/tier-c/season-5/texas" element={<F1RacesTexasC5 />} />
            <Route path="/f1/races/tier-a/season-5/brazil" element={<F1RacesBrazilA5 />} />
            <Route path="/f1/races/tier-b/season-5/brazil" element={<F1RacesBrazilB5 />} />
            <Route path="/f1/races/tier-c/season-5/brazil" element={<F1RacesBrazilC5 />} />
            <Route path="/f1/races/tier-a/season-5/hungary" element={<F1RacesHungaryA5 />} />
            <Route path="/f1/races/tier-b/season-5/hungary" element={<F1RacesHungaryB5 />} />
            <Route path="/f1/races/tier-c/season-5/hungary" element={<F1RacesHungaryC5 />} />
            <Route path="/f1/races/tier-a/season-5/italy" element={<F1RacesItalyA5 />} />
            <Route path="/f1/races/tier-b/season-5/italy" element={<F1RacesItalyB5 />} />
            <Route path="/f1/races/tier-a/season-5/france" element={<F1RacesFranceA5 />} />
            <Route path="/f1/races/tier-b/season-5/france" element={<F1RacesFranceB5 />} />
            <Route path="/f1/races/tier-a/season-5/austria" element={<F1RacesAustriaA5 />} />
            <Route path="/f1/races/tier-b/season-5/austria" element={<F1RacesAustriaB5 />} />
            <Route path="/f1/races/tier-a/season-5/monaco" element={<F1RacesMonacoA5 />} />
            <Route path="/f1/races/tier-b/season-5/monaco" element={<F1RacesMonacoB5 />} />
            <Route path="/f1/races/tier-a/season-5/netherlands" element={<F1RacesNetherlandsA5 />} />
            <Route path="/f1/races/tier-b/season-5/netherlands" element={<F1RacesNetherlandsB5 />} />
            <Route path="/f1/races/tier-a/season-5/spain" element={<F1RacesSpainA5 />} />
            <Route path="/f1/races/tier-b/season-5/spain" element={<F1RacesSpainB5 />} />
            <Route path="/f1/races/tier-b/season-5/great-britain" element={<F1RacesGreatBritainB5 />} />
            <Route path="/f1/races/tier-b/season-5/singapore" element={<F1RacesSingaporeB5 />} />
            <Route path="/f1/races/tier-100/season-5/brazil" element={<F1RacesBrazil1005 />} />
            <Route path="/f1/races/tier-100/season-5/abu-dhabi" element={<F1RacesAbuDhabi1005 />} />
            <Route path="/f1/register" element={<F1Register />} />
            <Route path="/f1/drivers" element={<F1Drivers />} />
            <Route path="/f1/standings" element={<F1Standings />} />
            <Route path="/wrc" element={<WRCMain />} />
            <Route path="/wrc/standings" element={<WRCStandings />} />
            <Route path="/wrc/events" element={<WRCEvents />} />
            <Route path="/wrc/events/season-1/croatia" element={<WRCEventsCroatia1 />} />
            <Route path="/wrc/events/season-1/kenya" element={<WRCEventsKenya1 />} />
            <Route path="/wrc/events/season-1/scandia" element={<WRCEventsScandia1 />} />
            <Route path="/wrc/events/season-1/portugal" element={<WRCEventsPortugal1 />} />
            <Route path="admin" element={<Admin />} />
            <Route path="/admin/f1" element={<AdminF1 />} />
            <Route path="/admin/f1/seasons" element={<AdminF1Seasons />} />
            <Route path="/articles/v10-esports-launch" element={<V10EsportsLaunchArticle />} />
            {/* <Route path="/admin/rl" element={<AdminACC />} />
            <Route path="/admin/" element={<AdminF1 />} />
            <Route path="/admin/rl" element={<AdminRL />} /> */}
          </Routes>
        </main>
      </div>
    </Router>
  )
}

export default App
