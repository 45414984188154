import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RacesTier.css'
import F1RaceBox from './F1RaceBox'
import F1RaceBoxComplete from './F1RaceBoxComplete'
// import F1RaceBox2 from './F1RaceBox2'
// import F1RaceBoxComplete from './F1RaceBoxComplete'

export default function F1RacesTierB3 () {
  const races = []
  return (
    <div className="race-tier-container">
      <F1RaceBoxComplete raceName="Bahrain" raceDate={new Date(2023, 2, 25).getTime()} raceFlag="/bh.png" raceLink="/f1/races/tier-b/season-3/bahrain" videoLink="https://www.youtube.com/watch?v=UyvB3bUXN0Q"/>
      <F1RaceBoxComplete raceName="Great Britain" raceDate={new Date(2023, 3, 1).getTime()} raceFlag="/gb.png" raceLink="/f1/races/tier-b/season-3/great-britain" videoLink="https://www.youtube.com/watch?v=6-XjBtXztC0"/>
      <F1RaceBoxComplete raceName="Monaco" raceDate={new Date(2023, 3, 8).getTime()} raceFlag="/mc.png" raceLink="/f1/races/tier-b/season-3/monaco" videoLink="https://www.youtube.com/watch?v=Ug-q-fenxgI"/>
      <F1RaceBoxComplete raceName="Belgium (Sprint)" raceDate={new Date(2023, 3, 15).getTime()} raceFlag="/be.png" raceLink="/f1/races/tier-b/season-3/belgium" videoLink="https://www.youtube.com/watch?v=w4YOt7TPfvA"/>
      <F1RaceBoxComplete raceName="Miami" raceDate={new Date(2023, 3, 22).getTime()} raceFlag="/us.png" raceLink="/f1/races/tier-b/season-3/miami" videoLink="https://www.youtube.com/@10controls22"/>
      <F1RaceBoxComplete raceName="Saudi Arabia (Sprint)" raceDate={new Date(2023, 3, 29).getTime()} raceFlag="/sa.png" raceLink="/f1/races/tier-b/season-3/saudi-arabia" videoLink="https://www.youtube.com/@10controls22"/>
      <F1RaceBoxComplete raceName="Netherlands" raceDate={new Date(2023, 4, 6).getTime()} raceFlag="/nl.png" raceLink="/f1/races/tier-b/season-3/netherlands" videoLink="https://www.youtube.com/@10controls22"/>
        {races.map(race => {
          return (<F1RaceBox key={race.id} raceName={race.name} raceDate={race.date} raceFlag={race.flag} />)
        })}
        {/* <F1RaceBox raceName="Melbourne" raceDate={['5', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Silverstone" raceDate={['12', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Spa-Francorchamps" raceDate={['19', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Jeddah" raceDate={['26', <sup>th</sup>, ' August 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Zandvoort" raceDate={['2', <sup>nd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Monza (Sprint)" raceDate={['9', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Hungaroring" raceDate={['16', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Miami" raceDate={['23', <sup>rd</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Mexico" raceDate={['30', <sup>th</sup>, ' September 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Imola" raceDate={['7', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Barcelona" raceDate={['14', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Suzuka" raceDate={['21', <sup>st</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Montreal" raceDate={['28', <sup>th</sup>, ' October 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Bahrain (Sprint)" raceDate={['4', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/>
        <F1RaceBox raceName="Yas Marina" raceDate={['11', <sup>th</sup>, ' November 2022']} raceFlag="/au.png"/> */}
    </div>
  )
}
