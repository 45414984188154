/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import '../../App.css'
import '../../stylesheets/F1Standings.css'
import WRCStandings1 from './Season 1/Standings'
import Footer from '../Footer'

export default function WRCStandings () {
  const [selectedSeason, setSelectedSeason] = useState('Season 1')
  return (
    <>
    <div className="page-content">
    <h1 className="page-content-heading">Standings</h1>
    <div className="division-menu-container">
        <h4 className="division-menu-item" style={{ color: selectedSeason === 'Season 1' ? 'white' : '#C18794' }} onClick={() => setSelectedSeason('Season 1')}>Season 1</h4>
    </div>
    <div className="drivers-list">
        {selectedSeason === 'Season 1' &&
       <WRCStandings1 />
        }
    </div>
    <Footer />
    </div>
    </>
  )
}
