import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesBahrainCup3 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Bahrain</h1>
        <h2 className="race-subtext">The Cup - Season 3</h2>
        <h3 className="race-subtext">Round 4 of 8</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(255, 32, 2, 0.2)" teamImage="/PM.png" constructorName="Prime Motorsport" driverName="Tony Riché" driverFlag="/fr.png" driverInterval="WINNER" driverFastLap="" driverGrid="2" driverPosition="1" driverPoints="34" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Lukáš Hendrych" driverFlag="/cz.png" driverInterval="+7.502" driverFastLap="" driverGrid="6" driverPosition="2" driverPoints="19" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Dallan McTeer" driverFlag="/us.png" driverInterval="+7.870" driverFastLap="" driverGrid="-" driverPosition="3" driverPoints="21"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="CasperSky" driverFlag="/pl.png" driverInterval="_8.138" driverFastLap="" driverGrid="7" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="+11.848" driverFastLap="" driverGrid="10" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+13.694" driverFastLap="" driverGrid="3" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="+13.756" driverFastLap="" driverGrid="5" driverPosition="7" driverPoints="13" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(240, 183, 78, 0.2)" teamImage="/XPG.png" constructorName="XPG Esports" driverName="LinenRayBok" driverFlag="/se.png" driverInterval="+13.898" driverFastLap="" driverGrid="4" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Ghost" driverFlag="/gb-eng.png" driverInterval="+24.917" driverFastLap="" driverGrid="4" driverPosition="9" driverPoints="5" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(240, 183, 78, 0.2)" teamImage="/XPG.png" constructorName="XPG Esports" driverName="Flame" driverFlag="/gb-eng.png" driverInterval="+30.906" driverFastLap="" driverGrid="4" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(235, 80, 89, 0.2)" teamImage="/SRL.png" constructorName="Syndicate Racing League" driverName="OS DEGREEZ" driverFlag="/gb-eng.png" driverInterval="+41.250" driverFastLap="" driverGrid="5" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(254, 199, 38, 0.2)" teamImage="/APP.png" constructorName="APPF1 Racing Community" driverName="Lifebout" driverFlag="/gb-eng.png" driverInterval="+48.514" driverFastLap="" driverGrid="6" driverPosition="12" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(42, 139, 8, 0.2)" teamImage="/NSR.png" constructorName="No Speed Racing" driverName="Antonio Brancati" driverFlag="/it.png" driverInterval="+1 LAP" driverFastLap="1:28.942" driverGrid="9" driverPosition="13" driverPoints="5" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Gloomy" driverFlag="/nl.png" driverInterval="+1 LAP" driverFastLap="" driverGrid="-" driverPosition="14" driverPoints="0"/>
        <F1RaceResult teamBackground="rgba(128, 0, 128, 0.2)" teamImage="/PF1.png" constructorName="PowerF1" driverName="Jan Švrdlik" driverFlag="/cz.png" driverInterval="DNF" driverFastLap="" driverGrid="8" driverPosition="-" driverPoints="2" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(213, 87, 255, 0.2)" teamImage="/ARL.png" constructorName="Amethyst Racing" driverName="Lewis Pyper" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up" />
        <F1RaceResult teamBackground="rgba(33, 45, 219, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="JP" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="" driverGrid="7" driverPosition="-" driverPoint="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Tony Riché</h3>
          </div>
        </div>
    </div>
  )
}
