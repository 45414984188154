import React from 'react'
import '../../../../stylesheets/F1ResultsPage.css'
import F1RaceResult from '../../F1RaceResult'

export default function F1RacesHungaryCup4 () {
  return (
    <div className="page-content">
        <h1 className="page-content-heading">Hungary</h1>
        <h2 className="race-subtext">The Cup - Season 4</h2>
        <h3 className="race-subtext">Round 1 of 14</h3>
        <table className="results-table">
        <tr>
            <th></th>
            <th className="flag-result"></th>
            <th></th>
            <th className="team-image-standing"></th>
            <th className="grid-position"></th>
            <th><h6>Interval</h6></th>
            <th className="fastest-lap"><h6>Fastest Lap</h6></th>
            <th><h6>Pts</h6></th>
        </tr>
        <F1RaceResult teamBackground="rgba(255, 245, 0, 0.2)" teamImage="/GRL.png" constructorName="Groundeffect Racing League" driverName="ROM BFord" driverFlag="/gb-eng.png" driverInterval="WINNER" driverFastLap="1:18.327" driverGrid="9" driverPosition="1" driverPoints="25" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Octavian Ursu" driverFlag="/ro.png" driverInterval="+4.363" driverFastLap="1:18.359" driverGrid="12" driverPosition="2" driverPoints="18" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 215, 0, 0.2)" teamImage="/PRS.png" constructorName="Premier Racing Series" driverName="Tsar" driverFlag="/hu.png" driverInterval="+4.768" driverFastLap="1:18.213" driverGrid="9" driverPosition="3" driverPoints="15" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Emil Lundell" driverFlag="/se.png" driverInterval="+7.565" driverFastLap="1:18.252" driverGrid="2" driverPosition="4" driverPoints="12" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(24, 29, 50, 0.2)" teamImage="/VRL.png" constructorName="Virtual Racing League" driverName="Ondřej Macháč" driverFlag="/cz.png" driverInterval="+9.781" driverFastLap="1:18.136" driverGrid="2" driverPosition="5" driverPoints="10" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(59, 236, 44, 0.2)" teamImage="/POR.png" constructorName="Piggy's Online Racing" driverName="Bigpou" driverFlag="/dz.png" driverInterval="+11.391" driverFastLap="1:19.367" driverGrid="7" driverPosition="6" driverPoints="8" arrow="fa-solid fa-angle-up" />
        <F1RaceResult teamBackground="rgba(255, 66, 66, 0.2)" teamImage="/SR.png" constructorName="Semi Racing Esports" driverName="Miron Pijl" driverFlag="/nl.png" driverInterval="+14.012" driverFastLap="1:18.080" driverGrid="4" driverPosition="7" driverPoints="6" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 66, 66, 0.2)" teamImage="/SR.png" constructorName="Semi Racing Esports" driverName="Čića Mile" driverFlag="/cz.png" driverInterval="+17.769" driverFastLap="1:19.320" driverGrid="1" driverPosition="8" driverPoints="4" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(255, 245, 0, 0.2)" teamImage="/GRL.png" constructorName="Groundeffect Racing League" driverName="Bladez" driverFlag="/gb-eng.png" driverInterval="+18.171" driverFastLap="1:18.295" driverGrid="-" driverPosition="9" driverPoints="2"/>
        <F1RaceResult teamBackground="rgba(0, 0, 255, 0.2)" teamImage="/LRL.png" constructorName="Limitless Racing League" driverName="Levi" driverFlag="/gb-eng.png" driverInterval="+21.110" driverFastLap="1:18.189" driverGrid="5" driverPosition="10" driverPoints="1" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 215, 0, 0.2)" teamImage="/PRS.png" constructorName="Premier Racing Series" driverName="Joe bean" driverFlag="/gb-eng.png" driverInterval="+35.778" driverFastLap="1:16.994" driverGrid="5" driverPosition="11" driverPoints="0" arrow="fa-solid fa-angle-down" lapColor="#ff00ff"/>
        <F1RaceResult teamBackground="rgba(221, 137, 18, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Boss game" driverFlag="/nl.png" driverInterval="+47.143" driverFastLap="1:19.667" driverGrid="8" driverPosition="12" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(255, 255, 255, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="JSTN" driverFlag="/nl.png" driverInterval="DNF" driverFastLap="1:18.737" driverGrid="11" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(0, 0, 255, 0.2)" teamImage="/LRL.png" constructorName="Limitless Racing League" driverName="Jake" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:19.067" driverGrid="9" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(59, 236, 44, 0.2)" teamImage="/POR.png" constructorName="Piggy's Online Racing" driverName="Cot5" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:18.713" driverGrid="7" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(109, 94, 180, 0.2)" teamImage="/VASA.png" constructorName="VASA Racing League" driverName="Larzen" driverFlag="/dk.png" driverInterval="DNF" driverFastLap="1:18.634" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-up"/>
        <F1RaceResult teamBackground="rgba(193, 135, 148, 0.2)" teamImage="/10C.png" constructorName="10 Controls" driverName="Anton Chebotarov" driverFlag="/ua.png" driverInterval="DNF" driverFastLap="1:18.571" driverGrid="13" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(221, 137, 18, 0.2)" teamImage="/OGK.png" constructorName="OGKROE" driverName="Bigcheff" driverFlag="/gb-eng.png" driverInterval="DNF" driverFastLap="1:20.340" driverGrid="-" driverPosition="-" driverPoints="0"/>
        <F1RaceResult teamBackground="rgba(255, 255, 255, 0.2)" teamImage="/VF1.png" constructorName="VF1 Racing Community" driverName="Cmagee" driverFlag="/ie.png" driverInterval="DNF" driverFastLap="1:18.899" driverGrid="3" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        <F1RaceResult teamBackground="rgba(109, 94, 180, 0.2)" teamImage="/VASA.png" constructorName="VASA Racing League" driverName="Vaipz" driverFlag="/dk.png" driverInterval="DNF" driverFastLap="" driverGrid="1" driverPosition="-" driverPoints="0" arrow="fa-solid fa-angle-down"/>
        </table>
        <div className="driver-day-container">
          <div>
            <h4>Driver of the day</h4>
          </div>
          <div>
          <h3>Octavian Ursu</h3>
          </div>
        </div>
    </div>
  )
}
