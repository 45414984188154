import React from 'react'
import '../../App.css'
import '../../stylesheets/F1RaceFormat.css'

function WRCFormat () {
  return (
    <>
      <section className="race-format-container">
  <h2>The Format</h2>
  <br></br>
  <h4>Each rally event will span one week, in which competitors will have 3 rally stages to complete. The times for each stage completed are combined into a total event time, and the positions/points for that event are determined by how quick competitors&apos; event times are.</h4>
  <br></br>
  <h4>Competitors have one attempt for each stage, however it can be attempted at any point during the week in their own time, and any amount of practice can be done outside of the official championship attempt. Different car categories, random time of day and conditions will keep all drivers on their toes!</h4>
  <br></br>
  <h4>Different car categories, random time of day and conditions will keep all drivers on their toes!</h4>
  <br></br>
  <h3>May the best man win!</h3>
</section>
</>
  )
}

export default WRCFormat
